interface ExampleTextProps {
	text: string;
}

export const ExampleText = ({ text }: ExampleTextProps) => {
	return (
		<div className={'mb-3'}>
			<span className={'italic text-slate-400'}>Приклад: </span>
			<span className={'text-slate-200'}>{text}</span>
		</div>
	);
};
