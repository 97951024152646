import { create } from 'zustand';

interface CropModalStore {
	isOpen: boolean;
	image?: File;
	onSetImage: (image: File) => void;
	onOpen: () => void;
	onClose: () => void;
	setMutateAvatar: (callback: (image: File) => void) => void;
	mutateAvatar: (image: File) => void;
}

export const useCropModal = create<CropModalStore>(set => ({
	isOpen: false,
	onSetImage: (image: File) => set({ image: image }),
	setMutateAvatar: (callback: (image: File) => void) =>
		set({ mutateAvatar: callback }),
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false }),
	mutateAvatar: (image: File) => {}, // Placeholder
}));
