import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { KEY_REDIRECT_AUTH_PROFILE } from 'shared/const/app';
import { useAuth } from './../services/useAuth/useAuth';

export const useIsAuth = (auth: boolean, router: string) => {
	const navigate = useNavigate();

	const isAuth = useAuth(state => state.isAuth);
	const isAuthInit = useAuth(state => state.isInit);

	useEffect(() => {
		if (isAuth === auth && isAuthInit) {
			localStorage.removeItem(KEY_REDIRECT_AUTH_PROFILE);
			navigate(router);
		}
	}, [isAuth, isAuthInit]);
};
