import { RichUtils } from 'draft-js';
import Bold from 'shared/assets/svgs/toolbar/Bold';
import Italic from 'shared/assets/svgs/toolbar/Italic';
import Ol from 'shared/assets/svgs/toolbar/Ol';
import Ul from 'shared/assets/svgs/toolbar/Ul';
import Underline from 'shared/assets/svgs/toolbar/Underline';

const Toolbar = ({ editorState, setEditorState }) => {
	const tools = [
		{
			label: 'bold',
			style: 'BOLD',
			icon: <Bold />,
			method: 'inline',
		},
		{
			label: 'italic',
			style: 'ITALIC',
			icon: <Italic />,
			method: 'inline',
		},
		{
			label: 'underline',
			style: 'UNDERLINE',
			icon: <Underline />,
			method: 'inline',
		},
		{
			label: 'Unordered-List',
			style: 'unordered-list-item',
			method: 'block',
			icon: <Ul />,
		},
		{
			label: 'Ordered-List',
			style: 'ordered-list-item',
			method: 'block',
			icon: <Ol />,
		},
	];

	const applyStyle = (e, style, method) => {
		e.preventDefault();
		method === 'block'
			? setEditorState(RichUtils.toggleBlockType(editorState, style))
			: setEditorState(RichUtils.toggleInlineStyle(editorState, style));
	};

	const isActive = (style, method) => {
		if (method === 'block') {
			const selection = editorState.getSelection();
			const blockType = editorState
				.getCurrentContent()
				.getBlockForKey(selection.getStartKey())
				.getType();
			return blockType === style;
		} else {
			const currentStyle = editorState.getCurrentInlineStyle();
			return currentStyle.has(style);
		}
	};

	return (
		<div className='toolbar-grid'>
			{tools.map((item, idx) => (
				<button
					style={{
						width: '20px',
						height: '20px',
						color: isActive(item.style, item.method) ? '#e2e8f0' : '#94a3b8',
					}}
					key={`${item.label}-${idx}`}
					title={item.label}
					onClick={e => applyStyle(e, item.style, item.method)}
					onMouseDown={e => e.preventDefault()}
				>
					{item.icon || item.label}
				</button>
			))}
		</div>
	);
};

export default Toolbar;
