import { RawDraftContentState, convertFromRaw } from 'draft-js';

export function getCharacterCountFromRaw(rawContent: any): number {
	const contentState = convertFromRaw(rawContent);
	const plainText = contentState.getPlainText();
	return plainText.length;
}

export function getCharacterCountFromJSONString(jsonString: string): number {
	const rawContent = JSON.parse(jsonString);
	const contentState = convertFromRaw(rawContent);
	const plainText = contentState.getPlainText();
	return plainText.length;
}

export function getCharacterCount(rawContent: any): number {
	let parsedContent: RawDraftContentState;

	try {
		if (typeof rawContent === 'string') {
			if (!rawContent.trim()) {
				throw new Error('Input JSON string is empty');
			}
			parsedContent = JSON.parse(rawContent);
		} else {
			parsedContent = rawContent;
		}
	} catch (error) {
		return 0; // Возвращаем 0 при ошибке парсинга
	}

	try {
		const contentState = convertFromRaw(parsedContent);
		const plainText = contentState.getPlainText();
		return plainText.length;
	} catch (error) {
		return 0; // Возвращаем 0 при ошибке конвертации
	}
}
