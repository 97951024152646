import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef } from 'react';

const mapContainerStyle = {
	width: '100%',
	height: '250px',
};

interface MapComponentProps {
	latitude: number;
	longitude: number;
}

export const MapComponent: React.FC<MapComponentProps> = ({
	latitude,
	longitude,
}) => {
	const mapRef = useRef<google.maps.Map | null>(null);

	const onLoad = useCallback((map: google.maps.Map) => {
		mapRef.current = map;
	}, []);

	const onUnmount = useCallback(() => {
		mapRef.current = null;
	}, []);

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.setCenter({ lat: latitude, lng: longitude });
		}
	}, [latitude, longitude]);

	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY as string}>
			<GoogleMap
				mapContainerStyle={mapContainerStyle}
				center={{ lat: latitude, lng: longitude }}
				zoom={17}
				options={{
					disableDefaultUI: true,
					fullscreenControl: false,
					mapTypeId: 'satellite',
				}}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				<Marker position={{ lat: latitude, lng: longitude }} />
			</GoogleMap>
		</LoadScript>
	);
};
