import { memo, useEffect, useState } from 'react';
import { Accordion } from 'shared/ui/Accordion';
import { Button } from 'shared/ui/Button';
import DraftEditor from 'shared/ui/Editor/DraftEditor';
import { ExampleText } from 'shared/ui/ExampleText';
import { MiniIndicator } from 'shared/ui/MiniIndicator';
import { getCharacterCount } from 'shared/utils/getCharacterCountFromRaw';

type BlockEdit = {
	title: string;
	initText: string;
	exampleText: string;
	setText: React.Dispatch<React.SetStateAction<string>>;
	isError: boolean;
	isSuccess: boolean;
	isPending: boolean;
	onClick: () => void;
	defaultOpen?: boolean;
};

export const BlockEdit = memo(
	({
		title,
		initText,
		setText,
		isError,
		isSuccess,
		exampleText,
		isPending,
		onClick,
		defaultOpen = false,
	}: BlockEdit) => {
		const [isShowMsg, setIsShowMsg] = useState(false);

		useEffect(() => {
			let interval: number | undefined;

			if (isError || isSuccess) {
				interval = window.setTimeout(() => {
					setIsShowMsg(false);
				}, 2000);
			} else {
				setIsShowMsg(true);
			}

			return () => clearTimeout(interval);
		}, [isError, isSuccess]);

		return (
			<Accordion
				white
				title={title}
				defaultOpen={defaultOpen}
				status={getCharacterCount(initText) > 0}
			>
				<ExampleText text={exampleText} />
				<DraftEditor initText={initText} setText={setText} />
				<div className={'flex justify-between items-center mt-3'}>
					<div>
						{isShowMsg && isError && (
							<div className={'text-red-400'}>Не вдалося зберегти</div>
						)}
						{isShowMsg && isSuccess && (
							<div className={'text-green-400'}>Успішне збереження</div>
						)}
						<MiniIndicator isShow={isPending} />
					</div>
					<Button long onClick={onClick}>
						Зберегти
					</Button>
				</div>
			</Accordion>
		);
	}
);
