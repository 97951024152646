import { MutateOptions } from '@tanstack/react-query';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Accordion } from 'shared/ui/Accordion';
import { Button } from 'shared/ui/Button';
import { InputText } from 'shared/ui/InputText';
import { MiniIndicator } from 'shared/ui/MiniIndicator';

type BurialPlaceType = { city: string; place: string };

type PlaceEditProps = {
	profileId: string;
	title: string;
	burialPlace: BurialPlaceType;
	isError: boolean;
	isSuccess: boolean;
	isPending: boolean;
	mutate: (
		variables: any,
		options?: MutateOptions<any, Error, any, unknown> | undefined
	) => void;
	defaultOpen?: boolean;
};

export const PlaceEdit = memo(
	({
		profileId,
		title,
		burialPlace,
		isError,
		isSuccess,
		isPending,
		mutate,
		defaultOpen = false,
	}: PlaceEditProps) => {
		const [isShowMsg, setIsShowMsg] = useState(false);

		const { control, handleSubmit } = useForm({
			defaultValues: {
				city: burialPlace?.city ?? '',
				place: burialPlace?.place ?? '',
			},
		});

		useEffect(() => {
			let interval: number | undefined;

			if (isError || isSuccess) {
				interval = window.setTimeout(() => {
					setIsShowMsg(false);
				}, 2000);
			} else {
				setIsShowMsg(true);
			}

			return () => clearTimeout(interval);
		}, [isError, isSuccess]);

		const submit = (burialPlace: BurialPlaceType) => {
			mutate({ profileId, burialPlace: { ...burialPlace } });
		};

		return (
			<Accordion
				white
				title={title}
				defaultOpen={defaultOpen}
				status={burialPlace?.city.length > 0 && burialPlace?.place.length > 0}
			>
				<div className={''}>
					<div>
						<span className={'text-slate-500'}>Місто</span>
						<InputText
							control={control}
							name={'city'}
							type='text'
							placeholder='Місто'
						></InputText>
					</div>
					<div className={'mt-5'}>
						<span className={'text-slate-500'}>Кладовище</span>
						<InputText
							control={control}
							name={'place'}
							type='text'
							placeholder='Кладовище'
						></InputText>
					</div>
				</div>
				<div className={'flex justify-between items-center mt-3'}>
					<div>
						{isShowMsg && isError && (
							<div className={'text-red-400'}>Не вдалося зберегти</div>
						)}
						{isShowMsg && isSuccess && (
							<div className={'text-green-400'}>Успішне збереження</div>
						)}
					</div>
					<MiniIndicator isShow={isPending} />
					<Button long onClick={handleSubmit(submit)}>
						Зберегти
					</Button>
				</div>
			</Accordion>
		);
	}
);
