import { useQuery } from '@tanstack/react-query';
import { FileApi } from '../api/FileApi';

export const useAvatarGet = (profileId: string, isAvatar: boolean = true) => {
	const {
		isPending,
		isSuccess,
		isLoading,
		isError,
		refetch,
		data,
		isRefetching,
		error,
	} = useQuery({
		queryKey: ['avtar' + profileId],
		queryFn: () => FileApi.getAvatar(profileId),
		enabled: isAvatar,
		retry: false,
	});

	return {
		isPending,
		isSuccess,
		isLoading,
		isError,
		data,
		refetch,
		error,
		isRefetching,
	};
};
