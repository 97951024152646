import { MutateOptions } from '@tanstack/react-query';
import { memo, useEffect, useState } from 'react';
import { Accordion } from 'shared/ui/Accordion';
import { Button } from 'shared/ui/Button';
import { CustomDateInput } from 'shared/ui/CustomDateInput';
import { MiniIndicator } from 'shared/ui/MiniIndicator';
import { isDateValid } from 'shared/utils/isDateValid';

type DateEditProps = {
	profileId: string;
	title: string;
	initDateBirth: Date;
	initDateDeath: Date;
	isError: boolean;
	isSuccess: boolean;
	isPending: boolean;
	mutate: (
		variables: any,
		options?: MutateOptions<any, Error, any, unknown> | undefined
	) => void;
	defaultOpen?: boolean;
};

export const DateEdit = memo(
	({
		profileId,
		title,
		initDateBirth,
		initDateDeath,
		isError,
		isSuccess,
		isPending,
		mutate,
		defaultOpen = false,
	}: DateEditProps) => {
		const [isShowMsg, setIsShowMsg] = useState(false);

		const [birth, setBirth] = useState<Date>(initDateBirth);
		const [death, setDeath] = useState<Date>(initDateDeath);

		useEffect(() => {
			let interval: number | undefined;

			if (isError || isSuccess) {
				interval = window.setTimeout(() => {
					setIsShowMsg(false);
				}, 2000);
			} else {
				setIsShowMsg(true);
			}

			return () => clearTimeout(interval);
		}, [isError, isSuccess]);

		const onSave = () => {
			if (birth) {
				mutate({ profileId, birthDay: birth });
			}

			if (death) {
				mutate({ profileId, deathDay: death });
			}
		};

		const isBirth = isDateValid(birth);
		const isDeath = isDateValid(death);

		return (
			<Accordion
				white
				title={title}
				defaultOpen={defaultOpen}
				status={isBirth && isDeath}
			>
				<CustomDateInput
					init={birth}
					setDate={setBirth}
					title={'Дата народження'}
				/>
				<CustomDateInput
					init={death}
					setDate={setDeath}
					title={'Дата смерті'}
				/>
				<div className={'flex justify-between items-center mt-3'}>
					<div>
						{isShowMsg && isError && (
							<div className={'text-red-400'}>Не вдалося зберегти</div>
						)}
						{isShowMsg && isSuccess && (
							<div className={'text-green-400'}>Успішне збереження</div>
						)}
						<MiniIndicator isShow={isPending} />
					</div>
					<Button long onClick={onSave}>
						Зберегти
					</Button>
				</div>
			</Accordion>
		);
	}
);
