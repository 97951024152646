/**
 * Конвертирует строку Base64 в Blob
 * @param {string} base64Data - строка Base64
 * @param {string} contentType - тип содержимого (например, 'image/jpeg')
 * @return {Blob}
 */
export function base64ToBlob(base64Data: string, contentType: string): Blob {
	const byteCharacters = atob(base64Data.split(',')[1]);
	const byteNumbers = new Array(byteCharacters.length);

	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}

	const byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type: contentType });
}

/**
 * Создает файл из Blob
 * @param {Blob} blob - Blob объект
 * @param {string} fileName - имя файла
 * @return {File}
 */
export function blobToFile(blob: Blob, fileName: string): File {
	return new File([blob], fileName, {
		type: blob.type,
		lastModified: Date.now(),
	});
}
