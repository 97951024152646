export function loadScript(src, callback) {
	// Проверяем, не загружен ли уже скрипт с таким src
	if (document.querySelector(`script[src="${src}"]`)) {
		console.log('Script already loaded:', src);
		callback(); // Вызываем callback, так как скрипт уже загружен
		return null; // Возвращаем null, так как новый скрипт не добавляется
	}

	const script = document.createElement('script');
	script.src = src;
	script.onload = () => {
		console.log('Script loaded:', src);
		callback();
	};
	script.onerror = () => {
		console.error('Script load error:', src);
	};
	document.head.appendChild(script);
	return script;
}
