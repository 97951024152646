import { authInstance } from 'shared/api';

export const VideoApi = {
	async get({
		profileId,
		pageParam = 0,
		moderate,
	}: {
		profileId: string;
		pageParam: number;
		moderate: boolean;
	}) {
		return authInstance
			.get('/video', {
				params: { profileId, limit: 3, cursor: pageParam, moderate },
			})
			.then(response => {
				return response.data;
			});
	},
	async delete({ profileId, videoId }: { profileId: string; videoId: string }) {
		return authInstance
			.delete('/video', {
				data: { profileId, videoId },
			})
			.then(response => {
				return response.data;
			});
	},
	async moderation({
		profileId,
		videoId,
	}: {
		profileId: string;
		videoId: string;
	}) {
		return authInstance.put('/video', { profileId, videoId }).then(response => {
			return response.data;
		});
	},
	async create({ profileId, url }: { profileId: string; url: string }) {
		return authInstance.post('/video', { profileId, url }).then(response => {
			return response.data;
		});
	},
};
