import { useMutation } from '@tanstack/react-query';
import { AuthApi } from '../../api/authApi';

export const useLoginAccessToken = () => {
	const { mutate, data, isPending, isSuccess, error, reset } = useMutation({
		mutationFn: AuthApi.loginAccessToken,
	});

	return {
		mutateAccess: mutate,
		isPendingAccess: isPending,
		isSuccessAccess: isSuccess,
		resetAccess: reset,
		dataAccess: data,
		errorAccess: error,
	};
};
