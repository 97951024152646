import { useMutation } from '@tanstack/react-query';
import { ProfileApi } from '../../api/ProfileApi';

export const useProfileView = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: ProfileApi.view,
		});

	return {
		mutateView: mutate,
		isPendingView: isPending,
		isSuccessView: isSuccess,
		isErrorView: isError,
		errorView: error,
		resetView: reset,
		dataView: data,
	};
};
