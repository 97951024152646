import { useIsAuth } from 'entities/auth';
import { useFetchProfile, useIsAccess } from 'entities/profile';
import { UserProvider } from 'entities/user';
import { useParams } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { Indicator } from 'shared/ui/Indicator';
import { Provider } from 'shared/ui/Provider';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { Geolocation } from './../ui/Geolocation/Geolocation';
import { Moderator } from './Moderator/Moderator';
import { ProfileSwith } from './ProfileSwith/ProfileSwith';
import { Replicate } from './Replicate/Replicate';

export const Setting = () => {
	useIsAuth(false, AppRouter.AUTH);

	const { profileId } = useParams();

	const {
		isError,
		isLoading,
		refetch,
		data: profile,
		error,
	} = useFetchProfile(profileId ?? '');

	useIsAccess(error, profile?.owners);

	return (
		<Provider>
			<UserProvider>
				<Indicator isShow={isLoading} />

				<div className='px-5'>
					{isError && !isLoading && (
						<div className={'mt-5'}>
							<ErrorLoad
								onRefresh={() => {
									refetch();
								}}
							/>
						</div>
					)}

					{!isError && !isLoading && (
						<>
							<ProfileSwith
								profileId={profileId as string}
								_profileSwitch={profile._profileSwitch}
							/>
							<Geolocation
								refetchProfile={refetch}
								_geoSwitch={profile?._geoSwitch}
								_geoCoords={profile?._geoCords}
							/>
							<Replicate
								refetchProfile={refetch}
								replicateStatus={profile.replicateStatus}
								profileId={profileId as string}
							/>
							<Moderator
								owners={profile.owners}
								refetchProfile={refetch}
								profileId={profileId as string}
							/>
						</>
					)}
				</div>
			</UserProvider>
		</Provider>
	);
};
