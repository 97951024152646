import { useProfileLike } from 'entities/profile';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useShareModal } from 'shared/ui/ShareModal';
import { checkAndAddStringToLocalStorage } from 'shared/utils/checkAndAddStringToLocalStorage';

interface NavBar {
	views: number;
	likes: number;
	isLoading: boolean;
}

export const NavBar = memo(({ views, likes, isLoading }: NavBar) => {
	const { profileId } = useParams();
	const [like, setLike] = useState(likes);

	useEffect(() => {
		setLike(likes);
	}, [likes]);

	const { onOpen } = useShareModal();
	const { mutateLike } = useProfileLike();

	const onLiked = () => {
		const isView = checkAndAddStringToLocalStorage('like', profileId as string);
		if (isView) {
			mutateLike(profileId as string);
			setLike(prev => prev + 1);
		}
	};

	return (
		<div className={'flex justify-center py-5'}>
			<div className='inline-flex rounded-md shadow-sm' role='group'>
				<div className='inline-flex items-center px-4 py-2 text-sm font-medium text-slate-100 bg-indigo-500 border border-indigo-600 rounded-s-lg'>
					{isLoading ? (
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-3 w-6 bg-slate-200 rounded-full'></div>
						</div>
					) : (
						<>
							<svg
								className='w-4 h-4 me-2'
								viewBox='0 0 24 24'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z'
									stroke='currentColor'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z'
									stroke='currentColor'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							{views}
						</>
					)}
				</div>
				<div
					onClick={onOpen}
					className='inline-flex cursor-pointer items-center px-4 py-2 text-sm font-medium text-slate-100 bg-indigo-500 border border-indigo-600'
				>
					<svg
						className='w-4 h-4 me-1'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z'
							fill='currentColor'
						/>
					</svg>
					Поділитися
				</div>
				<div
					onClick={onLiked}
					className='inline-flex cursor-pointer items-center px-4 py-2 text-sm font-medium text-slate-100 bg-indigo-500 border border-indigo-600 rounded-e-lg'
				>
					{isLoading ? (
						<div role='status' className='max-w-sm animate-pulse'>
							<div className='h-3 w-6 bg-slate-200 rounded-full'></div>
						</div>
					) : (
						<>
							<svg
								className='w-4 h-4 me-2'
								fill='currentColor'
								viewBox='0 0 24 24'
							>
								<path d='M0 0h24v24H0z' fill='none' />
								<path d='M12 22c4.97 0 9-4.03 9-9-4.97 0-9 4.03-9 9zM5.6 10.25c0 1.38 1.12 2.5 2.5 2.5.53 0 1.01-.16 1.42-.44l-.02.19c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5l-.02-.19c.4.28.89.44 1.42.44 1.38 0 2.5-1.12 2.5-2.5 0-1-.59-1.85-1.43-2.25.84-.4 1.43-1.25 1.43-2.25 0-1.38-1.12-2.5-2.5-2.5-.53 0-1.01.16-1.42.44l.02-.19C14.5 2.12 13.38 1 12 1S9.5 2.12 9.5 3.5l.02.19c-.4-.28-.89-.44-1.42-.44-1.38 0-2.5 1.12-2.5 2.5 0 1 .59 1.85 1.43 2.25-.84.4-1.43 1.25-1.43 2.25zM12 5.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8s1.12-2.5 2.5-2.5zM3 13c0 4.97 4.03 9 9 9 0-4.97-4.03-9-9-9z' />
							</svg>
							{like}
						</>
					)}
				</div>
			</div>
		</div>
	);
});
