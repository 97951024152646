export const requstOrientation = (
	setIsParallax: React.Dispatch<React.SetStateAction<boolean>>,
	setIsGyroscope: React.Dispatch<React.SetStateAction<boolean>>
) => {
	try {
		//@ts-ignore
		DeviceOrientationEvent.requestPermission().then(function (res: string) {
			const permision = res === 'granted' ? true : false;

			setIsGyroscope(permision);
			localStorage.setItem('is_yroscope', JSON.stringify(permision));
		});
	} catch (err) {}
	setIsParallax(res => {
		localStorage.setItem('is_parallax', JSON.stringify(!res));
		return !res;
	});
};
