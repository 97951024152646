import { AxiosError } from 'axios';
import { isAcceptOwner } from 'entities/profile';
import { useUser } from 'entities/user';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { ErrorResponse } from 'shared/types/errorResponse';

export const useIsAccess = (error: Error | null, array: any) => {
	const _error = error as AxiosError<ErrorResponse>;
	const navigate = useNavigate();

	const { _id } = useUser();

	const isAccept = isAcceptOwner(array, _id as string);

	useEffect(() => {
		if (isAccept !== null && !isAccept) {
			navigate(AppRouter.NOT_FOUND);
		}
	}, [isAccept]);

	useEffect(() => {
		if (_error?.response?.data?.message === 'Profile not valid') {
			navigate(AppRouter.NOT_FOUND);
		}
	}, [_error]);
};
