import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ProfileSchema } from '../../types/profile';

type ProfileProps = {
	isLoading: boolean;
	isError: boolean;
	data: ProfileSchema;
};

type Action = {
	initial: (data: ProfileSchema, isLoading: boolean) => void;
	setError: (isError: boolean, isLoading: boolean) => void;
};

export const useProfile = create<ProfileProps & Action>()(
	immer(set => ({
		isLoading: true,
		isError: false,
		data: {} as ProfileSchema,
		initial: (data, isLoading) =>
			set(state => {
				state.data = data;
				state.isLoading = isLoading;
			}),
		setError: (isError, isLoading) =>
			set(state => {
				state.isError = isError;
				// state.isLoading = isLoading;
			}),
	}))
);
