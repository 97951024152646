import { useMutation } from '@tanstack/react-query';
import { VideoApi } from 'entities/video/api/VideoApi';

export const useCreateVideo = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: VideoApi.create,
		});

	return {
		mutateVideo: mutate,
		isPendingVideo: isPending,
		isSuccessVideo: isSuccess,
		isErrorVideo: isError,
		errorVideo: error,
		resetVideo: reset,
		dataVideo: data,
	};
};
