export function checkAndAddStringToLocalStorage(
	key: string,
	searchString: string | null | undefined
): boolean {
	try {
		// Проверяем, что searchString не undefined, не null и не пустая строка
		if (typeof searchString !== 'string' || searchString.trim() === '') {
			return false;
		}

		// Получаем данные из localStorage по ключу
		const storedData = localStorage.getItem(key);

		// Парсим данные как массив, если они существуют, иначе создаем пустой массив
		const array: string[] = storedData ? JSON.parse(storedData) : [];

		// Проверяем, есть ли строка в массиве
		const exists = array.includes(searchString);

		if (!exists) {
			// Если строки нет, добавляем ее в массив
			array.push(searchString);

			// Сохраняем обновленный массив обратно в localStorage
			localStorage.setItem(key, JSON.stringify(array));
		}

		// Возвращаем true если строка была добавлена, false если уже существовала
		return !exists;
	} catch (error) {
		return false;
	}
}
