import { useMutation } from '@tanstack/react-query';
import { UserApi } from './../../api/userApi';

export const useFetchUser = () => {
	const { mutate, data, isPending, isSuccess, error, reset } = useMutation({
		mutationFn: UserApi.me,
	});

	return { mutate, isPending, isSuccess, error, reset, data };
};
