import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'entities/auth';
import { useCreateVideo } from 'entities/video/lib/hooks/useCreateVideo';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player/lazy';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { KEY_REDIRECT_AUTH_PROFILE } from 'shared/const/app';
import { InputText } from 'shared/ui/InputText';
import { Heading, Modal } from 'shared/ui/Modal';
import { VideoSkeleton } from 'shared/ui/Skeleton/VideoSkeleton';
import { useVideoModal } from '../model/hooks/useVideoModal';
import { youtubeValidation } from './../model/validations/youtube.validation';

export function VideoModal() {
	const { profileId } = useParams();
	const { isAuth } = useAuth();
	const navigate = useNavigate();

	const [youtube, setYoutube] = useState<string>();

	const {
		isOpen,
		onClose,
		isAuth: isAuthVideo,
		isModerate,
		refetch,
	} = useVideoModal();
	const { mutateVideo, isSuccessVideo, resetVideo, isErrorVideo } =
		useCreateVideo();

	const showAuth = isAuthVideo ? isAuth : true;

	const { control, handleSubmit, resetField } = useForm({
		defaultValues: {
			url: '',
		},
		resolver: showAuth ? yupResolver(youtubeValidation) : undefined,
	});

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined;
		if (isSuccessVideo) {
			if (isModerate) {
				timer = setTimeout(() => {
					onClose();
					resetVideo();
					refetch();
				}, 3000);
			} else {
				onClose();
				resetVideo();
				refetch();
			}
		}

		return () => timer && clearTimeout(timer);
	}, [isSuccessVideo, isModerate]);

	const onSubmitHandler = ({ url }: { url: string }) => {
		if (!showAuth) {
			navigate(AppRouter.AUTH);
			localStorage.setItem(KEY_REDIRECT_AUTH_PROFILE, profileId as string);
			onCloseHandler();
		} else {
			if (youtube) {
				mutateVideo({ profileId: profileId as string, url: youtube });
			} else {
				setYoutube(url);
			}
		}
	};

	const onCloseHandler = () => {
		onClose();
		setYoutube(undefined);
		resetField('url');
	};

	const bodyContent = (
		<div className='flex flex-col gap-5'>
			{!youtube && (
				<Heading
					center
					title={'Вставте посилання на відео з YouTube.'}
					subtitle={
						'Для кращої конфіденційності, можете встановити доступ до відео "тільки за посиланням".'
					}
				/>
			)}
			<div className='mb-4'>
				{!youtube && (
					<div className={'mb-4'}>
						<VideoSkeleton isAnimation={false} />
					</div>
				)}
				{youtube ? (
					<ReactPlayer url={youtube} width={'100%'} height={'300px'} />
				) : (
					<InputText
						control={control}
						name='url'
						type='text'
						disabled={!showAuth || isSuccessVideo}
						placeholder='Адрес відео на Youtube'
					/>
				)}
				{isErrorVideo && (
					<div className='text-red-400 mt-1'>Виникла помилка.</div>
				)}
				{isSuccessVideo && isModerate && (
					<div className='text-green-400 mt-1'>Чекайте на модерацію.</div>
				)}
			</div>
		</div>
	);

	return (
		<Modal
			isOpen={isOpen}
			title={"Відео пам'яті"}
			actionLabel={
				youtube ? 'Відправити' : !showAuth ? 'Авторизація' : 'Завантажити'
			}
			disabled={isSuccessVideo}
			onClose={onCloseHandler}
			onSubmit={handleSubmit(onSubmitHandler)}
			body={bodyContent}
		/>
	);
}
