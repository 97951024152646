import React from 'react';

function Italic() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 1920'>
			<path
				fill={'currentColor'}
				d='M738.077 0v147.692h348.554L680.477 1772.308H295V1920h886.302v-147.692H832.748l406.006-1624.616h385.477V0z'
			></path>
		</svg>
	);
}

export default React.memo(Italic);
