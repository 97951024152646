import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'entities/auth';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { KEY_REDIRECT_AUTH_PROFILE } from 'shared/const/app';
import { InputText } from 'shared/ui/InputText';
import { Heading, Modal } from 'shared/ui/Modal';
import { useCreateStory } from '../../../model/lib/hooks/useCreateStory';
import { storyValidation } from '../../../model/validations/story.validation';
import { useStoryModal } from '../model/hooks/useStoryModal';

export function StoryModal() {
	const { profileId } = useParams();
	const { isAuth } = useAuth();
	const navigate = useNavigate();

	const {
		isOpen,
		onClose,
		isAuth: isAuthStory,
		isModerate,
		refetch,
	} = useStoryModal();
	const { mutateStCreate, isErrorStCreate, isSuccessStCreate, resetStCreate } =
		useCreateStory();

	const showAuth = isAuthStory ? isAuth : true;

	const { control, handleSubmit, resetField } = useForm({
		defaultValues: {
			fullName: '',
			message: '',
		},
		resolver: showAuth ? yupResolver(storyValidation) : undefined,
	});

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined;
		if (isSuccessStCreate) {
			if (isModerate) {
				timer = setTimeout(() => {
					onClose();
					resetStCreate();
				}, 3000);
			} else {
				onClose();
				resetStCreate();
				refetch();
			}
		}

		return () => timer && clearTimeout(timer);
	}, [isSuccessStCreate, isModerate]);

	const onSubmit = ({
		fullName,
		message,
	}: {
		fullName: string;
		message: string;
	}) => {
		if (!showAuth) {
			navigate(AppRouter.AUTH);
			localStorage.setItem(KEY_REDIRECT_AUTH_PROFILE, profileId as string);
			onClose();
		} else {
			mutateStCreate({ profileId: profileId as string, fullName, message });

			resetField('fullName');
			resetField('message');
		}
	};

	const bodyContent = (
		<div className='flex flex-col gap-5'>
			<Heading center title={'Напишіть коротке повідомлення про померлого'} />
			<div
				className={'flex flex-col gap-3 mb-5'}
				style={{ width: '100%', overflow: 'hidden' }}
			>
				<InputText
					control={control}
					name='fullName'
					type='text'
					disabled={!showAuth || isSuccessStCreate}
					placeholder="Ім'я та прізвище"
				/>
				<InputText
					control={control}
					name='message'
					type='text'
					disabled={!showAuth || isSuccessStCreate}
					placeholder='Повідомлення'
				/>
				{isErrorStCreate && (
					<div className='text-red-400 mt-1'>Виникла помилка.</div>
				)}
				{isSuccessStCreate && isModerate && (
					<div className='text-green-400 mt-1'>Чекайте на модерацію.</div>
				)}
			</div>
		</div>
	);

	return (
		<Modal
			isOpen={isOpen}
			disabled={isSuccessStCreate}
			title={"Повідомлення пам'яті"}
			actionLabel={showAuth ? 'Відправити' : 'Авторизація'}
			onClose={onClose}
			onSubmit={handleSubmit(onSubmit)}
			body={bodyContent}
		/>
	);
}
