import { REPLICATE_STATUS, useReplicateUpdate } from 'entities/file';
import { memo, useEffect } from 'react';
import { Button } from 'shared/ui/Button';
import { CatgoryItem } from 'shared/ui/CatgoryItem';
import { getMedia } from 'shared/utils/getMedia';
import { ReplicateStatus } from 'widgets/ReplicateStatus';

type ReplicateProps = {
	refetchProfile: () => void;
	profileId: string;
	replicateStatus: REPLICATE_STATUS;
};

export const Replicate = memo(
	({ replicateStatus, refetchProfile, profileId }: ReplicateProps) => {
		const imageUrl = getMedia('avatars', profileId);

		const { mutateReplicate, isPendingReplicate, dataReplicate } =
			useReplicateUpdate();

		const onRefreshReplicate = () => {
			mutateReplicate({ profileId: profileId as string, imageUrl });
		};

		useEffect(() => {
			if (!isPendingReplicate && dataReplicate) {
				refetchProfile();
			}
		}, [isPendingReplicate, dataReplicate]);

		return (
			<CatgoryItem
				title={'AR'}
				subtitle='Це оживлена фотографія померлого, яку можна подивитися через AR
камеру, попередньо відсканувавши QR-код.'
			>
				<ReplicateStatus replicateStatus={replicateStatus} />
				<div className={'flex flex-col mt-5'}>
					{replicateStatus === REPLICATE_STATUS.ERROR && (
						<Button
							disabled={isPendingReplicate}
							onClick={onRefreshReplicate}
							long
						>
							Повторити генерацію
						</Button>
					)}
				</div>
			</CatgoryItem>
		);
	}
);
