import { Accordion } from 'shared/ui/Accordion';
import { Provider } from 'shared/ui/Provider';
import { Footer } from 'widgets/Footer';

export const Terms = () => {
	return (
		<Provider>
			<main className='px-5 flex-grow'>
				<h2 className='text-4xl font-extrabold mt-5 mb-5 text-white'>
					Правила користування сайту
				</h2>
				<Accordion title={'Публічна оферта DZVINOK'}>
					<div className={'flex flex-row gap-10'}>
						<div className={'flex'}>
							<div className={'mr-3'}>1.1</div>
							<div>
								Документ, положення якого викладені нижче по тексту, є публічною
								офертою та публічним договором. Згідно із положеннями ст.ст.
								633, 641 Цивільного кодексу України умови публічної оферти та
								публічного договору є однаковими для всіх Користувачів
								(Замовників). У відповідності до ч. 2 ст. 642 Цивільного кодексу
								України, реєстрація на web-сайті https://dzvinok.org є акцептом
								даної оферти, що прирівнюється до укладення договору на умовах
								викладених нижче. Дана публічна оферта адресована всім
								дієздатним фізичним особам, які досягли 16 років, що бажають
								скористатися послугою і мають технічну можливість одержання
								послуги.
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Терміни та визначення'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>2.1</div>
							<div>
								Публічна оферта – пропозиція Виконавця укласти з ним публічний
								договір про надання послуг доступу до сервісу DZVINOK адресована
								всім дієздатним фізичним особам, що досягли 16 років, у
								відповідності зі статтею 633 Цивільного кодексу України.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.2</div>
							<div>
								Акцепт оферти - повна й безумовна згода Користування на
								укладення з Виконавцем цього публічного договору про надання
								доступу до сервісу DZVINOK, шляхом реєстрації на ньому згідно з
								умовами, що пропонуються.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.3</div>
							<div>
								Сервіс DZVINOK - означає усі веб-сторінки, розміщення в мережі
								Інтернет за адресою: https://dzvinok.org.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.4</div>
							<div>
								Користувач – дієздатна фізична особа, що досягла 16 років та
								використовує сервіс DZVINOK та модерує створені ним Профілі
								пам’яті.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.5</div>
							<div>
								Виконавець – фізична особа-підприємець Пентелейчук Василь
								Георгiйович, Адреса: Чернівецька область, Глибоцький район
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.6</div>
							<div>
								Послуга – це надання Виконавцем Користувачу доступу до сервісу
								DZVINOK на платній, після проходження Користувачем реєстрації в
								порядку, встановленому даним Договором.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.7</div>
							<div>
								Служба підтримки сервісу IMMORTAL – служба, до якої Користувач
								може звертатися за наданням технічної підтримки щодо вирішенням
								проблеми, що виникла, а також за отриманням додаткової
								інформації з питання, що цікавить. Звернення до служби підтримки
								Сервісу можуть бути спрямовані: • Електронною поштою
								waitoatime@gmail.com • За номером телефону +380958301730
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.8</div>
							<div>
								Профіль пам’яті – запис створений Користувачем про померлу особу
								з метою збереження пам’яті про неї за допомогою оцифрованих
								матеріалів, таких як: •Текст • Фото • Відео • Аудіо • Інші форми
								інформації. Користувач може створювати необмежену кількість
								Профілів пам’яті.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>2.8</div>
							<div>
								Унікальний QR–код – код, що автоматично генерується для сторінки
								пам’яті та може за бажанням Користувача розміщуватися на
								унікальній таблиці або вибиватися на пам'ятнику особи для якої
								створений Профіль пам’яті.
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Загальні положення'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>3.1</div>
							<div>
								На підставі та умовах визначених даним Договором Виконавець
								надає Користувачу, а Користувач приймає послуги з доступу до
								сервісу DZVINOK лише після здійснення Користувачем реєстрації на
								сайті https://dzvinok.org та створення Профілю пам’яті.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>3.2</div>
							<div>Послуга надається на платній основі.</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>3.3</div>
							<div>
								Всі зміни й доповнення до даного Договору опубліковуються на
								сайті Виконавця.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>3.4</div>
							<div>
								Всі умови даного Договору є обов'язковими як для Користувача,
								так і для Виконавця. Перед початком користування Послугою
								Користувач зобов'язаний ознайомитися з умовами даного Договору.
								Якщо Користувач не згідний з умовами даного Договору, він не
								вправі користуватися послугами.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>3.5</div>
							<div>
								У разі незгоди Користувача зі змінами, внесеними Виконавцем у
								даний Договір або з новими тарифами на Послугу, Користувач
								повинен припинити користування сервісом.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>3.6</div>
							<div>
								Користувач — суб'єкт персональних даних, який пройшовши
								реєстрацію на сервісі, надає згоду на обробку його персональних
								даних в базі персональних даних Виконавця, а також погоджується
								отримувати регулярні електронні листи та інші повідомлення від
								сервісу.
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Порядок надання послуг'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>4.1</div>
							<div>
								За умовами цього Договору Користувач самостійно реєструється на
								сервісі DZVINOK та створює облікові дані для входу у свій
								обліковий запис, надаючи адресу електронної пошти.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>4.2</div>
							<div>
								Здійснивши реєстрацію на сервісі DVINOK Користувач отримує
								можливість створити Профіль пам’яті про особу, яка померла та
								пам’ять про яку Користувач має на меті зберегти шляхом
								користування сервісом.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>4.3</div>
							<div>
								Користувач може припинити дію свого облікового запису за власним
								рішенням у будь-який час, написавши на електронну пошту Служби
								підтримки сервісу DZVINOK waitoatime@gmail.com, після чого
								отримує зворотне автоматичне підтвердження на свою електронну
								пошту про видалення облікового запису протягом 24 годин.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>4.4</div>
							<div>
								Виконавець має право в односторонньому порядку видалити або
								призупинити доступ до всього або частини сайту
								https://dzvinok.org з причин порушення або недотримання умов
								цього Договору, а також в інших випадках, визначених на розсуд
								Виконавця, які унеможливлюють доступ до сайту
								https://dzvinok.org
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Права та обовʼязки сторін'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>5.1</div>
							<div>
								Виконавець зобов'язується:
								<ol className='mt-2 space-y-1 list-decimal list-inside'>
									<li>
										Надавати Користувачу послуги відповідно до цього Договору.
									</li>
									<li>
										Зберігати конфіденційність персональних даних Користувача,
										отриманих при реєстрації Акаунту та/або використання
										сервісу.
									</li>
									<li>
										Інші зобов’язання, які передбачені та випливають з чинного в
										Україні законодавства.
									</li>
								</ol>
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>5.2</div>
							<div>
								Виконавець має право:
								<ol className='mt-2 space-y-2 list-decimal list-inside'>
									<li>
										Припиняти надання послуг відповідно до умов цього Договору.
									</li>
									<li>
										Змінювати порядок надання послуг та їх вартість без
										переукладання даного Договору, опублікувавши зміни на своєму
										сайті https://dzvinok.org
									</li>
									<li>
										Розкривати будь-які відомості, якщо це необхідно, відповідно
										до законодавства України, на вимогу уповноважених державних
										установ, а також для забезпечення нормального функціонування
										сервісу https://dzvinok.org або захисту Виконавця та
										Користувачів, якщо це не суперечить відповідним
										Конституційним правам громадян та діючому законодавству
										України.
									</li>
									<li>
										Поширювати надану інформацію Користувачем, зокрема про осіб
										щодо яких створена Сторінка пам’яті публічного доступу на
										будь-яких інтернет-ресурсах, у тому числі соціальних мережах
										тощо.
									</li>
									<li>
										Інші права, які передбачені та випливають з чинного в
										Україні законодавства.
									</li>
								</ol>
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>5.3</div>
							<div>
								Користувач зобов'язується:
								<ol className='mt-2 space-y-2 list-decimal list-inside'>
									<li>
										Користуватися сервісом Dzvinok з дотриманням його ідеї та
										мети.
									</li>
									<li>Здійснити оплату послуг згідного з даним Договором.</li>
									<li>Дотримуватися умов даного Договору.</li>
									<li>
										Не перепродавати, не надавати результати послуг за даним
										Договором третім особам.
									</li>
									<li>
										Ознайомитись з Договором, умовами надання обраних послуг,
										дотримуватись та виконувати їх вимоги.
									</li>
									<li>Тримати в таємниці всі мережеві реквізити (email).</li>
									<li>
										Не здійснювати збір, копіювання і обробку персональних даних
										інших осіб, а також будь-якої іншої інформації, розміщеної в
										Сервісі, зокрема шляхом парсингу, тобто збору, аналізу,
										обробки та збереження розміщеної на веб-сторінках Сервісу
										інформації шляхом застосування програм-парсерів («павуків»)
										та їхніх аналогів.
									</li>
									<li>
										Інші зобов’язання, які передбачені та випливають з чинного в
										Україні законодавства.
									</li>
								</ol>
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>5.4</div>
							<div>
								Користувач має право:
								<ol className='mt-2 space-y-2 list-decimal list-inside'>
									<li>Отримувати інформацію про послуги сервісу Dzvinok.</li>
									<li>Використовувати сервіс у межах його функціоналу.</li>
									<li>
										У разі виникнення будь-яких питань, скарг або пропозицій
										щодо використання сервісу або необхідності в отриманні іншої
										інформації в межах компетенції Виконавця, звертатися до
										служби технічної підтримки за допомогою відповідного
										функціоналу Сервісу або шляхом надсилання повідомлення на
										електронну пошту Виконавця.
									</li>
									<li>
										Інші права, які передбачені та випливають з чинного в
										Україні законодавства
									</li>
								</ol>
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>5.5</div>
							<div>
								Користувач не має права:
								<ol className='mt-2 space-y-2 list-decimal list-inside'>
									<li>
										вибирати або використовувати облікові дані іншої особи, щоб
										видати себе за цю особу; - використовувати дані облікового
										запису без дозволу власника; - використовувати облікові дані
										на власний розсуд, якщо вони є або вважаються образливими.
									</li>
								</ol>
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Вартість послуги та порядок оплати'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>6.1</div>
							<div>
								Вартість Послуг за даним Договором визначається відповідно до
								діючих тарифних планів, які опубліковані на сайті
								https://dzvinok.org Ціни вказуються в національній валюті
								України.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>6.2</div>
							<div>
								Оплата Послуг здійснюється Користувачем шляхом безготівкового
								платежу на розрахунковий рахунок Виконавця.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>6.3</div>
							<div>
								Послуги надаються за умови внесення оплати в розмірі 100% за
								фактом надходження оплати на розрахунковий рахунок Виконавця.
								Датою початку надання Послуг Виконавцем є день надходження
								коштів на розрахункових рахунок Виконавця від Замовника.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>6.4</div>
							<div>
								Замовник самостійно несе відповідальність за правильність
								здійснених ним платежів.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>6.5</div>
							<div>
								У випадку припинення користування Сервісом DZVINOK Користувачем
								відповідно до п.3.5. та 9.2. цього Договору, кошти, внесені
								Користувачем за оплату Послуг згідно з цим Договором, не
								повертаються.
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Інформація сервісу DZVINOK'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>7.1</div>
							<div>
								Матеріали, опубліковані на сайті https://dzvinok.org, призначені
								для особистого, некомерційного використання. Усі матеріали,
								опубліковані на сайті, захищені авторським правом і належать або
								контролюються Виконавцем та/або які її надають.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>7.2</div>
							<div>
								Користувачі зобов’язані дотримуватися усіх повідомлень, які
								стосуються авторських прав, інформації та обмежень, що містяться
								на будь-якій сторінці сайту https://dzvinok.org.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>7.3</div>
							<div>
								Увесь контент та інформація сайту захищені законодавством
								України та міжнародним законодавством про авторське право. Вони
								не можуть бути змінені, опубліковані, передані, продані,
								відтворені, спотворені, поширені повністю або частково.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>7.4</div>
							<div>
								Користувач можете завантажувати або копіювати вміст, інші
								компоненти та елементи, що відображаються на сайті
								https://dzvinok.org, лише для особистого використання з
								дотриманням усіх авторських прав та інших повідомлень, що
								містяться в них.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>7.5</div>
							<div>
								Сервіс DZVINOK дозволяє вільно використовувати інформацію,
								розміщену на сайті, https://dzvinok.org за умови відкритого
								гіперпосилання та посилання на першоджерело.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>7.6</div>
							<div>
								Купуючи QR пам’яті, Сервіс DZVINOK дозволяє Користувачам
								зберігати пам’ять про своїх близьких за допомогою оцифрованих
								матеріалів, таких як: •Текст •Фото •Відео •Аудіо •Інші форми
								інформації. Інформація розміщується на сайті
								https://dzvinok.org.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>7.7</div>
							<div>
								Для Профілю пам’яті створюється QR-код, який містить посилання.
								Користувач може мати 2 типи сторінок: Загальнодоступна. Доступно
								всім користувачам, які сканують QR-код. По геопозиції. Доступно
								тільки в певному географічному місці, визначеному користувачем.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>7.8</div>
							<div>
								Користувач не має права завантажувати, поширювати або
								публікувати вміст, який є наклепницьким, образливим,
								непристойним, порнографічним, повторюваним (спам) або іншим
								чином протизаконним.
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Відповідальність сторін'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>8.1</div>
							<div>
								Виконавець не несе жодної відповідальності за повноту і
								достовірність будь-якої інформації, в тому числі і тієї, яка
								надається Користувачем.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.2</div>
							<div>
								Виконавець докладає усіх зусиль щодо довговічності та
								безперервності користування Сервісом DZVINOK. У випадку
								припинення діяльності Виконавця та/або припинення роботи Сервісу
								DZVINOK з технічних, економічних підстав тощо, Виконавець не
								несе відповідальності у разі такого припинення та не підлягає
								відповідальності за це.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.3</div>
							<div>
								Зведені дані, зв’язки та аналітична інформація, що
								використовується та/або може бути використана Сервісом DZVINOK
								має винятково інформаційний характер. Користувач використовує
								всю інформацію для прийняття будь-яких рішень виключно на
								власний розсуд. Виконавець не несе жодної відповідальності за
								наслідки її використання.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.4</div>
							<div>
								Якщо Замовник не задоволений умовами та/або якістю роботи
								Сервісом DZVINOK, йому необхідно припинити його використання.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.5</div>
							<div>
								Виконавець не несе відповідальності за будь-які збитки (прямі,
								непрямі і випадкові), у тому числі за упущену вигоду, що виникли
								внаслідок порушення у роботі Сервісу DZVINOK, в результаті його
								використання або неможливості використання. Виконавець не несе
								відповідальності за будь-які дії чи бездіяльність особи та їхні
								наслідки, пов’язані із використанням інформації, отриманої з
								Сервісом DZVINOK, якщо інше не встановлено законом.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.6</div>
							<div>
								За невиконання або неналежне виконання своїх зобов’язань за цим
								Договором Сторони несуть відповідальність відповідно до чинного
								законодавства України.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.7</div>
							<div>
								Користувач зобов’язується не порушувати і не намагатися
								порушувати положення Договору. Якщо Виконавець на свій розсуд
								визначить, що Користувач порушив або намагався порушити цей
								Договір, доступ Користувача до Сервісу DZVINOK може бути
								припинений. Виконавець може обмежити доступ Користувача до
								Сервісу, заблокувати або видалити обліковий запис Користувача в
								односторонньому порядку.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.8</div>
							<div>
								Користувач несе повну відповідальність за вміст своїх
								публікацій. Компанія не контролює і не може контролювати всі
								матеріали та не несе відповідальності за зміст цих публікацій,
								Компанія залишає за собою право видаляти, переміщувати або
								редагувати публікації, які Компанія на власний розсуд вважає
								образливими, наклепницькими, непристойними, авторським правом
								або порушення прав на торговельну марку або неналежне.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.9</div>
							<div>
								Матеріали можуть містити посилання на інші веб-сайти Всесвітньої
								павутини, ресурси з посиланнями. Виконавець не несе
								відповідальності за доступність цих зовнішніх ресурсів або їхній
								вміст, при будь-яких сумнівах щодо будь-яких зовнішніх посилань
								необхідно звернутися до Служби підтримки.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>8.10</div>
							<div>
								Будь-яка шахрайська, образлива або інша незаконна діяльність з
								боку Користувача може бути підставою для припинення дії вашого
								облікового запису на наш розсуд, і ми можемо передати інформацію
								до відповідних правоохоронних органів.
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Порядок вирішення спорів'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>9.1</div>
							<div>
								Договір, його укладання та виконання регулюються чинним
								законодавством України. Усі питання, які ним не врегульовані або
								врегульовані не повністю, регулюються відповідно до норм
								матеріального права України.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>9.2</div>
							<div>
								Сторони прагнуть вирішувати суперечки, розбіжності та/або
								вимоги, що виникли шляхом переговорів. Претензійний порядок
								вирішення суперечок, розбіжностей та/або вимог є обов'язковим
								для Сторін. Строк розгляду претензії становить 10 (десять) днів
								з дати її отримання.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>9.3</div>
							<div>
								Сторони визнають, що для цілей вирішення спорів між ними
								ефективним і обов’язковим для використання засобом комунікації є
								листування за допомогою електронної пошти та/або телефоном.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>9.4</div>
							<div>
								Суперечки, розбіжності та/або вимоги, не врегульовані в
								претензійному порядку, підлягають розгляду в суді за місцем
								знаходження Виконавця відповідно до чинного законодавства
								України.
							</div>
						</div>
					</div>
				</Accordion>
				<Accordion title={'Порядок вирішення спорів'}>
					<div className={'flex flex-col gap-7'}>
						<div className={'flex'}>
							<div className={'mr-3'}>10.1</div>
							<div>
								Договір набуває чинності з моменту Акцепту оферти і діє до
								повного виконання Сторонами своїх зобов'язань в об'ємі,
								передбаченому цим Договором або до моменту розірвання Договору.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.2</div>
							<div>
								Цей Договір може бути розірваний між Виконавцем і Користувачем
								шляхом видалення облікового запису Користувача за його власною
								ініціативою або відповідно до п. 3.5. цього Договору. У випадку
								видалення облікового запису Користувача, усі створені ним
								Сторінки також видаляються, а QR –коди стають неактивними.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.3</div>
							<div>
								Договір набуває чинності з моменту опублікування відповідної
								редакції на сайті https://dzvinok.org і діє до моменту виконання
								умов Оферти.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.4</div>
							<div>
								Цей Договір в контексті статті 628 Цивільного кодексу України є
								змішаним договором.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.5</div>
							<div>
								Усі правовідносини, що виникають у зв'язку з виконанням умов
								цього Договору, регламентуються нормами чинного законодавства
								України.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.6</div>
							<div>
								Здійснивши Акцепт оферти Користувач надав свою згоду на обробку
								персональних даних.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.7</div>
							<div>
								Виконавець має право вносити зміни або доповнення до публічної
								оферти у будь-який час без будь-якого спеціального повідомлення,
								розмістивши нову редакцію у відповідному розділі на сайті
								https://dzvinok.org. Нова редакція набуває чинності з моменту її
								розміщення сайтом dzvinok.org, якщо інше не передбачено нею.
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.8</div>
							<div>
								Погоджуючись з цією Публічною офертою, Користувач надає право
								Виконавцю надсилати Користувачу електронні листи щодо участі в
								опитуванні користувачів, поставити запитання з метою покращення
								поточного чи перспективного вмісту. Така інформація може бути
								використана для покращення сайту і кращого розуміння потреб
								користувачів. Будь-яка інформація, яка отримується під час таких
								опитувань, не, передаватиметься третім сторонам, за винятком
								узагальненої форми без розкриття особи Користувача
							</div>
						</div>
						<div className={'flex'}>
							<div className={'mr-3'}>10.9</div>
							<div>
								Усі запити та питання щодо використання Сервісу DZVINOK, можуть
								бути звернені до Служби підтримки сервісу DZVINOK.
							</div>
						</div>
					</div>
				</Accordion>
			</main>
			<Footer paddgin={false} />
		</Provider>
	);
};
