import { useQuery } from '@tanstack/react-query';
import { ProfileApi } from '../../../../../pages/dashboard/profile/model/api/profileApi';

export const useFetchProfile = (profileId: string, enabled: boolean = true) => {
	const { isPending, isSuccess, isError, isLoading, data, error, refetch } =
		useQuery({
			queryKey: ['avatar', profileId],
			queryFn: () => ProfileApi.getById(profileId),
			enabled: enabled,
			retry: false,
			staleTime: 600,
		});

	return { isPending, isError, isSuccess, isLoading, data, error, refetch };
};
