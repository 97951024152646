import { useMutation } from '@tanstack/react-query';
import { StoryApi } from '../../api/StoryApi';

export const useStoryModeration = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: StoryApi.moderation,
		});

	return {
		mutateMod: mutate,
		isPendingMod: isPending,
		isSuccessMod: isSuccess,
		isErrorMod: isError,
		errorMod: error,
		resetMod: reset,
		dataMod: data,
	};
};
