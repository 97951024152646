import { ProviderApp } from 'app/providers/provider-app';
import { useProfile } from 'entities/profile';
import { StoryItemTypes, useStory } from 'entities/story';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import Plus from 'shared/assets/svgs/Plus';
import { Button } from 'shared/ui/Button';
import { Provider } from 'shared/ui/Provider';
import { MessageSkeleton } from 'shared/ui/Skeleton/MessageSkeleton';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { StoryItem } from 'widgets/StoryItem';
import { StoryModal, useStoryModal } from './StoryModal';

export const Message = () => {
	const { profileId } = useParams();
	const { onOpen } = useStoryModal();

	const { data, isLoading } = useProfile();

	const {
		data: dataStory,
		isLoading: isLoadingStory,
		fetchNextPage,
		isError,
		hasNextPage,
		refetch,
	} = useStory(profileId as string, true);

	const items: StoryItemTypes[] =
		dataStory?.pages?.flatMap(page => page.data) ?? [];

	const onOpenModal = () => {
		onOpen(data._storyModerate, data._storyAuth, refetch);
	};

	return (
		<Provider>
			<ProviderApp>
				<StoryModal />
				<div className={'flex justify-between items-center'}>
					<h2 className='text-4xl font-extrabold mt-5 mb-5 text-white'>
						Стіна пам’яті
					</h2>
					{!isLoading && data._storySwitch && (
						<div>
							<Button onClick={onOpenModal} className=' text-white'>
								<Plus />
							</Button>
						</div>
					)}
				</div>

				<div className={'flex flex-col mt-3'}>
					{isError ? (
						<ErrorLoad onRefresh={refetch} />
					) : (
						<>
							{isLoadingStory ? (
								<div className={'flex flex-col gap-5'}>
									<MessageSkeleton />
									<MessageSkeleton />
									<MessageSkeleton />
								</div>
							) : (
								<>
									{!items.length ? (
										<h4 className={'text-center mt-3 text-slate-400'}>
											Немає жодного посту...
										</h4>
									) : (
										<InfiniteScroll
											dataLength={items.length}
											next={fetchNextPage}
											hasMore={!!hasNextPage}
											loader={
												<div className={'flex flex-col gap-5 mt-5'}>
													<MessageSkeleton />
													<MessageSkeleton />
													<MessageSkeleton />
												</div>
											}
										>
											<div className={'flex flex-col gap-5'}>
												{items.map(item => (
													<StoryItem
														key={item._id}
														{...item}
														edited={false}
														profileId={profileId as string}
													/>
												))}
											</div>
										</InfiniteScroll>
									)}
								</>
							)}
						</>
					)}
				</div>
			</ProviderApp>
		</Provider>
	);
};
