import { useIsAuth } from 'entities/auth';
import { PhotoItemTypes, usePhoto } from 'entities/photo';
import {
	useFetchProfile,
	useIsAccess,
	useProfileUpdate,
} from 'entities/profile';
import { UserProvider } from 'entities/user';
import { PhotoModal, usePhotoModal } from 'pages/profile/photo/ui/PhotoModal';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PhotoProvider } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { useParams } from 'react-router-dom';
import Plus from 'shared/assets/svgs/Plus';
import { AppRouter } from 'shared/config/router';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Indicator } from 'shared/ui/Indicator';
import { Provider } from 'shared/ui/Provider';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { PhotoItem } from 'widgets/PhotoItem';

export const Photo = () => {
	useIsAuth(false, AppRouter.AUTH);

	const { profileId } = useParams();

	const {
		isPending,
		isError,
		isLoading,
		refetch,
		error,
		data: dataProfile,
	} = useFetchProfile(profileId ?? '');

	useIsAccess(error, dataProfile?.owners);

	const {
		data,
		isPending: isPendingPhoto,
		isLoading: isLoadingPhoto,
		fetchNextPage,
		isError: isErrorPhoto,
		hasNextPage,
		refetch: refetchPhoto,
	} = usePhoto(profileId as string, false);

	const { mutateUpd } = useProfileUpdate();
	const { onOpen } = usePhotoModal();

	const [checkEnabled, setCheckEnabled] = useState<boolean>(false);
	const [checkModeration, setCheckModeration] = useState<boolean>(false);
	const [checkAuth, setCheckAuth] = useState<boolean>(false);

	useEffect(() => {
		if (!isPending && !isError) {
			setCheckEnabled(dataProfile._photoSwitch);
			setCheckModeration(dataProfile._photoModerate);
			setCheckAuth(dataProfile._photoAuth);
		}
	}, [isPending, isError]);

	const onCheckEnabled = () => {
		setCheckEnabled(prev => !prev);
		mutateUpd({ profileId: profileId, _photoSwitch: !checkEnabled });
	};

	const onModerationEnabled = () => {
		setCheckModeration(prev => !prev);
		mutateUpd({ profileId: profileId, _photoModerate: !checkModeration });
	};

	const onAuthEnabled = () => {
		setCheckAuth(prev => !prev);
		mutateUpd({ profileId: profileId, _photoAuth: !checkAuth });
	};

	const onOpenModal = () => {
		onOpen(dataProfile._photoModerate, dataProfile._photoAuth, refetchPhoto);
	};

	const items: PhotoItemTypes[] = data?.pages?.flatMap(page => page.data) ?? [];

	return (
		<Provider>
			<UserProvider>
				<Indicator isShow={isLoading || isLoadingPhoto} />
				<PhotoModal />

				<div className='px-5'>
					<div
						className={
							'flex justify-between items-center border-slate-600 border-b pb-2 mt-5 mb-5'
						}
					>
						<h2 className='text-1xl font-extrabold  text-white'>
							Фотографії пам'яті
						</h2>
						{!isLoading && (
							<div>
								<Button onClick={onOpenModal} className='text-white'>
									<Plus />
								</Button>
							</div>
						)}
					</div>
					{isError && !isLoading && (
						<div className={'mt-5'}>
							<ErrorLoad
								onRefresh={() => {
									refetch();
									refetchPhoto();
								}}
							/>
						</div>
					)}

					{!isError && !isLoading && (
						<>
							<div className={'flex flex-col gap-5'}>
								<Checkbox
									disabled={isPending}
									name={'_photoSwitch'}
									onChange={onCheckEnabled}
									checked={checkEnabled}
									title={'Публікація фотографій'}
									text={
										'Ви можете вимкнути або увімкнути публікацію фотографій.'
									}
								/>
								<Checkbox
									disabled={isPending}
									name={'_photoAuth'}
									onChange={onAuthEnabled}
									checked={checkAuth}
									title={'Безпечні фотографії'}
									text={'Фотографії можуть завантажувати лише авторизовані.'}
								/>
								<Checkbox
									disabled={isPending}
									name={'_photoModerate'}
									onChange={onModerationEnabled}
									checked={checkModeration}
									title={'Модерація фотографії'}
									text={'Фотографії будуть опубліковані після модерації.'}
								/>
							</div>
							<div className={'flex flex-col mt-3'}>
								<h2 className='text-1xl font-extrabold border-slate-600 border-b pb-2 mt-5 mb-5 text-white'>
									Список фотографій
								</h2>
								{isPendingPhoto && (
									<h4 className={'text-center mt-3 text-white'}>
										Завантаження...
									</h4>
								)}

								{!isPendingPhoto && !items.length && (
									<h4 className={'text-center mt-3 text-slate-400'}>
										Немає жодной фотографій...
									</h4>
								)}

								{!isPendingPhoto && !isErrorPhoto && items.length > 0 && (
									<PhotoProvider
										speed={() => 200}
										easing={type =>
											type === 2
												? 'cubic-bezier(0.36, 0, 0.66, -0.56)'
												: 'cubic-bezier(0.34, 1.56, 0.64, 1)'
										}
									>
										<InfiniteScroll
											dataLength={items.length}
											next={fetchNextPage}
											hasMore={!!hasNextPage}
											loader={
												<h4 className={'text-center mt-3 text-white'}>
													Завантаження...
												</h4>
											}
										>
											<div className={'flex flex-wrap gap-1'}>
												{items.map(item => (
													<PhotoItem
														key={item.url}
														{...item}
														edited={true}
														profileId={profileId as string}
													/>
												))}
											</div>
										</InfiniteScroll>
									</PhotoProvider>
								)}
							</div>
						</>
					)}
				</div>
			</UserProvider>
		</Provider>
	);
};
