import React from 'react';

interface CubeProps {
	color: string;
	width: number;
	height: number;
}

function Quote({ color, width, height }: CubeProps) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill={color}
		>
			<path d='M9.563 8.469l-.813-1.25C3.125 11 0 15.594 0 19.375c0 3.656 2.688 5.375 4.969 5.375 2.875 0 4.906-2.438 4.906-5 0-2.156-1.375-4-3.219-4.688-.531-.188-1.031-.344-1.031-1.25 0-1.156.844-2.875 3.938-5.344zm12.406 0l-.813-1.25c-5.563 3.781-8.75 8.375-8.75 12.156 0 3.656 2.75 5.375 5.031 5.375 2.906 0 4.969-2.438 4.969-5 0-2.156-1.406-4-3.313-4.688-.531-.188-1-.344-1-1.25 0-1.156.875-2.875 3.875-5.344z'></path>
		</svg>
	);
}

export default React.memo(Quote);
