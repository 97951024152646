import { useMutation } from '@tanstack/react-query';
import { VideoApi } from 'entities/video/api/VideoApi';

export const useVideoModeration = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: VideoApi.moderation,
		});

	return {
		mutateMod: mutate,
		isPendingMod: isPending,
		isSuccessMod: isSuccess,
		isErrorMod: isError,
		errorMod: error,
		resetMod: reset,
		dataMod: data,
	};
};
