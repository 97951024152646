import { memo } from 'react';

export const LocationError = memo(() => {
	return (
		<div className='mt-4 mb-5 p-4 border border-red-500 rounded bg-red-100 text-red-700'>
			<p className='mt-2'>
				Щоб увімкнути доступ до геолокації, виконайте наступні кроки:
			</p>
			<div className='mt-4'>
				<h4 className='font-bold'>На iPhone:</h4>
				<ol className='list-decimal list-inside mt-2 space-y-1'>
					<li>
						Відкрийте додаток <b>Налаштування</b>.
					</li>
					<li>
						Прокрутіть вниз і знайдіть ваш браузер (наприклад, Safari, Chrome).
					</li>
					<li>
						Натисніть на нього і знайдіть розділ <b>Місцезнаходження</b>.
					</li>
					<li>
						Переконайтеся, що для вашого браузера дозволено використання
						місцезнаходження.
					</li>
				</ol>
			</div>
			<div className='mt-4'>
				<h4 className='font-bold'>На Android:</h4>
				<ol className='list-decimal list-inside mt-2 space-y-1'>
					<li>
						Відкрийте додаток <b>Налаштування</b>.
					</li>
					<li>
						Виберіть <b>Додатки та сповіщення</b> або <b>Додатки</b>.
					</li>
					<li>Знайдіть і виберіть ваш браузер (наприклад, Chrome).</li>
					<li>
						Перейдіть до розділу <b>Дозволи</b>.
					</li>
					<li>
						Переконайтеся, що для вашого браузера дозволено використання
						місцезнаходження.
					</li>
				</ol>
			</div>
		</div>
	);
});
