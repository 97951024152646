export function checkGeoAccess(profileId: string): boolean {
	try {
		const key = 'geo';
		const storedData = localStorage.getItem(key);
		if (!storedData) {
			return false;
		}

		const profiles: { profileId: string; date: string }[] =
			JSON.parse(storedData);
		const profile = profiles.find(profile => profile.profileId === profileId);

		if (!profile) {
			return false;
		}

		const profileDate = new Date(profile.date);
		const currentDate = new Date();
		const hoursDifference =
			(currentDate.getTime() - profileDate.getTime()) / (1000 * 60 * 60);

		return hoursDifference <= 6;
	} catch (error) {
		return false;
	}
}
