import { authInstance } from 'shared/api';

export const StoryApi = {
	async get({
		profileId,
		pageParam = 0,
		moderate,
	}: {
		profileId: string;
		pageParam: number;
		moderate: boolean;
	}) {
		return authInstance
			.get('/stories', {
				params: { profileId, limit: 6, cursor: pageParam, moderate },
			})
			.then(response => {
				return response.data;
			});
	},
	async delete({ profileId, storyId }: { profileId: string; storyId: string }) {
		return authInstance
			.delete('/stories', {
				data: { profileId, storyId },
			})
			.then(response => {
				return response.data;
			});
	},
	async moderation({
		profileId,
		storyId,
	}: {
		profileId: string;
		storyId: string;
	}) {
		return authInstance
			.put('/stories', { profileId, storyId })
			.then(response => {
				return response.data;
			});
	},
	async create({
		profileId,
		fullName,
		message,
	}: {
		profileId: string;
		fullName: string;
		message: string;
	}) {
		return authInstance
			.post('/stories', { profileId, fullName, message })
			.then(response => {
				return response.data;
			});
	},
};
