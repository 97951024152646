import { useInfiniteQuery } from '@tanstack/react-query';
import { VideoApi } from 'entities/video/api/VideoApi';

export const useVideo = (profileId: string, moderate: boolean) => {
	const {
		data,
		isError,
		fetchNextPage,
		refetch,
		hasNextPage,
		isFetching,
		isPending,
		isLoading,
		isFetchingNextPage,
		status,
		fetchStatus,
	} = useInfiniteQuery({
		queryKey: ['video', profileId],
		queryFn: ({ pageParam }) =>
			VideoApi.get({ profileId, pageParam, moderate }),
		initialPageParam: 0,
		retry: false,
		getNextPageParam: lastPage => lastPage.nextCursor || undefined,
		select: sel => ({
			pages: [...sel.pages],
			pageParams: [...sel.pageParams],
		}),
	});

	return {
		data,
		isError,
		fetchNextPage,
		isPending,
		isLoading,
		refetch,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		status,
		fetchStatus,
	};
};
