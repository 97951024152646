import { ButtonHTMLAttributes, ReactNode } from 'react';

type ButtonProps = {
	children: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonRed = ({ children, ...res }: ButtonProps) => {
	return (
		<button
			{...res}
			type='button'
			className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center bg-red-600 hover:bg-red-700 focus:ring-red-800`}
		>
			{children}
		</button>
	);
};
