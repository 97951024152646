import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

export type InputTextType = React.InputHTMLAttributes<HTMLInputElement> & {
	name: string;
	rules?: Pick<
		RegisterOptions,
		'maxLength' | 'minLength' | 'validate' | 'required'
	>;
};

type InputTextProps = InputTextType & {
	control: Control<any, any>;
};

export const InputText = React.memo((props: InputTextProps) => {
	const { control, name, rules, ...res } = props;

	return (
		<Controller
			control={control}
			rules={rules}
			render={({
				field: { onChange, onBlur, value },
				fieldState: { error },
			}) => (
				<div className='input-container'>
					<div className='input-wrapper'>
						<input
							{...res}
							id={name}
							name={name}
							value={value || ''}
							onBlur={onBlur}
							onChange={onChange}
							className={`bg-slate-700 border ${
								!error ? 'border-slate-700' : 'border-red-400'
							} text-white w-full text-sm px-4 py-3.5 rounded-md outline-blue-600`}
						/>
						<div className='text-red-400 mt-1'>{error?.message}</div>
					</div>
				</div>
			)}
			name={name}
		/>
	);
});
