import { GeoCoords, useProfileUpdate } from 'entities/profile';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGeolocation from 'shared/hooks/useGeolocation';
import { Button } from 'shared/ui/Button';
import { CatgoryItem } from 'shared/ui/CatgoryItem';
import { Checkbox } from 'shared/ui/Checkbox';
import { MapComponent } from 'shared/ui/MapComponent';
import { LocationError } from 'widgets/LocationLock';

type GeolocationProps = {
	_geoCoords: GeoCoords;
	_geoSwitch: boolean;
	refetchProfile: () => void;
};

export const Geolocation = memo(
	({ _geoSwitch, refetchProfile, ...props }: GeolocationProps) => {
		const {
			coords,
			isLoading,
			errorMessage,
			requestGeolocationPermission,
			isGeolocationAvailable,
			isGeolocationEnabled,
			getCoords,
		} = useGeolocation();

		const [checkEnabled, setCheckEnabled] = useState<boolean>(_geoSwitch);

		const { profileId } = useParams();
		const { mutateUpd, isPendingUpd, dataUpd } = useProfileUpdate();

		useEffect(() => {
			if (!isPendingUpd && dataUpd) {
				refetchProfile();
			}
		}, [isPendingUpd, dataUpd]);

		useEffect(() => {
			if (!isLoading && coords) {
				mutateUpd({ profileId: profileId, _geoCords: coords });
			}
		}, [coords, isLoading]);

		const onRefreshLocation = () => {
			requestGeolocationPermission();
		};

		const onRefreshCords = () => {
			getCoords();
		};

		const onCheckEnabled = () => {
			setCheckEnabled(prev => !prev);
			mutateUpd({ profileId: profileId, _geoSwitch: !checkEnabled });
		};

		const TitleChildren = () => {
			return (
				<>
					{!isGeolocationEnabled ? (
						<Button
							isLoading={isLoading}
							disabled={isLoading}
							onClick={onRefreshLocation}
							long
						>
							Змінити позицію
						</Button>
					) : (
						<>
							{!coords && (
								<Button
									bg={'bg-green-600'}
									isLoading={isPendingUpd}
									disabled={isLoading}
									onClick={onRefreshCords}
									long
								>
									Встановити позицію
								</Button>
							)}
						</>
					)}
				</>
			);
		};

		return (
			<>
				{isGeolocationAvailable && (
					<CatgoryItem
						title={'Геопозиція'}
						subtitle='Геопозиція дозволяє відкрити профіль померлого тільки якщо людина знаходиться близько до встановленої геопозиції.'
						// titleChildren={<TitleChildren />}
					>
						{errorMessage && (
							<div>
								<LocationError />
							</div>
						)}
						<div className={'flex flex-col mb-5'}>
							<Checkbox
								disabled={isPendingUpd}
								name={'_geoSwitch'}
								onChange={onCheckEnabled}
								checked={checkEnabled}
								title={'Доступ лише за геопозицією'}
								text={
									'Для недосвідчених користувачів може бути проблематично відкрити профіль.'
								}
							/>
						</div>
						{props._geoCoords.latitude && props._geoCoords.longitude && (
							<MapComponent {...props._geoCoords} />
						)}
						<div className={'flex flex-col mt-5'}>
							<TitleChildren />
						</div>
					</CatgoryItem>
				)}
			</>
		);
	}
);
