import { useLocation } from 'react-router-dom';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterShareButton,
	ViberIcon,
	ViberShareButton,
	WhatsappIcon,
	WhatsappShareButton,
	XIcon,
} from 'react-share';
import { Heading, Modal } from 'shared/ui/Modal';
import { useShareModal } from './../model/hooks/useShareModal';

export function ShareModal() {
	const { isOpen, onClose } = useShareModal();

	const location = useLocation();
	const url = window.location.origin + location.pathname + location.search;

	const bodyContent = (
		<div className='flex flex-col gap-5'>
			<Heading title={'Поділіться цим профілем через'} />
			<div
				className={'flex gap-3 mb-5'}
				style={{ width: '100%', overflow: 'hidden' }}
			>
				<FacebookShareButton url={url}>
					<FacebookIcon size={40} round />
				</FacebookShareButton>
				<WhatsappShareButton url={url} separator=':: '>
					<WhatsappIcon size={40} round />
				</WhatsappShareButton>
				<EmailShareButton url={url} subject={'Dzvinok'} body='body'>
					<EmailIcon size={40} round />
				</EmailShareButton>
				<ViberShareButton url={url} title={'Dzvinok'}>
					<ViberIcon size={40} round />
				</ViberShareButton>
				<TelegramShareButton url={url} title={'Dzvinok'}>
					<TelegramIcon size={40} round />
				</TelegramShareButton>
				<TwitterShareButton url={url} title={'Dzvinok'}>
					<XIcon size={40} round />
				</TwitterShareButton>
			</div>
		</div>
	);

	const onCopyUrl = async () => {
		await navigator.clipboard.writeText(url);
	};

	return (
		<Modal
			isOpen={isOpen}
			title='Спосіб поділитися'
			actionLabel='Скопіювати посилання'
			onClose={onClose}
			onSubmit={onCopyUrl}
			body={bodyContent}
		/>
	);
}
