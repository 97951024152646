import { memo } from 'react';

interface CubeProps {
	color?: string;
	width: number;
	height: number;
}

function Cube({ color = 'currentColor', width, height }: CubeProps) {
	return (
		<svg
			width={width}
			height={height}
			fill='none'
			viewBox='0 0 24 24'
			// style={{ filter: 'drop-shadow(0px 0px 5px rgba(255, 255, 255, 1))' }}
		>
			<path
				stroke={color}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
				d='M12.92 2.26l6.51 3.51c.76.41.76 1.58 0 1.99l-6.51 3.51c-.58.31-1.26.31-1.84 0L4.57 7.76c-.76-.41-.76-1.58 0-1.99l6.51-3.51c.58-.31 1.26-.31 1.84 0zM3.61 10.13l6.05 3.03c.75.38 1.23 1.15 1.23 1.99v5.72c0 .83-.87 1.36-1.61.99l-6.05-3.03A2.238 2.238 0 012 16.84v-5.72c0-.83.87-1.36 1.61-.99zM20.39 10.13l-6.05 3.03c-.75.38-1.23 1.15-1.23 1.99v5.72c0 .83.87 1.36 1.61.99l6.05-3.03c.75-.38 1.23-1.15 1.23-1.99v-5.72c0-.83-.87-1.36-1.61-.99z'
			></path>
		</svg>
	);
}

export default memo(Cube);
