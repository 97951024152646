export type ProfileOwnerType = {
	_id: string;
	userId: string;
	email: string;
	role: RoleOwnerType;
};

export type GeoCoords = {
	latitude: number;
	longitude: number;
};

export type BurialPlaceType = {
	city: string;
	place: string;
};

export enum RoleOwnerType {
	OWNER = 'owner',
	MODERATOR = 'moderator',
}

export enum ReplicateType {
	EMPTY = 'empty',
	SUCCESS = 'success',
	LOADING = 'loading',
	ERROR = 'error',
}

export type ProfileSchema = {
	_id: string;
	profileID: number;
	activated: boolean;
	isAvatar: boolean;
	avatarUpdatedAt: Date;
	fullName: string;
	short: string;
	biography: string;
	privacyLife: string;
	work: string;
	owners: ProfileOwnerType;
	burialPlace: BurialPlaceType;
	birthDay: string;
	deathDay: string;
	replicateStatus: ReplicateType;
	views: number;
	likes: number;
	_geoSwitch: boolean;
	_profileSwitch: boolean;
	_geoCords: GeoCoords;
	_storyModerate: boolean;
	_storySwitch: boolean;
	_storyAuth: boolean;
	_photoSwitch: boolean;
	_photoModerate: boolean;
	_photoAuth: boolean;
	_videoSwitch: boolean;
	_videoModerate: boolean;
	_videoAuth: boolean;
	createdAt: Date;
};
