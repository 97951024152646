import imageCompression from 'browser-image-compression';
import { useAuth } from 'entities/auth';
import { usePhotoUpload } from 'entities/photo';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { imageOptions } from 'shared/config/image';
import { AppRouter } from 'shared/config/router';
import { KEY_REDIRECT_AUTH_PROFILE } from 'shared/const/app';
import { Heading, Modal } from 'shared/ui/Modal';
import { SkeletonImg } from 'shared/ui/Skeleton/SkeletonImg';
import { usePhotoModal } from './../model/hooks/usePhotoModal';

export function PhotoModal() {
	const { profileId } = useParams();
	const { isAuth } = useAuth();
	const navigate = useNavigate();

	const [image, setImage] = useState<File>();
	const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

	const {
		mutatePhoto,
		isSuccessPhoto,
		resetPhoto,
		isErrorPhoto,
		isPendingPhoto,
	} = usePhotoUpload();
	const {
		isOpen,
		onClose,
		isModerate,
		isAuth: isAuthModal,
		refetch,
	} = usePhotoModal();

	const fileInputRef = useRef<HTMLInputElement>(null);
	const showAuth = isAuthModal ? isAuth : true;

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined;
		if (isSuccessPhoto) {
			if (isModerate) {
				timer = setTimeout(() => {
					onCloseHandler();
					resetPhoto();
					refetch();
				}, 3000);
			} else {
				onCloseHandler();
				resetPhoto();
				refetch();
			}
		}

		return () => timer && clearTimeout(timer);
	}, [isSuccessPhoto, isModerate]);

	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files;
		if (file && file.length > 0) {
			const resizedFile = await imageCompression(file[0], imageOptions);
			setImage(resizedFile);
			const objectUrl = URL.createObjectURL(resizedFile);
			setImageSrc(objectUrl);
		}

		event.target.value = '';
	};

	const onSubmitHandler = () => {
		if (!showAuth) {
			navigate(AppRouter.AUTH);
			localStorage.setItem(KEY_REDIRECT_AUTH_PROFILE, profileId as string);
			onCloseHandler();
		} else {
			if (!imageSrc) {
				fileInputRef?.current?.click();
			} else {
				mutatePhoto({ file: image as File, profileId: profileId as string });
			}
		}
	};

	const onCloseHandler = () => {
		onClose();
		if (fileInputRef.current) {
			setImageSrc(undefined);
			fileInputRef.current.value = '';
		}
	};

	const bodyContent = (
		<div className='flex flex-col gap-5'>
			{!imageSrc && <Heading title={'Завантажити фотографію з померлим'} />}
			<div
				className={'flex flex-col gap-3 mb-5'}
				style={{ width: '100%', overflow: 'hidden' }}
			>
				{imageSrc ? (
					<img
						src={imageSrc}
						className={`w-full object-cover rounded-lg`}
						alt='avatar'
					/>
				) : (
					<div className={'h-[200px]'}>
						<SkeletonImg isAnimation={false} />
					</div>
				)}
				<input
					ref={fileInputRef}
					className='hidden'
					id='file'
					accept='image/png,image/jpeg'
					type='file'
					onChange={handleFileChange}
				></input>

				{isErrorPhoto && (
					<div className='text-red-400 mt-1'>Виникла помилка.</div>
				)}
				{isSuccessPhoto && isModerate && (
					<div className='text-green-400 mt-1'>Чекайте на модерацію.</div>
				)}
			</div>
		</div>
	);

	return (
		<Modal
			isOpen={isOpen}
			disabled={isPendingPhoto}
			title={"Фотографія пам'ять"}
			actionLabel={
				imageSrc ? 'Відправити' : !showAuth ? 'Авторизація' : 'Завантажити'
			}
			onClose={onCloseHandler}
			onSubmit={onSubmitHandler}
			body={bodyContent}
		/>
	);
}
