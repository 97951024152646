import { useEffect, useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { SIZE_IMAGE_PROFILE } from 'shared/const/size';
import { Modal } from 'shared/ui/Modal';
import { base64ToBlob, blobToFile } from 'shared/utils/base64ToBlob';
import { useCropModal } from '../../model/hooks/useCropModal';

export function CropModal() {
	const cropModal = useCropModal();
	const cropperRef = useRef<ReactCropperElement>(null);
	const [imageUrl, setImageUrl] = useState('');

	useEffect(() => {
		if (cropModal.image) {
			const reader = new FileReader();
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					setImageUrl(reader.result);
				}
			};
			reader.readAsDataURL(cropModal.image);
		} else {
			setImageUrl('');
		}
	}, [cropModal.image]);

	const onCrop = () => {
		try {
			const cropper = cropperRef.current?.cropper;
			if (cropper) {
				const base64 = cropper.getCroppedCanvas().toDataURL();
				const blob = base64ToBlob(base64, 'image/jpeg');
				const file = blobToFile(blob, 'image');
				cropModal.mutateAvatar(file);
				cropModal.onClose();
			}
		} catch (err) {}
	};

	const bodyContent = (
		<div className='flex flex-col gap-4'>
			<div style={{ width: '100%', overflow: 'hidden' }}>
				{cropModal.image && (
					<Cropper
						ref={cropperRef}
						src={imageUrl}
						className='mb-5'
						style={{ width: '100%', maxHeight: `${SIZE_IMAGE_PROFILE - 50}px` }}
						viewMode={3}
						initialAspectRatio={1 / 0.8}
						autoCropArea={0.95}
						center={false}
						dragMode={'move'}
						movable={true}
						guides={false}
						highlight={true}
						rotatable={false}
						zoomOnWheel={false}
						cropBoxResizable={false}
						cropBoxMovable={false}
						toggleDragModeOnDblclick={false}
						background={true}
						responsive={true}
						// onChange={onCrop}
					/>
				)}
			</div>
		</div>
	);

	return (
		<Modal
			isOpen={cropModal.isOpen}
			title='Світлина'
			actionLabel='Зберегти'
			onClose={cropModal.onClose}
			onSubmit={onCrop}
			body={bodyContent}
		/>
	);
}
