import { ReactNode, useState } from 'react';

interface AccordionProps {
	title: string;
	children: ReactNode;
	defaultOpen?: boolean;
	status?: boolean;
	white?: boolean;
}

export const Accordion = ({
	title,
	children,
	defaultOpen = false,
	status,
	white = false,
}: AccordionProps) => {
	const [isOpen, setIsOpen] = useState(defaultOpen);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className=' border-b border-slate-700'>
			<h2>
				<button
					type='button'
					className={`flex items-center justify-between w-full py-5 font-medium border-b border-indigo-300 ${
						isOpen
							? white
								? 'text-slate-200'
								: 'text-indigo-200'
							: white
							? 'text-slate-300'
							: 'text-indigo-100'
					} gap-3`}
					onClick={toggleAccordion}
					aria-expanded={isOpen}
					aria-controls='accordion-flush-body-1'
				>
					<div className={'flex items-center'}>
						{status !== undefined && (
							<span
								className={`w-[15px] ${
									status ? 'text-green-500' : 'text-red-400'
								} mr-2`}
							>
								<svg
									fill='currentColor'
									viewBox='0 0 1024 1024'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path d='M429.811 577.1l-80.485-86.309c-21.425-22.976-57.42-24.233-80.396-2.807s-24.233 57.42-2.807 80.396l81.839 87.762-.158.152 43.493 45.038c19.643 20.341 52.056 20.907 72.397 1.264l35.686-34.462 8.195-7.642-.133-.143 251.802-243.163c22.607-21.832 23.236-57.857 1.405-80.464s-57.857-23.236-80.464-1.405L429.812 577.1zM512 1024C229.23 1024 0 794.77 0 512S229.23 0 512 0s512 229.23 512 512-229.23 512-512 512z' />
								</svg>
							</span>
						)}
						<span className={'font-semibold text-left'}>{title}</span>
					</div>
					<svg
						className={`w-3 h-3 shrink-0 text-indigo-300 ${
							isOpen ? 'rotate-180' : ''
						}`}
						aria-hidden='true'
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 10 6'
					>
						<path
							stroke='currentColor'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='2'
							d='M9 5 5 1 1 5'
						/>
					</svg>
				</button>
			</h2>
			{isOpen && (
				<div aria-labelledby='accordion-flush-heading-1'>
					<div className='pb-5 text-slate-50'>{children}</div>
				</div>
			)}
		</div>
	);
};
