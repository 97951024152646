import { ProviderApp } from 'app/providers/provider-app';
import { useProfile } from 'entities/profile';
import Quote from 'shared/assets/svgs/Quote';
import { Accordion } from 'shared/ui/Accordion';
import { Provider } from 'shared/ui/Provider';
import { convertDataToHTML } from './../model/utils/convertDataToHTML';

export const Profile = () => {
	const { data, isLoading } = useProfile();

	const { biography, privacyLife, work } = convertDataToHTML({
		biography: data?.biography,
		privacyLife: data?.privacyLife,
		work: data?.work,
	});

	return (
		<Provider>
			<ProviderApp>
				<h2 className='text-4xl font-extrabold mt-5 mb-5 text-white'>
					Інформація
				</h2>
				<div>
					<div>
						<h3 className={'font-semibold pb-2 text-indigo-100'}>Коротко</h3>
						<div className={'flex items-center'}>
							<Quote color={'#a1a1aa'} width={30} height={25} />
							{isLoading ? (
								<div role='status' className='animate-pulse w-full'>
									<div className='h-2.5 bg-slate-400 rounded-full'></div>
								</div>
							) : (
								<span className='text-slate-400 italic'>
									{!data?.short.length ? 'Не вказано' : data?.short}
								</span>
							)}
						</div>
					</div>
					<Accordion
						title={'Життєпис'}
						defaultOpen={biography.length > 0 && true}
					>
						{isLoading ? (
							<div role='status' className='animate-pulse w-full'>
								<div className='h-2.5 bg-slate-400 rounded-full w-8/12 mb-4'></div>
								<div className='h-2.5 bg-slate-300 rounded-full w-10/12 mb-4'></div>
								<div className='h-2.5 bg-slate-500 rounded-full w-7/12'></div>
							</div>
						) : (
							<>
								{!biography.length ? (
									'Не вказано'
								) : (
									<div dangerouslySetInnerHTML={{ __html: biography }} />
								)}
							</>
						)}
					</Accordion>
					<Accordion title={'Місце поховання'}>
						{isLoading ? (
							<div role='status' className='animate-pulse w-full'>
								<div className='h-2.5 bg-slate-400 rounded-full w-8/12 mb-4'></div>
								<div className='h-2.5 bg-slate-300 rounded-full w-10/12 mb-4'></div>
								<div className='h-2.5 bg-slate-500 rounded-full w-7/12'></div>
							</div>
						) : (
							<>
								<div>
									<div className={'font-semibold text-slate-500'}>Місто</div>
									<div className={'text-slate-300'}>
										{data?.burialPlace?.city ?? 'Не вказано'}
									</div>
								</div>
								<div className={'mt-5'}>
									<div className={'font-semibold text-slate-500'}>
										Назва кладовища
									</div>
									<div className={'text-slate-300'}>
										{data?.burialPlace?.place ?? 'Не вказано'}
									</div>
								</div>
							</>
						)}
					</Accordion>
					<Accordion title={'Особисте життя'}>
						{isLoading ? (
							<div role='status' className='animate-pulse w-full'>
								<div className='h-2.5 bg-slate-400 rounded-full w-8/12 mb-4'></div>
								<div className='h-2.5 bg-slate-300 rounded-full w-10/12 mb-4'></div>
								<div className='h-2.5 bg-slate-500 rounded-full w-7/12'></div>
							</div>
						) : (
							<>
								{!privacyLife.length ? (
									'Не вказано'
								) : (
									<div dangerouslySetInnerHTML={{ __html: privacyLife }} />
								)}
							</>
						)}
					</Accordion>
					<Accordion title={'Робота'}>
						{isLoading ? (
							<div role='status' className='animate-pulse w-full'>
								<div className='h-2.5 bg-slate-400 rounded-full w-8/12 mb-4'></div>
								<div className='h-2.5 bg-slate-300 rounded-full w-10/12 mb-4'></div>
								<div className='h-2.5 bg-slate-500 rounded-full w-7/12'></div>
							</div>
						) : (
							<>
								{!work.length ? (
									'Не вказано'
								) : (
									<div dangerouslySetInnerHTML={{ __html: work }} />
								)}
							</>
						)}
					</Accordion>
				</div>
			</ProviderApp>
		</Provider>
	);
};
