import axios from 'axios';

const INSTANCE_TIMEOUT = 3000;
const INSTANCE_HEADER = {
	'Content-Type': 'application/json',
};
export const instance = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: INSTANCE_HEADER,
	timeout: INSTANCE_TIMEOUT,
});
