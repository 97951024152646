import { AxiosError } from 'axios';
import { useFetchProfile, useProfile } from 'entities/profile';
import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { ErrorResponse } from 'shared/types/errorResponse';

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
	const location = useLocation();
	const profileId = location.pathname.split('/').pop();
	const navigate = useNavigate();

	const { refetch, isSuccess, isError, error, data } = useFetchProfile(
		profileId as string,
		false
	);

	const { initial, setError } = useProfile();
	const _error = error as AxiosError<ErrorResponse>;

	// redirect not_found
	useEffect(() => {
		if (_error?.response?.data?.message === 'Profile not valid') {
			navigate(AppRouter.NOT_FOUND);
		}
	}, [_error]);

	useEffect(() => {
		if (profileId) {
			refetch();
		}
	}, [profileId]);

	useEffect(() => {
		if (isError) {
			setError(true, false);
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			initial(data, false);
		}
	}, [isSuccess, data]);

	return <>{children}</>;
};
