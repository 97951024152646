import {
	PhotoItemTypes,
	usePhotoDelete,
	usePhotoModeration,
} from 'entities/photo';
import { useState } from 'react';
import { PhotoView } from 'react-photo-view';
import { Button } from 'shared/ui/Button';
import { ButtonRed } from 'shared/ui/ButtonRed';

type PhotoItemProps = {
	edited: boolean;
	profileId: string;
} & PhotoItemTypes;

export const PhotoItem = (props: PhotoItemProps) => {
	const [isDelete, setIsDetele] = useState(false);
	const [isModerate, setIsModerate] = useState(props.moderation);

	const { mutateDel } = usePhotoDelete();
	const { mutateMod } = usePhotoModeration();

	const onDelete = () => {
		setIsDetele(true);
		mutateDel({ profileId: props.profileId, photoId: props._id });
	};

	const onModerate = () => {
		setIsModerate(true);
		mutateMod({ profileId: props.profileId, photoId: props._id });
	};

	return (
		<>
			{!isDelete && (
				<div className={'flex w-calc-1/3 relative flex-col'}>
					<PhotoView key={props.url} src={props.url}>
						<img
							src={props.url}
							style={{
								// width: 'calc(33.33% - 6px)',
								height: '150px',
								objectFit: 'cover',
							}}
						/>
					</PhotoView>
					{props.isOwner && props.edited && (
						<>
							{!isModerate && (
								<div className={'absolute bottom-1 left-1'}>
									<Button onClick={onModerate}>
										<svg
											className={'w-3 h-3'}
											fill='currentColor'
											viewBox='0 0 24 24'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												id='accept'
												d='M1008,120a12,12,0,1,1,12-12A12,12,0,0,1,1008,120Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,1008,98Zm-0.08,14.333a0.819,0.819,0,0,1-.22.391,0.892,0.892,0,0,1-.72.259,0.913,0.913,0,0,1-.94-0.655l-2.82-2.818a0.9,0.9,0,0,1,1.27-1.271l2.18,2.184,4.46-7.907a1,1,0,0,1,1.38-.385,1.051,1.051,0,0,1,.36,1.417Z'
												transform='translate(-996 -96)'
											/>
										</svg>
									</Button>
								</div>
							)}
							<div className={'absolute bottom-1 right-1'}>
								<ButtonRed onClick={onDelete} color={'indigo'}>
									<svg
										className={'w-3 h-3'}
										viewBox='0 0 24 24'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M10 12V17'
											stroke={'currentColor'}
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M14 12V17'
											stroke={'currentColor'}
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M4 7H20'
											stroke={'currentColor'}
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10'
											stroke={'currentColor'}
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z'
											stroke={'currentColor'}
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</ButtonRed>
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};
