import { useIsAuth } from 'entities/auth';
import { useFetchProfiles } from 'entities/profile';
import { UserProvider } from 'entities/user';
import { AppRouter } from 'shared/config/router';
import { Button } from 'shared/ui/Button';
import { Indicator } from 'shared/ui/Indicator';
import { Provider } from 'shared/ui/Provider';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { Footer } from 'widgets/Footer';
import { ListItem } from './ListItem/ListItem';

export interface ListItemProps {
	profileID: number;
	fullName: string;
	short: string;
	_id: string;
}

export const Lists = () => {
	useIsAuth(false, AppRouter.AUTH);

	const { isLoading, isError, refetch, data } = useFetchProfiles();

	const onLinkBuy = () => {
		window.location.href = '/';
	};

	return (
		<Provider>
			<UserProvider isBottomBar={false}>
				<main className='px-5 flex-grow'>
					{isLoading ? (
						<Indicator isShow={isLoading} />
					) : (
						<>
							<h2 className='text-2xl pb-2 font-extrabold mt-5 mb-5 border-slate-600 border-b text-white'>
								Список профілів
							</h2>
							{isError ? (
								<ErrorLoad onRefresh={refetch} />
							) : (
								<>
									{!data?.length ? (
										<div className='flex text-slate-300'>
											<span>
												У вас не підключено жодного qr-дзвінка профілю.
											</span>
											<Button long onClick={onLinkBuy}>
												Придбати
											</Button>
										</div>
									) : (
										<div className={'flex flex-col gap-5'}>
											{data.map((el: ListItemProps) => {
												return <ListItem key={el._id} {...el} />;
											})}
										</div>
									)}
								</>
							)}
						</>
					)}
				</main>
			</UserProvider>
			<Footer paddgin={false} />
		</Provider>
	);
};
