export function saveGeoAccess(profileId: string): void {
	try {
		const key = 'geo';
		const storedData = localStorage.getItem(key);
		const profiles: { profileId: string; date: string }[] = storedData
			? JSON.parse(storedData)
			: [];

		const existingProfileIndex = profiles.findIndex(
			profile => profile.profileId === profileId
		);
		const currentDate = new Date().toISOString();

		if (existingProfileIndex !== -1) {
			profiles[existingProfileIndex].date = currentDate;
		} else {
			profiles.push({ profileId, date: currentDate });
		}

		localStorage.setItem(key, JSON.stringify(profiles));
	} catch (error) {}
}
