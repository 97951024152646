import { ReactElement, memo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Info from 'shared/assets/svgs/Info';
import Photo from 'shared/assets/svgs/Photo';
import Post from 'shared/assets/svgs/Post';
import Setting from 'shared/assets/svgs/Setting';
import Video from 'shared/assets/svgs/Video';
import { AppRouter } from 'shared/config/router';
import BottomItem from './../BottomItem/BottomItem';

interface BottomItemType {
	id: number;
	title: string;
	icon: ReactElement;
	link: string;
}

const bottomItem: BottomItemType[] = [
	{
		id: 1,
		title: 'Інфо',
		icon: <Info />,
		link: AppRouter.DASHBOARD,
	},
	{
		id: 2,
		title: 'Стіна',
		icon: <Post />,
		link: AppRouter.DASHBOARD_MESSAGES,
	},
	{
		id: 3,
		title: 'Налаштування',
		icon: <Setting />,
		link: AppRouter.DASHBOARD_SETTING,
	},
	{
		id: 4,
		title: 'Фото',
		icon: <Photo />,
		link: AppRouter.DASHBOARD_PHOTO,
	},
	{
		id: 5,
		title: 'Відео',
		icon: <Video />,
		link: AppRouter.DASHBOARD_VIDEO,
	},
];

function BottomBar() {
	const location = useLocation();
	const currentPath = location.pathname;

	const isActive = (link: string) => {
		const exactMatch = matchPath({ path: link, end: true }, currentPath);
		const dynamicMatch = matchPath(
			{ path: `${link}/:profileId`, end: true },
			currentPath
		);
		return exactMatch !== null || dynamicMatch !== null;
	};

	return (
		<div
			className={`fixed bottom-0 w-full max-w-full sm:max-w-lg sm:mx-auto bg-slate-700 border-t-[1px] border-slate-800 z-[500] pb-safe-bottom`}
		>
			<div className={`flex items-center justify-evenly py-2 px-4`}>
				{bottomItem.map(el => {
					return (
						<BottomItem
							key={el.id}
							label={el.title}
							link={el.link}
							Icon={el.icon}
							isActive={isActive(el.link)}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default memo(BottomBar);
