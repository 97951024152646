import { useFetchActivated } from 'pages/dashboard/profile/model/services/useFetchActivated/useFetchActivated';
import { memo, useEffect } from 'react';

interface AddProfileProps {
	profileId: string;
	refetchProfile: () => void;
}

export const AddProfile = memo(
	({ profileId, refetchProfile }: AddProfileProps) => {
		const { mutateAct, isSuccessAct, isPendingAct } = useFetchActivated();

		const onAddQR = () => {
			mutateAct(profileId);
		};

		useEffect(() => {
			if (isSuccessAct) {
				refetchProfile();
			}
		}, [isSuccessAct]);

		return (
			<>
				<div className={'mt-10 flex items-center justify-center'}>
					<div
						className={
							'flex flex-col p-5 items-center bg-slate-700 w-full py-5 rounded-md justify-center'
						}
					>
						<div className={'text-slate-400'}>
							<svg
								className='w-[150px]'
								fill='currentColor'
								viewBox='0 0 512 512'
								xmlns='http://www.w3.org/2000/svg'
							>
								<rect x='336' y='336' width='80' height='80' rx='8' ry='8' />
								<rect x='272' y='272' width='64' height='64' rx='8' ry='8' />
								<rect x='416' y='416' width='64' height='64' rx='8' ry='8' />
								<rect x='432' y='272' width='48' height='48' rx='8' ry='8' />
								<rect x='272' y='432' width='48' height='48' rx='8' ry='8' />
								<path d='M448,32H304a32,32,0,0,0-32,32V208a32,32,0,0,0,32,32H448a32,32,0,0,0,32-32V64A32,32,0,0,0,448,32ZM416,168a8,8,0,0,1-8,8H344a8,8,0,0,1-8-8V104a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8Z' />
								<path d='M208,32H64A32,32,0,0,0,32,64V208a32,32,0,0,0,32,32H208a32,32,0,0,0,32-32V64A32,32,0,0,0,208,32ZM176,168a8,8,0,0,1-8,8H104a8,8,0,0,1-8-8V104a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8Z' />
								<path d='M208,272H64a32,32,0,0,0-32,32V448a32,32,0,0,0,32,32H208a32,32,0,0,0,32-32V304A32,32,0,0,0,208,272ZM176,408a8,8,0,0,1-8,8H104a8,8,0,0,1-8-8V344a8,8,0,0,1,8-8h64a8,8,0,0,1,8,8Z' />
							</svg>
						</div>
						<p className={'mt-5 text-center text-slate-100'}>
							QR-дзвінок успішно був знайдений, ви дійсно бажаєте прив'язати
							його до вашого облікового запису ?
						</p>
						<div className='mt-5 w-full'>
							<button
								onClick={onAddQR}
								disabled={isPendingAct}
								type='button'
								className='w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none'
							>
								Додати
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
);
