import { authInstance } from 'shared/api';

type UploadAvatar = {
	file: File;
	profileId: string;
};

type UpdateReplicate = {
	imageUrl: string;
	profileId: string;
};

export const FileApi = {
	async uploadAvatar({ file, profileId }: UploadAvatar) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('profileId', profileId);

		return authInstance
			.post('/avatar', formData, {
				headers: {
					'Content-type': 'multipart/form-data',
				},
			})
			.then(({ data }: any) => {
				return data.url;
			});
	},
	async updateReplicatre(date: UpdateReplicate) {
		return authInstance
			.post('/avatar/replicate', date)
			.then(({ data }: any) => {
				return data.status;
			});
	},
	async getAvatar(profileId: string) {
		return authInstance.get(`/avatar/${profileId}`).then(({ data }: any) => {
			return data.url;
		});
	},
	async getVideoAvatar(profileId: string) {
		return authInstance
			.get(`/avatar/video/${profileId}`)
			.then(({ data }: any) => {
				return data.url;
			});
	},
};
