import { useMutation } from '@tanstack/react-query';
import 'firebase/compat/auth';
import { AuthApi } from '../../api/authApi';

export const useAuthGoogle = () => {
	const { mutate, data, isPending, isSuccess, error, reset } = useMutation({
		mutationFn: AuthApi.google,
	});

	return {
		mutateGoogle: mutate,
		isPendingGoogle: isPending,
		isSuccessGoogle: isSuccess,
		resetGoogle: reset,
		dataGoogle: data,
		errorGoogle: error,
	};
};
