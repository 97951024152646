import { useMutation } from '@tanstack/react-query';
import { ProfileApi } from './../../api/ProfileApi';

export const useProfileModeratorAdd = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: ProfileApi.moderatorAdd,
		});

	return {
		mutateModeratorAdd: mutate,
		isPendingModeratorAdd: isPending,
		isSuccessModeratorAdd: isSuccess,
		isErrorModeratorAdd: isError,
		errorModeratorAdd: error,
		resetModeratorAdd: reset,
		dataModeratorAdd: data,
	};
};
