import { useMutation } from '@tanstack/react-query';
import { ProfileApi } from '../../api/ProfileApi';

export const useProfileModeratorRm = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: ProfileApi.moderatorRemove,
		});

	return {
		mutateModeratorRm: mutate,
		isPendingModeratorRm: isPending,
		isSuccessModeratorRm: isSuccess,
		isErrorModeratorRm: isError,
		errorModeratorRm: error,
		resetModeratorRm: reset,
		dataModeratorRm: data,
	};
};
