import { authInstance } from 'shared/api';

type UploadAvatar = {
	file: File;
	profileId: string;
};

export const PhotoApi = {
	async get({
		profileId,
		pageParam = 0,
		moderate,
	}: {
		profileId: string;
		pageParam: number;
		moderate: boolean;
	}) {
		return authInstance
			.get(`/avatar/photo`, {
				params: { profileId, limit: 6, cursor: pageParam, moderate },
			})
			.then(response => {
				return response.data;
			});
	},
	async uploadPhoto({ file, profileId }: UploadAvatar) {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('profileId', profileId);

		return authInstance
			.post('/avatar/photo', formData, {
				headers: {
					'Content-type': 'multipart/form-data',
				},
			})
			.then(({ data }: any) => {
				return data.url;
			});
	},
	async delete({ profileId, photoId }: { profileId: string; photoId: string }) {
		return authInstance
			.delete('/avatar/photo', {
				data: { profileId, photoId },
			})
			.then(response => {
				return response.data;
			});
	},
	async moderation({
		profileId,
		photoId,
	}: {
		profileId: string;
		photoId: string;
	}) {
		return authInstance
			.put('/avatar/photo', { profileId, photoId })
			.then(response => {
				return response.data;
			});
	},
};
