import * as Yup from 'yup';

export const youtubeValidation = Yup.object().shape({
	url: Yup.string()
		.required('Поле не може бути порожнім')
		.matches(
			/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
			'Некоректний URL YouTube'
		),
});
