import {
	ProfileOwnerType,
	RoleOwnerType,
	useProfileModeratorRm,
} from 'entities/profile';
import { memo, useEffect } from 'react';

type ModeratorItemProps = {
	profileId: string;
	owner: ProfileOwnerType;
	role?: string;
	refetchProfile: () => void;
};

export const ModeratorItem = memo(
	({ owner, role, profileId, refetchProfile }: ModeratorItemProps) => {
		const { mutateModeratorRm, isPendingModeratorRm, dataModeratorRm } =
			useProfileModeratorRm();

		useEffect(() => {
			if (!isPendingModeratorRm && dataModeratorRm) {
				refetchProfile();
			}
		}, [isPendingModeratorRm, dataModeratorRm]);

		const onRemove = () => {
			mutateModeratorRm({ email: owner.email, profileId });
		};

		return (
			<li className='pb-3 pt-3'>
				<div className='flex items-center space-x-4 rtl:space-x-reverse'>
					<div className='flex-1 min-w-0'>
						<p className='text-sm text-slate-300 truncate'>{owner.email}</p>
						<p className='text-sm font-medium text-slate-500 truncate'>
							{owner.role === RoleOwnerType.OWNER ? 'Власник' : 'Модератор'}
						</p>
					</div>
					<div>
						{owner.role !== RoleOwnerType.OWNER &&
							role === RoleOwnerType.OWNER && (
								<button
									type='submit'
									disabled={isPendingModeratorRm}
									onClick={onRemove}
									className='text-white bg-red-500 rounded-lg text-sm p-2'
								>
									<svg
										className='w-[20px] h-[20px]'
										fill='currentColor'
										viewBox='0 0 22 32'
										version='1.1'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path d='M11.188 4.781c6.188 0 11.219 5.031 11.219 11.219s-5.031 11.188-11.219 11.188-11.188-5-11.188-11.188 5-11.219 11.188-11.219zM11.25 17.625l3.563 3.594c0.438 0.438 1.156 0.438 1.594 0 0.406-0.406 0.406-1.125 0-1.563l-3.563-3.594 3.563-3.594c0.406-0.438 0.406-1.156 0-1.563-0.438-0.438-1.156-0.438-1.594 0l-3.563 3.594-3.563-3.594c-0.438-0.438-1.156-0.438-1.594 0-0.406 0.406-0.406 1.125 0 1.563l3.563 3.594-3.563 3.594c-0.406 0.438-0.406 1.156 0 1.563 0.438 0.438 1.156 0.438 1.594 0z'></path>
									</svg>
								</button>
							)}
					</div>
				</div>
			</li>
		);
	}
);
