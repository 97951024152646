import { useIsAuth } from 'entities/auth';
import {
	useFetchProfile,
	useIsAccess,
	useProfileUpdate,
} from 'entities/profile';
import { UserProvider } from 'entities/user';
import { VideoItemTypes, useVideo } from 'entities/video';
import { VideoModal, useVideoModal } from 'pages/profile/video/ui/VideoModal';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import Plus from 'shared/assets/svgs/Plus';
import { AppRouter } from 'shared/config/router';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Indicator } from 'shared/ui/Indicator';
import { Provider } from 'shared/ui/Provider';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { VideoItem } from 'widgets/VideoItem';

export const Video = () => {
	useIsAuth(false, AppRouter.AUTH);

	const { profileId } = useParams();

	const [checkEnabled, setCheckEnabled] = useState<boolean>(false);
	const [checkModeration, setCheckModeration] = useState<boolean>(false);
	const [checkAuth, setCheckAuth] = useState<boolean>(false);

	const {
		isPending,
		isLoading,
		isError,
		data: dataProfile,
		refetch,
		error,
	} = useFetchProfile(profileId ?? '');
	const { mutateUpd } = useProfileUpdate();
	const { onOpen } = useVideoModal();

	useIsAccess(error, dataProfile?.owners);

	const {
		data,
		isPending: isPendingVideo,
		fetchNextPage,
		isError: isErrorVideo,
		isLoading: isLoadingVideo,
		hasNextPage,
		refetch: refetchVideo,
	} = useVideo(profileId as string, false);

	useEffect(() => {
		if (!isPending && !isError) {
			setCheckEnabled(dataProfile._videoSwitch);
			setCheckModeration(dataProfile._videoModerate);
			setCheckAuth(dataProfile._videoAuth);
		}
	}, [isPending, isError]);

	const onCheckEnabled = () => {
		setCheckEnabled(prev => !prev);
		mutateUpd({ profileId: profileId, _videoSwitch: !checkEnabled });
	};

	const onModerationEnabled = () => {
		setCheckModeration(prev => !prev);
		mutateUpd({ profileId: profileId, _videoModerate: !checkModeration });
	};

	const onAuthEnabled = () => {
		setCheckAuth(prev => !prev);
		mutateUpd({ profileId: profileId, _videoAuth: !checkAuth });
	};

	const items: VideoItemTypes[] = data?.pages?.flatMap(page => page.data) ?? [];

	const onOpenModal = () => {
		onOpen(dataProfile._videoModerate, dataProfile._videoAuth, refetchVideo);
	};

	return (
		<Provider>
			<UserProvider>
				<Indicator isShow={isLoading || isLoadingVideo} />
				<VideoModal />

				<div className='px-5'>
					<div
						className={
							'flex justify-between items-center border-slate-600 border-b pb-2 mt-5 mb-5'
						}
					>
						<h2 className='text-1xl font-extrabold text-white'>
							Відео пам'яті
						</h2>
						{!isLoading && (
							<div>
								<Button onClick={onOpenModal} className=' text-white'>
									<Plus />
								</Button>
							</div>
						)}
					</div>
					{isError && !isLoading && (
						<div className={'mt-5'}>
							<ErrorLoad
								onRefresh={() => {
									refetch();
									refetchVideo();
								}}
							/>
						</div>
					)}

					{!isError && !isLoading && (
						<>
							<div className={'flex flex-col gap-5'}>
								<Checkbox
									disabled={isPending}
									name={'_videoSwitch'}
									onChange={onCheckEnabled}
									checked={checkEnabled}
									title={'Публікація відеозаписів'}
									text={
										'Ви можете вимкнути або увімкнути публікацію відеозаписів.'
									}
								/>
								<Checkbox
									disabled={isPending}
									name={'_videoAuth'}
									onChange={onAuthEnabled}
									checked={checkAuth}
									title={'Безпечні відеозаписи'}
									text={'Відеозаписи можуть завантажувати лише авторизовані.'}
								/>
								<Checkbox
									disabled={isPending}
									name={'_videoModerate'}
									onChange={onModerationEnabled}
									checked={checkModeration}
									title={'Модерація відеозаписів'}
									text={'Відеозаписи будуть опубліковані після модерації.'}
								/>
							</div>
							<div className={'flex flex-col mt-3'}>
								<h2 className='text-1xl font-extrabold border-slate-600 border-b pb-2 mt-5 mb-5 text-white'>
									Список відеозаписів
								</h2>
							</div>
							<div className={'flex flex-col mt-3'}>
								{isPendingVideo && (
									<h4 className={'text-center mt-3 text-white'}>
										Завантаження...
									</h4>
								)}

								{!isPendingVideo && !items.length && (
									<h4 className={'text-center mt-3 text-slate-400'}>
										Немає жодного відео...
									</h4>
								)}

								{!isPendingVideo && !isErrorVideo && items.length > 0 && (
									<InfiniteScroll
										dataLength={items.length}
										next={fetchNextPage}
										hasMore={!!hasNextPage}
										loader={
											<h4 className={'text-center mt-3 text-white'}>
												Завантаження...
											</h4>
										}
									>
										<div className={'flex flex-wrap gap-2'}>
											{items.map(item => (
												<VideoItem
													key={item._id}
													{...item}
													edited={true}
													profileId={profileId as string}
												/>
											))}
										</div>
									</InfiniteScroll>
								)}
							</div>
						</>
					)}
				</div>
			</UserProvider>
		</Provider>
	);
};
