import React from 'react';

function Ol() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
			<path
				fill='currentColor'
				d='M4 0h12v4H4V0zM4 6h12v4H4V6zM4 12h12v4H4v-4zM1 0L.1.5l.2.7.7-.3V4h1V0zM2.2 13.9c.3-.2.5-.5.5-.8 0-.5-.4-1-1.3-1-.5 0-1 .1-1.2.3H.1l.2.8.1-.1c.1-.1.4-.2.7-.2s.4.1.4.3c0 .4-.5.4-.6.4H.5v.7h.4c.3 0 .6.1.6.4 0 .2-.2.4-.6.4s-.7-.2-.8-.2l-.1-.1v.9h.1c.2.2.6.3 1.1.3 1 0 1.6-.5 1.6-1.2 0-.4-.2-.8-.6-.9zM.1 6.4l.3 1s.7-.6 1.2-.3C2.7 7.9 0 9.5 0 9.5v.5h3V9H1.8c.6-.5 1.2-1.2 1-1.9C2.3 5.2.1 6.4.1 6.4z'
			></path>
		</svg>
	);
}

export default React.memo(Ol);
