import { GeoCoords } from 'entities/profile';
import { memo, useEffect, useState } from 'react';
import { AppRouter } from 'shared/config/router';
import useGeolocationDistance from 'shared/hooks/useGeolocationDistance';
import { Button } from 'shared/ui/Button';
import { LinkCustom } from 'shared/ui/Link';
import { saveGeoAccess } from 'shared/utils/storage/saveGeoAccess';
import { LocationError } from './LocationError';

type LocationLockProps = {
	profileId: string;
	_geoCoords: GeoCoords;
	setIsGeo: (value: boolean) => void;
};

export const LocationLock = memo(
	({ _geoCoords, profileId, setIsGeo }: LocationLockProps) => {
		const [longDistance, setLongDistance] = useState(false);

		const {
			getCoordsAndDistance,
			isGeolocationAvailable,
			distance,
			errorMessage,
			isLoading,
		} = useGeolocationDistance();

		useEffect(() => {
			if (distance !== null) {
				if (distance < 1000) {
					saveGeoAccess(profileId);
					setIsGeo(true);
				} else {
					setLongDistance(true);
				}
			}
		}, [distance]);

		useEffect(() => {
			if (isGeolocationAvailable === false) {
				saveGeoAccess(profileId);
			}
		}, [isGeolocationAvailable]);

		const onDistance = () => {
			getCoordsAndDistance(_geoCoords);
		};

		const onClickHome = () => {
			window.location.href = '/';
		};

		return (
			<div
				className={
					'flex z-[999] absolute top-0 left-0 w-full h-full items-start justify-center bg-black/40 backdrop-blur-md'
				}
			>
				<div className='max-w-sm sticky top-16 mt-16 p-6 bg-slate-700 border border-slate-500 rounded-lg shadow'>
					<a href='#'>
						<h5 className='mb-2 text-2xl font-bold tracking-tight text-slate-300'>
							Доступ за допомогою геопозиції
						</h5>
					</a>
					{!longDistance ? (
						<p className='mb-3 font-normal text-slate-400'>
							Щоб переглянути профіль, ви повинні надати доступ до вашої
							геопозиції. Якщо ви знаходитесь занадто далеко від QR-коду, ви не
							зможете відкрити профіль.
						</p>
					) : (
						<p className='mb-3 font-normal text-red-400'>
							На жаль, ви занадто далеко від QR-коду, тому ви не можете відкрити
							профіль та переглянути його.
						</p>
					)}
					{errorMessage && <LocationError />}
					{!longDistance && (
						<Button isLoading={isLoading} onClick={onDistance} long>
							Надати доступ
							<svg
								className='rtl:rotate-180 w-3.5 h-3.5 ms-2'
								aria-hidden='true'
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 14 10'
							>
								<path
									stroke='currentColor'
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M1 5h12m0 0L9 1m4 4L9 9'
								/>
							</svg>
						</Button>
					)}
					<div className='border-slate-500 border-t mt-5 pt-5 flex justify-between'>
						{' '}
						<a
							href='/'
							onClick={onClickHome}
							type='button'
							className={`text-indigo-200 rounded-lg text-sm text-center`}
						>
							Замовити
						</a>
						<LinkCustom to={AppRouter.DASHBOARD}>Кабінет</LinkCustom>
					</div>
				</div>
			</div>
		);
	}
);
