import imageCompression from 'browser-image-compression';
import 'cropperjs/dist/cropper.css';
import { REPLICATE_STATUS, useAvatarUpdate } from 'entities/file';
import { ChangeEvent, memo, useEffect, useRef, useState } from 'react';
import { imageOptions } from 'shared/config/image';
import { SIZE_IMAGE_PROFILE } from 'shared/const/size';
import { Accordion } from 'shared/ui/Accordion';
import { Button } from 'shared/ui/Button';
import { SkeletonImg } from 'shared/ui/Skeleton/SkeletonImg';
import { getMedia } from 'shared/utils/getMedia';
import { ReplicateStatus } from 'widgets/ReplicateStatus';
import { useCropModal } from '../../model/hooks/useCropModal';

interface PhotoEditProps {
	profileId: string;
	isAvatar: boolean;
	replicateStatus: REPLICATE_STATUS;
	refetchProfile: () => void;
}

export const PhotoEdit = memo(
	({
		profileId,
		isAvatar,
		replicateStatus,
		refetchProfile,
	}: PhotoEditProps) => {
		const url = getMedia('avatars', profileId);

		const [image, setImage] = useState<string | undefined>();
		const [isLoadingImage, setIsLoadingImage] = useState(true);

		// const { data: profile, isLoading: isProfileLoading } = useProfile();

		const { onOpen, onSetImage, setMutateAvatar } = useCropModal();
		const { mutateAvatar, isSuccessAvatar, dataAvatar } = useAvatarUpdate();

		const fileInputRef = useRef<HTMLInputElement>(null);
		const fileCropRef = useRef<HTMLInputElement>(null);

		// Первоначальная загрузка картинки
		useEffect(() => {
			setIsLoadingImage(false);
			setImage(isAvatar ? url : undefined);
		}, [isAvatar]);

		// Обновление фотографии
		useEffect(() => {
			let timeoutId: ReturnType<typeof setTimeout>;

			if (isSuccessAvatar && dataAvatar) {
				setImage(undefined);
				timeoutId = setTimeout(() => {
					setImage(dataAvatar);
					setIsLoadingImage(false);
					refetchProfile();
				}, 300);
			}

			return () => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
			};
		}, [isSuccessAvatar, dataAvatar]);

		// Функция для обновления фотографии через кроп
		useEffect(() => {
			const callback = (image: File) => {
				setIsLoadingImage(true);
				mutateAvatar({ file: image, profileId });
			};

			setMutateAvatar(callback);
		}, [setMutateAvatar, profileId]);

		const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
			setIsLoadingImage(true);
			const file = event.target.files;
			if (file && file.length > 0) {
				const resizedFile = await imageCompression(file[0], imageOptions);
				mutateAvatar({ file: resizedFile, profileId });
			}

			event.target.value = '';
		};

		const handleFileCropChange = async (
			event: ChangeEvent<HTMLInputElement>
		) => {
			const file = event.target.files;
			if (file && file.length > 0) {
				try {
					const resizedFile = await imageCompression(file[0], imageOptions);
					onSetImage(resizedFile);
					onOpen();
				} catch (error) {}
			}

			event.target.value = '';
		};

		return (
			<Accordion white title={'Світлина'} status={isAvatar}>
				<ReplicateStatus replicateStatus={replicateStatus} />

				<div style={{ height: `${SIZE_IMAGE_PROFILE}px` }}>
					{isLoadingImage ? (
						<SkeletonImg isAnimation={isAvatar} />
					) : (
						<>
							{isAvatar ? (
								<img
									src={image + `?&prefix=${new Date().getTime()}`}
									style={{ height: `${SIZE_IMAGE_PROFILE}px` }}
									className={`w-full object-cover rounded-lg`}
									alt='avatar'
								/>
							) : (
								<SkeletonImg isAnimation={false} />
							)}
						</>
					)}
				</div>
				<div className={'flex flex-col gap-3 mt-5'}>
					<Button onClick={() => fileCropRef?.current?.click()}>
						Просунуте завантаження
					</Button>
					<Button onClick={() => fileInputRef?.current?.click()}>
						Звичайне завантаження
					</Button>
					<input
						ref={fileCropRef}
						className='hidden'
						id='file'
						accept='image/*'
						type='file'
						onChange={handleFileCropChange}
					></input>
					<input
						ref={fileInputRef}
						className='hidden'
						id='file'
						accept='image/*'
						type='file'
						onChange={handleFileChange}
					></input>
				</div>
			</Accordion>
		);
	}
);
