import { usePreviousLocation } from 'app/providers/PreviousLocationProvider';
import { ReactNode, memo, useEffect } from 'react';

export const Provider = memo(({ children }: { children: ReactNode }) => {
	const { previousLocation } = usePreviousLocation();

	useEffect(() => {
		if (previousLocation && previousLocation.startsWith('/p/ar/')) {
			window.location.reload();
		}
	}, [previousLocation]);

	return (
		<div className='flex justify-center min-h-screen'>
			<div className='flex flex-col w-full min-h-screen relative sm:max-w-lg sm:mx-screen bg-slate-800'>
				{children}
			</div>
		</div>
	);
});
