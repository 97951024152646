import { ReactNode, memo, useEffect } from 'react';
import { Footer } from 'widgets/Footer';
import { BottomBar } from 'widgets/dashboard/BottomBar';
import { TopBar } from 'widgets/dashboard/TopBar';
import { useFetchUser } from './../model/hooks/useFetchUser/useFetchUser';
import { useUser } from './../model/services/useUser/useUser';

interface UserProvider {
	children: ReactNode;
	isBottomBar?: boolean;
	isActivated?: boolean;
}

export const UserProvider = memo(
	({ children, isBottomBar = true, isActivated = true }: UserProvider) => {
		const { mutate, isSuccess, data } = useFetchUser();
		const setUser = useUser(state => state.initialUser);

		useEffect(() => {
			mutate();
		}, []);

		useEffect(() => {
			if (isSuccess) {
				setUser(data._id, data.name, data.email);
			}
		}, [isSuccess]);

		return (
			<>
				<main className='flex-grow'>
					<TopBar />
					{children}
				</main>
				{isBottomBar && isActivated && (
					<>
						<Footer />
						<BottomBar />
					</>
				)}
				{!isActivated && (
					<>
						<Footer paddgin={false} />
					</>
				)}
			</>
		);
	}
);
