import { useIsAuth } from 'entities/auth';
import {
	useFetchProfile,
	useIsAccess,
	useProfileUpdate,
} from 'entities/profile';
import { StoryItemTypes, useStory } from 'entities/story';
import { UserProvider } from 'entities/user';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { Checkbox } from 'shared/ui/Checkbox';
import { Indicator } from 'shared/ui/Indicator';
import { Provider } from 'shared/ui/Provider';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { StoryItem } from 'widgets/StoryItem';

export const Message = () => {
	useIsAuth(false, AppRouter.AUTH);

	const { profileId } = useParams();

	const {
		isPending,
		isError,
		isLoading,
		refetch,
		error,
		data: dataProfile,
	} = useFetchProfile(profileId ?? '');
	const {
		data,
		isPending: isPendingStory,
		isLoading: isLoadingStory,
		fetchNextPage,
		isError: isErrorStory,
		hasNextPage,
		refetch: refetchStory,
	} = useStory(profileId as string, false);
	const { mutateUpd } = useProfileUpdate();

	useIsAccess(error, dataProfile?.owners);

	const [checkEnabled, setCheckEnabled] = useState<boolean>(false);
	const [checkModeration, setCheckModeration] = useState<boolean>(false);
	const [checkAuth, setCheckAuth] = useState<boolean>(false);

	useEffect(() => {
		if (!isPending && !isError) {
			setCheckEnabled(dataProfile._storySwitch);
			setCheckModeration(dataProfile._storyModerate);
			setCheckAuth(dataProfile._storyAuth);
		}
	}, [isPending, isError]);

	const onCheckEnabled = () => {
		setCheckEnabled(prev => !prev);
		mutateUpd({ profileId: profileId, _storySwitch: !checkEnabled });
	};

	const onModerationEnabled = () => {
		setCheckModeration(prev => !prev);
		mutateUpd({ profileId: profileId, _storyModerate: !checkModeration });
	};

	const onAuthEnabled = () => {
		setCheckAuth(prev => !prev);
		mutateUpd({ profileId: profileId, _storyAuth: !checkAuth });
	};

	const items: StoryItemTypes[] = data?.pages?.flatMap(page => page.data) ?? [];

	return (
		<Provider>
			<UserProvider>
				<Indicator isShow={isLoading || isLoadingStory} />

				<div className='px-5'>
					<h2 className='text-1xl font-extrabold border-slate-600 border-b pb-2 mt-5 mb-5 text-white'>
						Стіна пам’яті
					</h2>
					{isError && !isLoading && (
						<div className={'mt-5'}>
							<ErrorLoad
								onRefresh={() => {
									refetch();
									refetchStory();
								}}
							/>
						</div>
					)}

					{!isError && !isLoading && (
						<>
							<div className={'flex flex-col gap-5'}>
								<Checkbox
									disabled={isPending}
									name={'_storySwitch'}
									onChange={onCheckEnabled}
									checked={checkEnabled}
									title={'Публікація постів'}
									text={'Ви можете вимкнути або увімкнути публікацію постів.'}
								/>
								<Checkbox
									disabled={isPending}
									name={'_storyAuth'}
									onChange={onAuthEnabled}
									checked={checkAuth}
									title={'Безпечні пости'}
									text={'Пости можуть писати лише авторизовані.'}
								/>
								<Checkbox
									disabled={isPending}
									name={'_storyModerate'}
									onChange={onModerationEnabled}
									checked={checkModeration}
									title={'Модерація постів'}
									text={'Пости будуть опубліковані після вашої модерації.'}
								/>
							</div>
							<div className={'flex flex-col mt-3'}>
								<h2 className='text-1xl font-extrabold border-slate-600 border-b pb-2 mt-5 mb-5 text-white'>
									Список постів
								</h2>

								{isPendingStory && (
									<h4 className={'text-center mt-3 text-white'}>
										Завантаження...
									</h4>
								)}

								{!isPendingStory && !items.length && (
									<h4 className={'text-center mt-3 text-slate-400'}>
										Немає жодного посту...
									</h4>
								)}

								{!isPendingStory && !isErrorStory && items.length > 0 && (
									<InfiniteScroll
										dataLength={items.length}
										next={fetchNextPage}
										hasMore={!!hasNextPage}
										loader={
											<h4 className={'text-center mt-3 text-white'}>
												Завантаження...
											</h4>
										}
									>
										<div className={'flex flex-col gap-5'}>
											{items.map(item => (
												<StoryItem
													key={item._id}
													{...item}
													edited={true}
													profileId={profileId as string}
												/>
											))}
										</div>
									</InfiniteScroll>
								)}
							</div>
						</>
					)}
				</div>
			</UserProvider>
		</Provider>
	);
};
