import { memo } from 'react';
import { Link } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';

interface ListItemProps {
	profileID: number;
	fullName: string;
	short: string;
	_id: string;
}

export const ListItem = memo((props: ListItemProps) => {
	return (
		<div className='p-6 bg-slate-700 border border-slate-600 rounded-lg shadow'>
			<Link to={`${AppRouter.DASHBOARD}/${props._id}`}>
				<h5 className='mb-2 text-2xl font-bold tracking-tight text-slate-200'>
					{props.fullName.length ? props.fullName : 'Не вказано'}
				</h5>
			</Link>
			<p className='mb-3 font-normal text-slate-300'>
				{props.short.length ? props.short : 'Не вказано'}
			</p>
			<Link
				to={`${AppRouter.DASHBOARD}/${props._id}`}
				className='inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-indigo-500 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300'
			>
				Редагувати
				<svg
					className='rtl:rotate-180 w-3.5 h-3.5 ms-2'
					aria-hidden='true'
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 14 10'
				>
					<path
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
						d='M1 5h12m0 0L9 1m4 4L9 9'
					/>
				</svg>
			</Link>
		</div>
	);
});
