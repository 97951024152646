import { useMutation } from '@tanstack/react-query';
import { FileApi } from './../api/FileApi';

export const useAvatarUpdate = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: FileApi.uploadAvatar,
		});

	return {
		mutateAvatar: mutate,
		isPendingAvatar: isPending,
		isSuccessAvatar: isSuccess,
		isErrorAvatar: isError,
		errorAvatar: error,
		resetAvatar: reset,
		dataAvatar: data,
	};
};
