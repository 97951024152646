import { authInstance, instance } from 'shared/api';

export const ProfileApi = {
	async getById(profileId: string) {
		return instance
			.get('/profile/' + profileId)
			.then(response => response.data);
	},
	async activated(profileId: string) {
		return authInstance
			.post('/profile/activated', { profileId })
			.then(response => response.data);
	},
	async dashboard() {
		return authInstance
			.get('/profile/dashboard')
			.then(response => response.data);
	},
};
