import { useMutation } from '@tanstack/react-query';
import { VideoApi } from 'entities/video/api/VideoApi';

export const useVideoDelete = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: VideoApi.delete,
		});

	return {
		mutateDel: mutate,
		isPendingDel: isPending,
		isSuccessDel: isSuccess,
		isErrorDel: isError,
		errorDel: error,
		resetDel: reset,
		dataDel: data,
	};
};
