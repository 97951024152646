import React from 'react';

function Ul() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
			<g fill='currentColor'>
				<path d='M0 0h16v4H0V0z'></path>
				<path d='M0 6h16v4H0V6z'></path>
				<path d='M.06 12H16v4H.06v-4z'></path>
			</g>
		</svg>
	);
}

export default React.memo(Ul);
