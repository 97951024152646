import { instance } from 'shared/api';
import { AuthSocial, LoginAccessTokenType, LoginType } from '../types/auth';

export const AuthApi = {
	async login(args: LoginType) {
		return instance.post('/auth/login', args).then(response => response.data);
	},
	async loginAccessToken(args: LoginAccessTokenType) {
		return instance
			.post('/auth/login-access-token', args)
			.then(response => response.data);
	},
	async google(args: AuthSocial) {
		return instance.post('/auth/google', args).then(response => response.data);
	},
};
