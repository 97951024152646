import { useMutation } from '@tanstack/react-query';
import { StoryApi } from 'entities/story/model/api/StoryApi';

export const useCreateStory = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: StoryApi.create,
		});

	return {
		mutateStCreate: mutate,
		isPendingStCreate: isPending,
		isSuccessStCreate: isSuccess,
		isErrorStCreate: isError,
		errorStCreate: error,
		resetStCreate: reset,
		dataStCreate: data,
	};
};
