import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type AuthProps = {
	_id?: string;
	name?: string;
	email?: string;
};

type Action = {
	initialUser: (
		_id: AuthProps['_id'],
		name: AuthProps['name'],
		email: AuthProps['email']
	) => void;
};

export const useUser = create<AuthProps & Action>()(
	immer(set => ({
		_id: undefined,
		name: undefined,
		email: undefined,
		initialUser: (_id, name, email) =>
			set(state => {
				state._id = _id;
				state.name = name;
				state.email = email;
			}),
	}))
);
