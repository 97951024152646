import { useIsAuth } from 'entities/auth';
import {
	useFetchProfile,
	useIsAccess,
	useProfileUpdate,
} from 'entities/profile';
import { UserProvider } from 'entities/user';
import 'quill/dist/quill.snow.css';
import { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { Button } from 'shared/ui/Button';
import { Indicator } from 'shared/ui/Indicator';
import { Provider } from 'shared/ui/Provider';
import { saveGeoAccess } from 'shared/utils/storage/saveGeoAccess';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { AddProfile } from 'widgets/dashboard/AddProfile/ui/AddProfile';
import { BlockEdit } from './BlockEdit/BlockEdit';
import { CropModal } from './CropModal/CropModal';
import { DateEdit } from './DateEdit/DateEdit';
import { InputEdit } from './InputEdit/InputEdit';
import { PhotoEdit } from './PhotoEdit/PhotoEdit';
import { PlaceEdit } from './PlaceEdit/PlaceEdit';

export const Profile = memo(() => {
	const { profileId } = useParams();
	useIsAuth(false, `${AppRouter.AUTH}/${profileId}`);

	const { isLoading, isError, data, refetch, error } = useFetchProfile(
		profileId ?? ''
	);

	useIsAccess(error, data?.owners);

	const { mutateUpd, isPendingUpd, isErrorUpd, isSuccessUpd } =
		useProfileUpdate();

	const [biography, setBiography] = useState('');
	const [privacyLife, setPrivacyLife] = useState('');
	const [work, setWork] = useState('');

	const onOpenLink = useCallback(() => {
		saveGeoAccess(profileId as string);
		const url = `${window.location.protocol}//${window.location.host}${
			'/p/' + data?._id
		}`;

		window.open(url, '_blank', 'noopener,noreferrer');
	}, [data?._id]);

	return (
		<Provider>
			<UserProvider isActivated={data?.activated}>
				<CropModal />
				<Indicator isShow={isLoading} />

				<div className='px-5'>
					{isError && !isLoading && (
						<div className={'mt-5'}>
							<ErrorLoad onRefresh={refetch} />
						</div>
					)}

					{!isError && !isLoading && (
						<>
							{!data.activated && (
								<AddProfile
									profileId={profileId ?? ''}
									refetchProfile={refetch}
								/>
							)}

							{data.activated && (
								<>
									<div className='flex flex-row justify-between text-1xl font-extrabold border-slate-600 border-b pb-2 mt-5 mb-2 text-white'>
										<div className={''}>
											<div className={'text-white'}>
												Профіль <span>#{data.profileID}</span>
											</div>
											<div
												className={'flex text-slate-400 text-sm font-normal'}
											>
												<div className={'flex items-center'}>
													<svg
														className='w-4 h-4 me-1'
														viewBox='0 0 24 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
														<path
															d='M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z'
															stroke='currentColor'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
													</svg>
													<span>{data?.views}</span>
												</div>
												<svg viewBox='0 0 2 2' className={'w-1 mx-3'}>
													<circle
														fill={'currentColor'}
														cx='1'
														cy='1'
														r='1'
													></circle>
												</svg>
												<div className={'flex items-center'}>
													<svg
														className='w-4 h-4 me-1'
														fill='currentColor'
														viewBox='0 0 24 24'
													>
														<path d='M0 0h24v24H0z' fill='none' />
														<path d='M12 22c4.97 0 9-4.03 9-9-4.97 0-9 4.03-9 9zM5.6 10.25c0 1.38 1.12 2.5 2.5 2.5.53 0 1.01-.16 1.42-.44l-.02.19c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5l-.02-.19c.4.28.89.44 1.42.44 1.38 0 2.5-1.12 2.5-2.5 0-1-.59-1.85-1.43-2.25.84-.4 1.43-1.25 1.43-2.25 0-1.38-1.12-2.5-2.5-2.5-.53 0-1.01.16-1.42.44l.02-.19C14.5 2.12 13.38 1 12 1S9.5 2.12 9.5 3.5l.02.19c-.4-.28-.89-.44-1.42-.44-1.38 0-2.5 1.12-2.5 2.5 0 1 .59 1.85 1.43 2.25-.84.4-1.43 1.25-1.43 2.25zM12 5.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5S9.5 9.38 9.5 8s1.12-2.5 2.5-2.5zM3 13c0 4.97 4.03 9 9 9 0-4.97-4.03-9-9-9z' />
													</svg>
													<span>{data?.likes}</span>
												</div>
											</div>
										</div>
										<div>
											<Button long onClick={onOpenLink}>
												Перегляд
											</Button>
										</div>
									</div>
									<PhotoEdit
										isAvatar={data.isAvatar}
										replicateStatus={data.replicateStatus}
										profileId={profileId ?? ''}
										refetchProfile={refetch}
									/>
									<InputEdit
										profileId={profileId as string}
										title={"Прізвище ім'я та по батькові"}
										refetchProfile={refetch}
										initText={data?.fullName}
										isError={isErrorUpd}
										isSuccess={isSuccessUpd}
										isPending={isPendingUpd}
										keyName={'fullName'}
										mutate={mutateUpd}
										exampleText='Кузьменко Андрiй Вiкторович'
									/>
									<InputEdit
										profileId={profileId as string}
										title={'Коротко'}
										refetchProfile={refetch}
										initText={data?.short}
										isError={isErrorUpd}
										isSuccess={isSuccessUpd}
										isPending={isPendingUpd}
										keyName={'short'}
										exampleText='Прекрасна, світла, яскрава особистість, любляча мама.'
										mutate={mutateUpd}
									/>
									<DateEdit
										profileId={profileId as string}
										title={'Дата народження та смерті'}
										initDateBirth={data?.birthDay}
										initDateDeath={data?.deathDay}
										isError={isErrorUpd}
										isSuccess={isSuccessUpd}
										isPending={isPendingUpd}
										mutate={mutateUpd}
									/>
									<PlaceEdit
										profileId={profileId as string}
										title={'Місце поховання'}
										burialPlace={data?.burialPlace}
										isError={isErrorUpd}
										isSuccess={isSuccessUpd}
										isPending={isPendingUpd}
										mutate={mutateUpd}
									/>
									<BlockEdit
										title={'Життєпис'}
										initText={data?.biography}
										setText={setBiography}
										isError={isErrorUpd}
										isSuccess={isSuccessUpd}
										isPending={isPendingUpd}
										exampleText={
											'Родился в Самборе, обучался стоматологии по просьбе родителей, но его настоящая страсть была музыка. Стал известен благодаря участию в популярной музыкальной группе, где его карьера получила толчок после разногласий с коллегой.....'
										}
										onClick={() => {
											mutateUpd({ profileId, biography });
										}}
									/>
									<BlockEdit
										title={'Особисте життя'}
										initText={data?.privacyLife}
										setText={setPrivacyLife}
										isError={isErrorUpd}
										isSuccess={isSuccessUpd}
										isPending={isPendingUpd}
										exampleText={
											'Був одружений з художницею, майстром розпису по дереву. Вона закінчила педагогічний інститут і заснувала благодійний фонд, активно допомагає дитячим будинкам і лікарням, підтримує військових.....'
										}
										onClick={() => {
											mutateUpd({ profileId, privacyLife });
										}}
									/>
									<BlockEdit
										title={'Работа'}
										initText={data?.work}
										setText={setWork}
										isError={isErrorUpd}
										isSuccess={isSuccessUpd}
										isPending={isPendingUpd}
										exampleText={
											'Вимушено виступав на підтримку кандидата в президенти, але згодом зайняв активну політичну позицію. Допомагав бійцям АТО фінансово і відвідував їх у лікарнях, висловлюючи критику владі. Записав кілька відеозвернень, де засуджував стан українських доріг та інші проблеми...'
										}
										onClick={() => {
											mutateUpd({ profileId, work });
										}}
									/>
								</>
							)}
						</>
					)}
				</div>
			</UserProvider>
		</Provider>
	);
});
