import React, { memo, useEffect, useState } from 'react';
import { isDateValid } from 'shared/utils/isDateValid';

type Option = {
	value: string | number;
	label: string;
};

const generateYearOptions = (startYear: number, endYear: number): Option[] => {
	const options: Option[] = [];
	for (let year = startYear; year <= endYear; year++) {
		options.push({ value: year, label: year.toString() });
	}
	return options;
};

const generateMonthOptions = (): Option[] => {
	const months = [
		'Січень',
		'Лютий',
		'Березень',
		'Квітень',
		'Травень',
		'Червень',
		'Липень',
		'Серпень',
		'Вересень',
		'Жовтень',
		'Листопад',
		'Грудень',
	];
	return months.map((month, index) => ({
		value: index + 1,
		label: month,
	}));
};

const generateDayOptions = (daysInMonth: number): Option[] => {
	const options: Option[] = [];
	for (let day = 1; day <= daysInMonth; day++) {
		options.push({ value: day, label: day.toString() });
	}
	return options;
};

type CustomDateInputProps = {
	title: string;
	setDate: (value: Date) => void;
	init: Date;
};

export const CustomDateInput = memo(
	({ title, setDate, init }: CustomDateInputProps) => {
		const gdate = new Date(init);

		const cDay = gdate.getDate();
		const cMonth = gdate.getMonth();
		const cYear = gdate.getFullYear();

		const [day, setDay] = useState<string>(cDay.toString());
		const [month, setMonth] = useState<string>(cMonth.toString());
		const [year, setYear] = useState<string>(cYear.toString());

		const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
			setDay(event.target.value);
		const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
			setMonth(event.target.value);
		const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
			setYear(event.target.value);

		const daysInMonth = new Date(
			parseInt(year, 10),
			parseInt(month, 10),
			0
		).getDate();

		useEffect(() => {
			const formattedDate = new Date(
				Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day))
			);

			if (isDateValid(formattedDate)) {
				setDate(formattedDate);
			}
		}, [day, month, year]);

		return (
			<div>
				<h3 className={'pb-3 text-slate-500'}>{title}</h3>
				<div className={'flex gap-5'}>
					<select
						className={
							'p-2 px-4 mb-6 text-sm border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500'
						}
						id='year'
						name='year'
						value={year}
						onChange={handleYearChange}
					>
						<option value=''>Рік</option>
						{generateYearOptions(1900, new Date().getFullYear()).map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>

					<select
						className={
							'p-2 px-4 mb-6 text-sm border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500'
						}
						id='month'
						name='month'
						value={month}
						onChange={handleMonthChange}
					>
						<option value=''>Місяць</option>
						{generateMonthOptions().map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>

					<select
						className={
							'p-2 px-4 mb-6 text-sm border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500'
						}
						id='day'
						name='day'
						value={day}
						onChange={handleDayChange}
					>
						<option value=''>День</option>
						{generateDayOptions(daysInMonth).map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			</div>
		);
	}
);
