import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from 'entities/auth';
import { ProfileProvider } from 'entities/profile/ui/ProfileProvider/ProfileProvider';
import { Auth } from 'pages/auth';
import { Lists } from 'pages/dashboard/lists';
import { Message } from 'pages/dashboard/message';
import { Photo } from 'pages/dashboard/photo';
import { Profile } from 'pages/dashboard/profile';
import { Setting } from 'pages/dashboard/setting';
import { Video } from 'pages/dashboard/video';
import { NotFound } from 'pages/not-found';
import { AR as ShowAR } from 'pages/profile/ar';
import { Message as ShowMessage } from 'pages/profile/message';
import { Photo as ShowPhoto } from 'pages/profile/photo';
import { Profile as ShowProfile } from 'pages/profile/profile';
import { Video as ShowVideo } from 'pages/profile/video';
import { Terms } from 'pages/terms';
import { memo, useEffect } from 'react';
import {
	BrowserRouter,
	Navigate,
	Outlet,
	Route,
	Routes,
} from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { PreviousLocationProvider } from './providers/PreviousLocationProvider';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

export const App = memo(() => {
	const initAuth = useAuth(state => state.initialAuth);

	useEffect(() => {
		initAuth();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID as string}>
				<BrowserRouter>
					<PreviousLocationProvider>
						<Routes>
							<Route
								element={
									<ProfileProvider>
										<Outlet />
									</ProfileProvider>
								}
							>
								<Route
									path={`${AppRouter.HOME}/:profileId`}
									element={<ShowProfile />}
								/>
								<Route
									path={`${AppRouter.MESSAGES}/:profileId`}
									element={<ShowMessage />}
								/>
								<Route
									path={`${AppRouter.PHOTO}/:profileId`}
									element={<ShowPhoto />}
								/>
								<Route
									path={`${AppRouter.VIDEO}/:profileId`}
									element={<ShowVideo />}
								/>
								<Route
									path={`${AppRouter.AR}/:profileId`}
									element={<ShowAR />}
								/>
							</Route>
							<Route path={AppRouter.AUTH} element={<Auth />} />
							<Route path={`${AppRouter.AUTH}/:profileId`} element={<Auth />} />
							<Route
								path={`${AppRouter.DASHBOARD}/:profileId`}
								element={<Profile />}
							/>
							<Route path={AppRouter.DASHBOARD} element={<Lists />} />
							<Route
								path={`${AppRouter.DASHBOARD_MESSAGES}/:profileId`}
								element={<Message />}
							/>
							<Route
								path={`${AppRouter.DASHBOARD_SETTING}/:profileId`}
								element={<Setting />}
							/>
							<Route
								path={`${AppRouter.DASHBOARD_PHOTO}/:profileId`}
								element={<Photo />}
							/>
							<Route path={`${AppRouter.TERMS}`} element={<Terms />} />
							<Route
								path={`${AppRouter.DASHBOARD_VIDEO}/:profileId`}
								element={<Video />}
							/>
							<Route path={AppRouter.NOT_FOUND} element={<NotFound />} />
							<Route path='*' element={<Navigate to='/not_found' />} />
						</Routes>
					</PreviousLocationProvider>
				</BrowserRouter>
			</GoogleOAuthProvider>
		</QueryClientProvider>
	);
});
