import { authInstance } from 'shared/api';
import { ProfileSchema } from '../types/profile';

export const ProfileApi = {
	async update(data: any) {
		return authInstance.patch('/profile', data).then(response => response.data);
	},
	async like(profileId: string) {
		return authInstance
			.put('/profile/like', { profileId })
			.then(response => response.data);
	},
	async view(profileId: string) {
		return authInstance
			.put<ProfileSchema>('/profile/view', { profileId })
			.then(response => response.data);
	},
	async moderatorAdd(data: { profileId: string; email: string }) {
		return authInstance
			.put<ProfileSchema>('/profile/moderator', data)
			.then(response => response.data);
	},
	async moderatorRemove(data: { profileId: string; email: string }) {
		return authInstance
			.delete<ProfileSchema>('/profile/moderator', {
				data: data,
			})
			.then(response => response.data);
	},
};
