import {
	Editor,
	EditorState,
	RichUtils,
	convertFromRaw,
	convertToRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useRef, useState } from 'react';
import './DraftEditor.css';
import Toolbar from './Toolbar/Toolbar';

const DraftEditor = ({ initText = '', setText }) => {
	// Инициализация состояния редактора безопасно
	const initializeEditorState = () => {
		if (initText && initText.trim() !== '') {
			try {
				const rawContent = JSON.parse(initText);
				const contentState = convertFromRaw(rawContent);
				return EditorState.createWithContent(contentState);
			} catch (error) {}
		}
		return EditorState.createEmpty();
	};

	const [editorState, setEditorState] = useState(initializeEditorState);
	const editor = useRef(null);

	const handleKeyCommand = command => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			setEditorState(newState);
			return true;
		}
		return false;
	};

	// FOR BLOCK LEVEL STYLES(Returns CSS Class From DraftEditor.css)
	const myBlockStyleFn = contentBlock => {
		const type = contentBlock.getType();
		switch (type) {
			case 'blockQuote':
				return 'superFancyBlockquote';
			case 'leftAlign':
				return 'leftAlign';
			case 'rightAlign':
				return 'rightAlign';
			case 'centerAlign':
				return 'centerAlign';
			case 'justifyAlign':
				return 'justifyAlign';
			default:
				break;
		}
	};

	return (
		<div className='editor-wrapper'>
			<Toolbar editorState={editorState} setEditorState={setEditorState} />
			<div className='editor-container'>
				<Editor
					ref={editor}
					placeholder='Напишіть тут'
					handleKeyCommand={handleKeyCommand}
					editorState={editorState}
					blockStyleFn={myBlockStyleFn}
					onChange={newEditorState => {
						const contentState = newEditorState.getCurrentContent();
						const rawContent = convertToRaw(contentState);
						const jsonContent = JSON.stringify(rawContent);
						setEditorState(newEditorState);
						setText(jsonContent); // Отправка сериализованного состояния для сохранения в базе данных
					}}
				/>
			</div>
		</div>
	);
};

export default DraftEditor;
