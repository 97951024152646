import { useInfiniteQuery } from '@tanstack/react-query';
import { PhotoApi } from './../../api/PhotoApi';

export const usePhoto = (profileId: string, moderate: boolean) => {
	const {
		data,
		isError,
		fetchNextPage,
		refetch,
		hasNextPage,
		isFetching,
		isLoading,
		isPending,
		isFetchingNextPage,
		status,
		fetchStatus,
	} = useInfiniteQuery({
		queryKey: ['photo', profileId],
		queryFn: ({ pageParam }) =>
			PhotoApi.get({ profileId, pageParam, moderate }),
		initialPageParam: 0,
		retry: false,
		getNextPageParam: lastPage => lastPage.nextCursor || undefined,
		select: sel => ({
			pages: [...sel.pages],
			pageParams: [...sel.pageParams],
		}),
	});

	return {
		data,
		isError,
		isLoading,
		fetchNextPage,
		isPending,
		refetch,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		status,
		fetchStatus,
	};
};
