import { memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Tilt from 'react-parallax-tilt';
import Cube from 'shared/assets/svgs/Cube';
import { SkeletonImg } from 'shared/ui/Skeleton/SkeletonImg';
import { getMedia } from 'shared/utils/getMedia';
import { requstOrientation } from './../model/lib/requestOrientation';

interface AvatarProps {
	isLoading: boolean;
	isAvatar: boolean;
	avatarUpdatedAt: Date;
	fullName: string;
	short: string;
	birthday: string;
	deadday: string;
	profileId: string;
}

export const Avatar = memo(
	({
		fullName,
		isLoading,
		short,
		birthday,
		deadday,
		isAvatar,
		avatarUpdatedAt,
		profileId,
	}: AvatarProps) => {
		const [key, setKey] = useState(0);

		const initParallax = localStorage.getItem('is_parallax') === 'true';
		const initGyroscope = localStorage.getItem('is_yroscope') === 'true';

		const [isParallax, setIsParallax] = useState<boolean>(initParallax);
		const [isGyroscope, setIsGyroscope] = useState<boolean>(initGyroscope);

		const switchParallax = () => {
			requstOrientation(setIsParallax, setIsGyroscope);
			setKey(prevKey => prevKey + 1);
		};

		const image = getMedia('avatars', profileId);

		const url = window.location.origin + location.pathname + location.search;

		return (
			<div className={'relative'}>
				{!isLoading && (
					<Helmet>
						<title>{fullName ? fullName : 'Не вказано'} - dzvinok</title>
						<meta name='description' content={short} />

						<meta
							property='og:title'
							content={`${fullName ? fullName : 'Не вказано'} - dzvinok`}
						/>
						<meta property='og:description' content={short} />
						<meta property='og:image' content={image} />
						<meta property='og:url' content={url} />
						<meta property='og:type' content='website' />

						<meta name='twitter:card' content='summary_large_image' />
						<meta
							name='twitter:title'
							content={`${fullName ? fullName : 'Не вказано'} - dzvinok`}
						/>
						<meta name='twitter:description' content={short} />
						<meta name='twitter:image' content={image} />

						<link rel='canonical' href={url} />
					</Helmet>
				)}

				<div className={'absolute top-3 right-3 z-50'}>
					{isAvatar && (
						<button
							onClick={switchParallax}
							className={
								'text-wihite bg-white/20 p-1 rounded-md backdrop-blur-sm'
							}
							type={'button'}
						>
							<Cube
								color={isParallax ? '#93c5fd' : '#dbeafe'}
								width={25}
								height={25}
							/>
						</button>
					)}
				</div>

				<div className={'overflow-hidden w-full'}>
					<div className={'h-[350px]'}>
						{isAvatar && (
							<Tilt
								key={key}
								tiltEnable={isParallax}
								gyroscope={isParallax && isGyroscope}
								tiltMaxAngleX={isParallax ? 17 : 0}
								tiltMaxAngleY={isParallax ? 15 : 0}
								transitionSpeed={1500}
								scale={isParallax && isGyroscope ? 1.3 : 1}
							>
								<img
									alt='pic'
									className={'inner-element object-cover w-full h-[350px]'}
									src={image + `?&prefix=${avatarUpdatedAt}`}
								/>
							</Tilt>
						)}
						{!isAvatar && <SkeletonImg height={350} isAnimation={isAvatar} />}
					</div>
					<div
						className={
							'absolute w-full bg-gradient-to-t from-slate-800 bottom-0 pb-5 pt-[300px] px-5 text-center'
						}
					>
						<h3 className='flex justify-center font-semibold text-lg text-slate-100'>
							{isLoading ? (
								<div role='status' className='max-w-sm animate-pulse'>
									<div className='h-2.5 bg-slate-400 rounded-full w-48 mb-4' />
								</div>
							) : !fullName.length ? (
								"Ім'я прізвище по батькові"
							) : (
								fullName
							)}
						</h3>

						{isLoading ? (
							<div role='status' className='space-y-2.5 animate-pulse'>
								<div className='flex justify-center items-center w-full'>
									<div className='h-2.5 bg-slate-400 rounded-full w-14'></div>
									<div className='h-2.5 ms-3 bg-slate-400 rounded-full w-14'></div>
								</div>
							</div>
						) : (
							<p className={'text-slate-300'}>
								{birthday} - {deadday}
							</p>
						)}
					</div>
				</div>
			</div>
		);
	}
);
