import { memo } from 'react';

export const MessageSkeleton = memo(() => {
	return (
		<div
			role='status'
			className='p-4 border border-slate-500 rounded shadow animate-pulse md:p-6 w-full'
		>
			<div className='h-2.5 bg-slate-500 rounded-full w-48 mb-4'></div>
			<div className='h-2 bg-slate-500 rounded-full mb-2.5'></div>
			<div className='h-2 bg-slate-500 rounded-full mb-2.5'></div>
			<div className='h-2 bg-slate-500 rounded-full'></div>
			<div className='flex items-center mt-4'>
				<div>
					<div className='h-2.5 bg-slate-500 rounded-full w-32 mb-2'></div>
				</div>
			</div>
		</div>
	);
});
