import { useProfileUpdate } from 'entities/profile';
import { memo, useState } from 'react';
import { CatgoryItem } from 'shared/ui/CatgoryItem';
import { Checkbox } from 'shared/ui/Checkbox';

type ProfileSwithProps = {
	profileId: string;
	_profileSwitch: boolean;
};

export const ProfileSwith = memo(
	({ _profileSwitch, profileId }: ProfileSwithProps) => {
		const { mutateUpd, isPendingUpd } = useProfileUpdate();

		const [checkEnabled, setCheckEnabled] = useState<boolean>(_profileSwitch);

		const onCheckEnabled = () => {
			setCheckEnabled(prev => !prev);
			mutateUpd({ profileId: profileId, _profileSwitch: !checkEnabled });
		};

		return (
			<CatgoryItem title={'Налаштування'} subtitle=''>
				<div className={'flex flex-col mb-5'}>
					<Checkbox
						disabled={isPendingUpd}
						name={'_profileSwitch'}
						onChange={onCheckEnabled}
						checked={checkEnabled}
						title={'Доступ до профілю'}
						text={'Ніхто не може переглянути профіль.'}
					/>
				</div>
			</CatgoryItem>
		);
	}
);
