export enum AppRouter {
	HOME = '/p',
	MESSAGES = '/p/messages',
	PHOTO = '/p/photo',
	VIDEO = '/p/video',
	AR = '/p/ar',
	AUTH = '/auth',
	DASHBOARD = '/dashboard',
	DASHBOARD_MESSAGES = '/dashboard/messages',
	DASHBOARD_VIDEO = '/dashboard/video',
	DASHBOARD_PHOTO = '/dashboard/photo',
	DASHBOARD_SETTING = '/dashboard/setting',
	TERMS = '/terms-of-use',
	NOT_FOUND = '/not_found',
}
