import { useQuery } from '@tanstack/react-query';
import { ProfileApi } from '../../../../../pages/dashboard/profile/model/api/profileApi';

export const useFetchProfiles = (enabled: boolean = true) => {
	const { isPending, isSuccess, isError, isLoading, data, error, refetch } =
		useQuery({
			queryKey: ['dashboard'],
			queryFn: ProfileApi.dashboard,
			enabled: enabled,
			retry: false,
		});

	return { isPending, isError, isSuccess, isLoading, data, error, refetch };
};
