import { memo } from 'react';
import { AppRouter } from 'shared/config/router';
import { LinkCustom } from 'shared/ui/Link';

export const ProfileLock = memo(() => {
	const onClickHome = () => {
		window.location.href = '/';
	};

	return (
		<div
			className={
				'flex absolute z-[999] top-0 left-0 w-full h-full items-start justify-center bg-black/40 backdrop-blur-md'
			}
		>
			<div className='max-w-sm sticky top-16 mt-16 p-6 bg-slate-700 border border-slate-500 rounded-lg shadow'>
				<a href='#'>
					<h5 className='mb-2 text-2xl font-bold tracking-tight text-slate-300'>
						Доступ до облікового запису
					</h5>
				</a>
				<p className='mb-3 font-normal text-slate-400'>
					На жаль, доступ до профілю померлого відключено. Спробуйте пізніше,
					можливо, оновлюють інформацію про померлого.
				</p>
				<div className='border-slate-500 border-t mt-5 pt-5 flex justify-between'>
					<a
						href='/'
						onClick={onClickHome}
						type='button'
						className={`text-indigo-200 rounded-lg text-sm text-center`}
					>
						Замовити
					</a>
					<LinkCustom to={AppRouter.DASHBOARD}>Кабінет</LinkCustom>
				</div>
			</div>
		</div>
	);
});
