import { useProfile } from 'entities/profile';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArImg } from 'shared/assets/images';
import { Indicator } from 'shared/ui/Indicator';
import { getMedia } from 'shared/utils/getMedia';
import { loadScript } from 'shared/utils/loadScript';
// import './styles/index.css';

export const AR = () => {
	const { profileId } = useParams();

	const videoRef = useRef(null);
	const markerRef = useRef(null);

	const { data } = useProfile();
	const dataVideo = getMedia('videos', profileId);

	const [isLoadingScripts, setIsLoadingScripts] = useState(false);

	const [isShowPlayVideo, setIsShowPlayVideo] = useState(false);
	const [isPlayingVideo, setIsPlayingVideo] = useState(false);
	const [isMarkerFound, setIsMarkerFound] = useState(false);

	useEffect(() => {
		loadScript('/scripts/aframe.min.js', () => {
			loadScript('/scripts/aframe-ar.js', () => {
				setIsLoadingScripts(true);
			});
		});
	}, []);

	useEffect(() => {
		const markerEl = markerRef.current;

		if (markerEl) {
			const onMarkerFound = () => {
				setIsMarkerFound(true);

				videoRef.current.play().catch(er => {
					if (!isPlayingVideo) {
						setIsShowPlayVideo(true);
					}
				});
			};

			const onMarkerLost = () => {
				setIsMarkerFound(false);
				setIsShowPlayVideo(false);
			};

			markerEl.addEventListener('markerFound', onMarkerFound);
			markerEl.addEventListener('markerLost', onMarkerLost);

			return () => {
				markerEl.removeEventListener('markerFound', onMarkerFound);
				markerEl.removeEventListener('markerLost', onMarkerLost);
			};
		}
	}, [isLoadingScripts, isPlayingVideo]);

	useEffect(() => {
		// Действия при монтировании компонента
		document.documentElement.style.width = '100vw';
		document.documentElement.style.height = '100%';
		document.documentElement.style.overflow = 'hidden';

		document.body.style.margin = '0';
		document.body.style.overflow = 'hidden';

		// Очистка при размонтировании компонента
		return () => {
			document.documentElement.style.width = '';
			document.documentElement.style.height = '';
			document.documentElement.style.overflow = '';

			document.body.style.margin = '';
			document.body.style.overflow = '';
		};
	}, []);

	const playVideosHandler = useCallback(() => {
		setIsPlayingVideo(true);
		setIsShowPlayVideo(false);
		videoRef.current.play();
	}, []);

	const backNaviagtion = () => {
		window.location.href = `/p/${profileId}`;
	};

	if (!isLoadingScripts) {
		return <Indicator isShow={true} />;
	}

	return (
		<>
			{isLoadingScripts && (
				<>
					{data.replicateStatus !== 'success' && (
						<div
							className={`absolute z-50 top-5 left-1/2 transform -translate-x-1/2 bg-red-600/20 px-5 py-2 rounded-md backdrop-blur-sm text-white whitespace-nowrap font-semibold`}
						>
							AR модель не згенерована
						</div>
					)}
					{!isMarkerFound && data.replicateStatus === 'success' && (
						<div className='absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[60px] h-[60px] opacity-30'>
							<img src={ArImg} />
						</div>
					)}

					<div
						className={`flex ${
							isShowPlayVideo ? 'justify-between' : 'justify-end'
						} absolute gap-5 z-50 left-1/2 transform -translate-x-1/2 bottom-10 w-10/12`}
					>
						{isShowPlayVideo && (
							<button
								onClick={playVideosHandler}
								className=' text-white bg-green-500/20 p-3 rounded-md  backdrop-blur-sm whitespace-nowrap font-semibold flex-grow'
							>
								Ожвити
							</button>
						)}
						<button
							onClick={backNaviagtion}
							className=' text-white bg-blue-500/20 p-3 rounded-md  backdrop-blur-sm whitespace-nowrap font-semibold'
						>
							Назад
						</button>
					</div>
					<div>
						<a-scene
							vr-mode-ui='enabled: false'
							device-orientation-permission-ui='enabled: false'
							arjs='cameraParametersUrl: /data/camera_para.dat; debugUIEnabled: false; detectionMode: mono_and_matrix; matrixCodeType: 3x3; '
							// renderer='logarithmicDepthBuffer: true; antialias: true; alpha: true;'
							renderer='antialias: true; alpha: true; precision: medium;'
							loading-screen='dotsColor: #818cf8; backgroundColor: #1e293b'
						>
							<a-assets>
								{/* <img
									crossOrigin='anonymous'
									id='mirror'
									src='/data/mirror.png'
								/> */}
								<video
									crossOrigin='anonymous'
									id='penguin-sledding-video'
									ref={videoRef}
									preload={'true'}
									autoPlay
									loop
									muted
									playsInline
									webkit-playsinline={'true'}
									src={dataVideo}
									onError={() => {
										setIsShowPlayVideo(false);
									}}
								></video>
							</a-assets>

							<a-marker
								ref={markerRef}
								emitevents='true'
								type='barcode'
								value='6'
								smooth='true'
								// smoothCount='1'
								// smoothTolerance='0.01'
								// smoothThreshold='2'
							>
								<a-video
									src='#penguin-sledding-video'
									position='0 0.5 0'
									rotation='-90 90 0'
									width='16'
									height='9'
									material='shader: flat;'
									shadow='receive: false'
									geometry='primitive: plane; width: 16; height: 9'
									render-order='1'
								></a-video>
								{/* <a-image
									src='#mirror'
									position='0 1.2 0'
									rotation='-90 90 0'
									width='11.8'
									height='6.5'
									geometry='primitive: plane'
									render-order='0'
								></a-image> */}
							</a-marker>
							<a-entity camera></a-entity>
						</a-scene>
					</div>
				</>
			)}
		</>
	);
};
