import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import {
	ProfileOwnerType,
	RoleOwnerType,
	isAcceptOwner,
	useProfileModeratorAdd,
} from 'entities/profile';
import { useUser } from 'entities/user';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorResponse } from 'shared/types/errorResponse';
import { CatgoryItem } from 'shared/ui/CatgoryItem';
import { authValidation } from 'shared/validation/auth.validation';
import { ModeratorItem } from './ModeratorItem';

type ModeratorProps = {
	owners: ProfileOwnerType[];
	profileId: string;
	refetchProfile: () => void;
};

export const Moderator = memo(
	({ profileId, owners, refetchProfile }: ModeratorProps) => {
		const {
			mutateModeratorAdd,
			errorModeratorAdd,
			isPendingModeratorAdd,
			dataModeratorAdd,
		} = useProfileModeratorAdd();
		const _errorModeratorAdd = errorModeratorAdd as AxiosError<ErrorResponse>;
		const { _id } = useUser();

		const { control, handleSubmit, resetField } = useForm({
			defaultValues: {
				email: '',
			},
			resolver: yupResolver(authValidation),
		});

		useEffect(() => {
			if (dataModeratorAdd && !isPendingModeratorAdd) {
				resetField('email');
				refetchProfile();
			}
		}, [isPendingModeratorAdd]);

		const onSubmit = ({ email }: { email: string }) => {
			mutateModeratorAdd({ profileId, email });
		};

		const isPermision = isAcceptOwner(
			owners,
			_id as string,
			RoleOwnerType.OWNER
		);

		return (
			<CatgoryItem title={'Права доступу'} subtitle=''>
				<div className={'flex flex-col mb-5'}>
					<Controller
						control={control}
						render={({
							field: { onChange, onBlur },
							fieldState: { error },
						}) => (
							<>
								<div className='relative'>
									<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
										<svg
											className='w-4 h-4 text-slate-400'
											aria-hidden='true'
											xmlns='http://www.w3.org/2000/svg'
											fill='currentColor'
											viewBox='0 0 20 16'
										>
											<path d='m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z' />
											<path d='M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z' />
										</svg>
									</div>
									<input
										id='email'
										type='email'
										name='email'
										disabled={!isPermision?.role}
										onBlur={onBlur}
										onChange={onChange}
										className='block w-full p-4 ps-10 text-sm text-slate-200 border border-slate-400 rounded-lg bg-slate-600 focus:ring-blue-500 focus:border-blue-500'
										placeholder='name@gmail.com'
										required
									/>
									<button
										type='submit'
										disabled={isPendingModeratorAdd || !isPermision?.role}
										onClick={handleSubmit(onSubmit)}
										className='text-white absolute end-2.5 bottom-2.5 bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2'
									>
										Додати
									</button>
								</div>
								{error && (
									<div className='text-red-400 mt-1'>{error?.message}</div>
								)}
								{!error && errorModeratorAdd && (
									<div className='text-red-400 mt-1'>
										{_errorModeratorAdd?.response?.data?.message}
									</div>
								)}
							</>
						)}
						name={'email'}
					/>

					<ul className='divide-y divide-slate-600 mt-3'>
						{owners.map(el => (
							<ModeratorItem
								key={el.email}
								role={isPermision?.role}
								owner={el}
								refetchProfile={refetchProfile}
								profileId={profileId}
							/>
						))}
					</ul>
				</div>
			</CatgoryItem>
		);
	}
);
