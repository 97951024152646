import React from 'react';

function Post() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill={'currentColor'}
			version='1.1'
			viewBox='0 0 470 470'
			xmlSpace='preserve'
		>
			<path d='M220.716 354.75a7.5 7.5 0 00-10.507 1.456l-69.447 91.792-12.547-27.18a7.5 7.5 0 00-5.637-4.264l-29.566-4.682 82.214-108.666a7.5 7.5 0 10-11.963-9.05L73.502 412.798a7.5 7.5 0 004.808 11.933l37.965 6.012 16.11 34.9a7.499 7.499 0 0012.791 1.381l76.995-101.769a7.499 7.499 0 00-1.455-10.505zM396.498 412.799l-105.149-138.98 48.208-63.719c17.343-22.92 26.51-50.249 26.51-79.032C366.067 58.797 307.27 0 235 0c-72.271 0-131.068 58.797-131.068 131.067 0 28.785 9.167 56.113 26.508 79.03l194.383 256.927a7.501 7.501 0 0012.791-1.381l16.11-34.9 37.966-6.012a7.498 7.498 0 004.808-11.932zm-49.077 3.755a7.503 7.503 0 00-5.637 4.264l-12.547 27.18-186.836-246.951c-15.354-20.291-23.47-44.489-23.47-69.979C118.932 67.068 171 15 235 15s116.067 52.068 116.067 116.067c0 25.489-8.116 49.688-23.472 69.982l-45.65 60.339-37.541-49.62 35.442-46.847c7.421-9.807 11.344-21.514 11.344-33.854 0-30.984-25.207-56.191-56.19-56.191-30.984 0-56.191 25.207-56.191 56.191 0 12.34 3.922 24.046 11.341 33.852l38.78 51.258c.056.077.113.153.173.228l46.766 61.812c.061.084.123.167.188.248l100.932 133.407-29.568 4.682zM235 199.338l-32.889-43.47c-5.432-7.179-8.303-15.755-8.303-24.801 0-22.713 18.479-41.191 41.191-41.191s41.19 18.478 41.19 41.191c0 9.046-2.872 17.623-8.306 24.803L235 199.338z'></path>
		</svg>
	);
}

export default React.memo(Post);
