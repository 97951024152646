import React from 'react';

type IcontType = {
	width: string;
};

function Tiktok({ width }: IcontType) {
	return (
		<svg
			className={`${width}`}
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
		>
			<path
				fill='currentColor'
				d='M16.822 5.134A4.75 4.75 0 0115.648 2h-.919a4.763 4.763 0 002.093 3.134zM8.322 11.905a2.89 2.89 0 00-2.886 2.888c0 1.11.63 2.076 1.55 2.559a2.859 2.859 0 01-.548-1.686 2.89 2.89 0 012.886-2.888c.297 0 .585.05.854.134v-3.51a6.419 6.419 0 00-.854-.06c-.051 0-.097.004-.149.004v2.693a2.905 2.905 0 00-.853-.134z'
			></path>
			<path
				fill='currentColor'
				d='M19.424 6.676v2.67a8.172 8.172 0 01-4.778-1.537v6.989c0 3.487-2.835 6.329-6.324 6.329a6.296 6.296 0 01-3.624-1.147A6.312 6.312 0 009.324 22c3.484 0 6.324-2.837 6.324-6.33V8.683a8.172 8.172 0 004.779 1.537V6.783a4.7 4.7 0 01-1.003-.107z'
			></path>
			<path
				fill='currentColor'
				d='M14.646 14.798V7.809a8.172 8.172 0 004.778 1.537v-2.67a4.773 4.773 0 01-2.602-1.542A4.802 4.802 0 0114.725 2H12.21l-.005 13.777a2.89 2.89 0 01-2.881 2.782 2.898 2.898 0 01-2.343-1.203 2.899 2.899 0 01-1.55-2.558 2.89 2.89 0 012.886-2.889c.297 0 .585.051.854.135V9.351C5.756 9.425 3 12.23 3 15.67a6.31 6.31 0 001.698 4.309 6.296 6.296 0 003.624 1.147c3.484 0 6.324-2.842 6.324-6.33z'
			></path>
		</svg>
	);
}

export default React.memo(Tiktok);
