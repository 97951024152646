import { Button } from 'shared/ui/Button';

type ErrorLoadProps = {
	onRefresh: () => void;
};

export const ErrorLoad = ({ onRefresh }: ErrorLoadProps) => {
	return (
		<div className={'p-5 border border-slate-500 rounded-md'}>
			<div className='mb-3 text-white font-semibold'>
				Сталася помилка завантаження даних
			</div>
			<Button long onClick={() => onRefresh()}>
				Повторити запит
			</Button>
		</div>
	);
};
