import { create } from 'zustand';

interface PhotoModalStore {
	isOpen: boolean;
	isModerate: boolean;
	isAuth: boolean;
	refetch: () => void;
	onOpen: (isModerate: boolean, isAuth: boolean, refetch: () => void) => void;
	onClose: () => void;
}

export const useVideoModal = create<PhotoModalStore>(set => ({
	isOpen: false,
	isModerate: false,
	isAuth: false,
	refetch: () => {},
	onOpen: (isModerate, isAuth, refetch) =>
		set(state => ({
			isOpen: true,
			isModerate,
			isAuth,
			refetch,
		})),
	onClose: () => set({ isOpen: false }),
}));
