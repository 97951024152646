import { MutateOptions } from '@tanstack/react-query';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Accordion } from 'shared/ui/Accordion';
import { Button } from 'shared/ui/Button';
import { ExampleText } from 'shared/ui/ExampleText';
import { InputText } from 'shared/ui/InputText';
import { MiniIndicator } from 'shared/ui/MiniIndicator';

type InputEditProps = {
	profileId: string;
	title: string;
	initText: string;
	isError: boolean;
	isSuccess: boolean;
	isPending: boolean;
	keyName: string;
	exampleText: string;
	mutate: (
		variables: any,
		options?: MutateOptions<any, Error, any, unknown> | undefined
	) => void;
	refetchProfile: () => void;
	defaultOpen?: boolean;
};

export const InputEdit = memo(
	({
		profileId,
		title,
		initText,
		isError,
		isSuccess,
		isPending,
		keyName,
		exampleText,
		mutate,
		refetchProfile,
		defaultOpen = false,
	}: InputEditProps) => {
		const [isShowMsg, setIsShowMsg] = useState(false);

		const { control, handleSubmit } = useForm({
			defaultValues: {
				[keyName]: initText,
			},
		});

		useEffect(() => {
			let interval: number | undefined;

			if (isError || isSuccess) {
				interval = window.setTimeout(() => {
					setIsShowMsg(false);
				}, 2000);
				refetchProfile();
			} else {
				setIsShowMsg(true);
			}

			return () => clearTimeout(interval);
		}, [isError, isSuccess]);

		const submit = (formData: { [keyName: string]: string }) => {
			mutate({ profileId, [keyName]: formData[keyName] });
		};

		return (
			<Accordion
				white
				title={title}
				defaultOpen={defaultOpen}
				status={initText.length > 0}
			>
				<ExampleText text={exampleText} />
				<InputText
					control={control}
					name={keyName}
					type='text'
					placeholder=''
				></InputText>
				<div className={'flex justify-between items-center mt-3'}>
					<div>
						{isShowMsg && isError && (
							<div className={'text-red-400'}>Не вдалося зберегти</div>
						)}
						{isShowMsg && isSuccess && (
							<div className={'text-green-400'}>Успішне збереження</div>
						)}
						<MiniIndicator isShow={isPending} />
					</div>
					<Button long onClick={handleSubmit(submit)}>
						Зберегти
					</Button>
				</div>
			</Accordion>
		);
	}
);
