import { useMutation } from '@tanstack/react-query';
import { ProfileApi } from './../../api/ProfileApi';

export const useProfileUpdate = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: ProfileApi.update,
		});

	return {
		mutateUpd: mutate,
		isPendingUpd: isPending,
		isSuccessUpd: isSuccess,
		isErrorUpd: isError,
		errorUpd: error,
		resetUpd: reset,
		dataUpd: data,
	};
};
