import { useCallback, useState } from 'react';

interface Coordinates {
	latitude: number;
	longitude: number;
}

const useGeolocation = () => {
	const [coords, setCoords] = useState<Coordinates | null>(null);
	const [isGeolocationAvailable, setIsGeolocationAvailable] = useState<boolean>(
		!!navigator.geolocation
	);
	const [isGeolocationEnabled, setIsGeolocationEnabled] = useState<
		boolean | null
	>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const handleGeolocationError = (error: GeolocationPositionError) => {
		setIsLoading(false);
		setIsGeolocationEnabled(false);
		switch (error.code) {
			case error.PERMISSION_DENIED:
				setErrorMessage('User denied the request for Geolocation.');
				break;
			case error.POSITION_UNAVAILABLE:
				setErrorMessage('Location information is unavailable.');
				break;
			case error.TIMEOUT:
				setErrorMessage('The request to get user location timed out.');
				break;
			default:
				setErrorMessage('An unknown error occurred.');
				break;
		}
	};

	const requestGeolocationPermission = useCallback(() => {
		if (!navigator.geolocation) {
			setIsGeolocationAvailable(false);
			setErrorMessage('Geolocation is not supported by your browser.');
			return;
		}

		setIsLoading(true);
		navigator.geolocation.getCurrentPosition(() => {
			setIsLoading(false);
			setIsGeolocationEnabled(true);
			setErrorMessage(null);
		}, handleGeolocationError);
	}, []);

	const getCoords = useCallback(() => {
		if (!navigator.geolocation) {
			setIsGeolocationAvailable(false);
			setErrorMessage('Geolocation is not supported by your browser.');
			return;
		}

		setIsLoading(true);
		navigator.geolocation.getCurrentPosition(position => {
			setCoords({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			});
			setIsLoading(false);
			setIsGeolocationEnabled(true);
			setErrorMessage(null);
		}, handleGeolocationError);
	}, []);

	return {
		coords,
		isGeolocationAvailable,
		isGeolocationEnabled,
		isLoading,
		errorMessage,
		getCoords,
		requestGeolocationPermission,
	};
};

export default useGeolocation;
