import { ReactNode, memo } from 'react';
import { NavLink, useParams } from 'react-router-dom';

interface BottomItemProps {
	Icon: ReactNode;
	label: string;
	isActive?: boolean;
	link: string;
}

const BottomItem: React.FC<BottomItemProps> = props => {
	const { Icon, label, isActive, link } = props;

	const { profileId } = useParams();

	return (
		<NavLink
			to={link + '/' + profileId}
			className={`
				flex
				flex-col
				items-center
				justify-between
				gap-1
				px-2
				transition
				cursor-pointer
				${isActive ? 'text-blue-400' : 'text-gray-300'} 
	    `}
		>
			<span className={'w-6'}>{Icon}</span>

			<div className='font-light text-[12px]'>{label}</div>
		</NavLink>
	);
};

export default memo(BottomItem);
