import { useCallback, useEffect, useState } from 'react';
import { ButtonModal } from 'shared/ui/ButtonModal';

interface ModalProps {
	isOpen?: boolean;
	onClose: () => void;
	onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
	title?: string;
	body?: React.ReactElement;
	footer?: React.ReactElement;
	actionLabel: string;
	disabled?: boolean;
	secondaryAction?: () => void;
	secondaryActionLabel?: string;
	hFull?: boolean;
}

const Modal: React.FC<ModalProps> = props => {
	const {
		isOpen,
		onClose,
		onSubmit,
		title,
		body,
		actionLabel,
		footer,
		disabled,
		secondaryAction,
		secondaryActionLabel,
		hFull = true,
	} = props;
	const [showModal, setShowModal] = useState(isOpen);

	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('lock');
		} else {
			document.body.classList.remove('lock');
		}

		return () => {
			document.body.classList.remove('lock');
		};
	}, [isOpen]);

	useEffect(() => {
		setShowModal(isOpen);
	}, [isOpen]);

	const handleClose = useCallback(() => {
		setShowModal(false);
		setTimeout(() => {
			onClose();
		}, 300);
	}, [onClose]);

	const handleSubmit = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			if (disabled) {
				return;
			}

			onSubmit(event);
		},
		[onSubmit, disabled]
	);

	const handleSecondaryAction = useCallback(() => {
		if (disabled || !secondaryAction) {
			return;
		}

		secondaryAction();
	}, [secondaryAction, disabled]);

	if (!isOpen) {
		return null;
	}

	return (
		<>
			<div
				onClick={handleClose}
				className='modal-fixed fixed inset-0 z-[505] flex items-center justify-center overflow-x-hidden overflow-y-hidden outline-none focus:outline-none bg-neutral-800/70'
			>
				<div
					onClick={e => e.stopPropagation()}
					className={`relative ${
						hFull ? 'h-full md:h-5/6' : 'h-full md:h-auto'
					} w-full  mx-auto my-6 md:w-4/6 lg:w-3/6 xl:w-2/5`}
				>
					{/*content*/}
					<div
						className={`
            translate
            duration-300
            h-full
            ${showModal ? 'translate-y-0' : 'translate-y-full'}
            ${showModal ? 'opacity-100' : 'opacity-0'}
          `}
					>
						<div className='relative flex flex-col w-full h-full border-0 bg-slate-800 md:rounded-lg shadow-lg outline-none translate focus:outline-none'>
							{/*header*/}
							<div
								className='
                flex 
                items-center 
                p-4
                rounded-t
                justify-center
                relative
                border-b-[1px]
								border-slate-700
                '
							>
								<button
									className='absolute p-1 transition border-0 hover:opacity-70 right-3'
									onClick={handleClose}
								>
									<div className={'_icon-close text-[25px] text-blue-500'}>
										<svg
											width='30px'
											height='30px'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<g clipPath='url(#clip0_429_11083)'>
												<path
													d='M7 7.00006L17 17.0001M7 17.0001L17 7.00006'
													stroke='currentColor'
													strokeWidth='2.5'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</g>
											<defs>
												<clipPath id='clip0_429_11083'>
													<rect width='24' height='24' fill='white' />
												</clipPath>
											</defs>
										</svg>
									</div>
								</button>
								<div className='text-lg text-white font-semibold'>{title}</div>
							</div>
							{/*body*/}
							<div className=' overflow-auto'>
								<div className='relative flex-auto p-6 pb-0'>{body}</div>
								{/*footer*/}
								<div className='flex flex-col gap-2 p-6 pt-0'>
									<div className='flex flex-row items-center w-full gap-4 '>
										{secondaryAction && secondaryActionLabel && (
											<ButtonModal
												disabled={disabled}
												label={secondaryActionLabel}
												onClick={handleSecondaryAction}
												outline
											/>
										)}
										<ButtonModal
											disabled={disabled}
											label={actionLabel}
											onClick={handleSubmit}
										/>
									</div>
									{footer}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Modal;
