export function formatDate(value: string): string {
	const date = new Date(value);

	if (isNaN(date.getTime())) {
		return 'д.м.р';
	}

	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();

	return `${day}.${month}.${year}`;
}
