interface Checkbox {
	title: string;
	text: string;
	name: string;
	checked: boolean;
	disabled?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({
	title,
	text,
	name,
	checked,
	disabled = false,
	onChange,
}: Checkbox) => {
	return (
		<div className='flex items-center'>
			<div className='flex items-center h-5'>
				<input
					id={name}
					disabled={disabled}
					aria-describedby={`${name}-text`}
					type='checkbox'
					value=''
					checked={checked}
					onChange={onChange}
					className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2'
				/>
			</div>
			<div className='ms-3 text'>
				<label htmlFor={name} className='font-medium text-slate-300'>
					{title}
				</label>
				<p id={`${name}-text`} className='text-sm font-normal text-slate-500'>
					{text}
				</p>
			</div>
		</div>
	);
};
