import { ProviderApp } from 'app/providers/provider-app';
import { PhotoItemTypes, usePhoto } from 'entities/photo';
import { useProfile } from 'entities/profile';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PhotoProvider } from 'react-photo-view';
import { useParams } from 'react-router-dom';
import Plus from 'shared/assets/svgs/Plus';
import { Button } from 'shared/ui/Button';
import { Provider } from 'shared/ui/Provider';
import { PhotoSkeleton } from 'shared/ui/Skeleton/PhotoSkeleton';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { PhotoItem } from 'widgets/PhotoItem';
import { PhotoModal, usePhotoModal } from './PhotoModal';

export const Photo = () => {
	const { profileId } = useParams();
	const { onOpen } = usePhotoModal();

	const { data, isLoading } = useProfile();

	const {
		data: dataPhoto,
		isPending: isPendingPhoto,
		fetchNextPage,
		isError,
		refetch,
		hasNextPage,
	} = usePhoto(profileId as string, false);

	const items: PhotoItemTypes[] =
		dataPhoto?.pages?.flatMap(page => page.data) ?? [];

	const onOpenModal = () => {
		onOpen(data._photoModerate, data._photoAuth, refetch);
	};

	return (
		<Provider>
			<ProviderApp>
				<PhotoModal />
				<div className={'flex justify-between items-center'}>
					<h2 className='text-4xl font-extrabold mt-5 mb-5 text-white'>
						Фотографії
					</h2>
					{!isLoading && data._photoSwitch && (
						<div>
							<Button onClick={onOpenModal} className='text-white'>
								<Plus />
							</Button>
						</div>
					)}
				</div>
				<div className={'flex flex-col mt-3 mx-[-1.25rem] pl-[3px]'}>
					{isError ? (
						<ErrorLoad onRefresh={refetch} />
					) : (
						<>
							{isPendingPhoto ? (
								<div className={'flex flex-row gap-1 mb-5'}>
									<PhotoSkeleton />
									<PhotoSkeleton />
									<PhotoSkeleton />
								</div>
							) : (
								<>
									{!items.length ? (
										<h4 className={'text-center mt-3 text-slate-400'}>
											Немає жодной фотографіi...
										</h4>
									) : (
										<PhotoProvider
											speed={() => 400}
											easing={type =>
												type === 2
													? 'cubic-bezier(0.36, 0, 0.66, -0.56)'
													: 'cubic-bezier(0.34, 1.56, 0.64, 1)'
											}
										>
											<InfiniteScroll
												dataLength={items.length}
												next={fetchNextPage}
												hasMore={!!hasNextPage}
												loader={
													<div className={'flex flex-row gap-1 mt-2'}>
														<PhotoSkeleton />
														<PhotoSkeleton />
														<PhotoSkeleton />
													</div>
												}
											>
												<div className={'flex flex-wrap gap-1'}>
													{items.map(item => (
														<PhotoItem
															key={item.url}
															{...item}
															edited={false}
															profileId={profileId as string}
														/>
													))}
												</div>
											</InfiniteScroll>
										</PhotoProvider>
									)}
								</>
							)}
						</>
					)}
				</div>
			</ProviderApp>
		</Provider>
	);
};
