import { ContentState, convertFromRaw, RawDraftContentState } from 'draft-js';
//@ts-ignore
import { stateToHTML } from 'draft-js-export-html';

interface Data {
	biography: string;
	privacyLife: string;
	work: string;
}

interface ConvertedHTML {
	biography: string;
	privacyLife: string;
	work: string;
}

// Базовый объект RawDraftContentState
const defaultRawDraftContentState: RawDraftContentState = {
	blocks: [],
	entityMap: {},
};

// Функция для безопасного преобразования JSON строки в объект
function safeParseJSON(jsonString: string): RawDraftContentState {
	try {
		return JSON.parse(jsonString) as RawDraftContentState;
	} catch (error) {
		return defaultRawDraftContentState;
	}
}

// Функция для преобразования данных в HTML
export function convertDataToHTML(data: Data): ConvertedHTML {
	// Вспомогательная функция для преобразования сырого объекта в HTML
	const convertFromRawToHTML = (raw: string): string => {
		const contentState: ContentState = convertFromRaw(safeParseJSON(raw));
		return stateToHTML(contentState);
	};

	return {
		biography: convertFromRawToHTML(data.biography),
		privacyLife: convertFromRawToHTML(data.privacyLife),
		work: convertFromRawToHTML(data.work),
	};
}

export function convertToHTML(value: string): string {
	// Вспомогательная функция для преобразования сырого объекта в HTML
	const convertFromRawToHTML = (raw: string): string => {
		const contentState: ContentState = convertFromRaw(safeParseJSON(raw));
		return stateToHTML(contentState);
	};

	return convertFromRawToHTML(value);
}
