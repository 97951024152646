import { useAuth } from 'entities/auth';
import { useProfile, useProfileView } from 'entities/profile';
import { ReactNode, memo, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRouter } from 'shared/config/router';
import { ShareModal } from 'shared/ui/ShareModal';
import { checkAndAddStringToLocalStorage } from 'shared/utils/checkAndAddStringToLocalStorage';
import { formatDate } from 'shared/utils/formatDate';
import { checkGeoAccess } from 'shared/utils/storage/checkGeoAccess';
import { Avatar } from 'widgets/Avatar';
import { BottomBar } from 'widgets/BottomBar';
import { Footer } from 'widgets/Footer';
import { LocationLock } from 'widgets/LocationLock';
import { NavBar } from 'widgets/NavBar';
import { ProfileLock } from 'widgets/ProfileLock';

export const ProviderApp = memo(
	({
		children,
		noPadding = false,
	}: {
		children: ReactNode;
		noPadding?: boolean;
	}) => {
		const { profileId } = useParams();
		const navigate = useNavigate();

		const isGeoAccess = checkGeoAccess(profileId as string);

		const { isAuth } = useAuth();
		const { mutateView } = useProfileView();
		const { data, isLoading } = useProfile();

		const [isGeo, setIsGeo] = useState(isGeoAccess);

		// Redirect to activation
		useEffect(() => {
			if (!isLoading && !data?.activated) {
				navigate(`${AppRouter.DASHBOARD}/${profileId}`);
			}
		}, [data, isLoading, profileId, isAuth]);

		// View activate
		useEffect(() => {
			const isView = checkAndAddStringToLocalStorage('view', data._id);
			if (isView && data?.activated) {
				mutateView(data._id);
			}
		}, [data, isLoading, profileId]);

		return (
			<HelmetProvider>
				<main className='flex-grow'>
					{!isGeo && data._geoSwitch && (
						<LocationLock
							setIsGeo={setIsGeo}
							profileId={profileId as string}
							_geoCoords={data._geoCords}
						/>
					)}

					{data._profileSwitch && <ProfileLock />}

					<Avatar
						isLoading={isLoading}
						profileId={data._id}
						avatarUpdatedAt={data.avatarUpdatedAt}
						isAvatar={data.isAvatar}
						fullName={data.fullName}
						short={data.short}
						birthday={formatDate(data.birthDay)}
						deadday={formatDate(data.deathDay)}
					/>

					<NavBar isLoading={isLoading} views={data.views} likes={data.likes} />
					<ShareModal />
					<div className={`${!noPadding ? 'px-5' : ''}`}>{children}</div>
				</main>
				<Footer />
				<BottomBar />
			</HelmetProvider>
		);
	}
);
