import { useMutation } from '@tanstack/react-query';
import { ProfileApi } from '../../api/ProfileApi';

export const useProfileLike = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: ProfileApi.like,
		});

	return {
		mutateLike: mutate,
		isPendingLike: isPending,
		isSuccessLike: isSuccess,
		isErrorLike: isError,
		errorLike: error,
		resetLike: reset,
		dataLike: data,
	};
};
