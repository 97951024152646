import { useMutation } from '@tanstack/react-query';
import { FileApi } from './../api/FileApi';

export const useReplicateUpdate = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: FileApi.updateReplicatre,
		});

	return {
		mutateReplicate: mutate,
		isPendingReplicate: isPending,
		isSuccessReplicate: isSuccess,
		isErrorReplicate: isError,
		errorReplicate: error,
		resetReplicate: reset,
		dataReplicate: data,
	};
};
