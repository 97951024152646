import { REPLICATE_STATUS } from 'entities/file';
import Info from 'shared/assets/svgs/Info';

type ReplicateStatusProps = {
	replicateStatus: REPLICATE_STATUS;
};

export const ReplicateStatus = ({ replicateStatus }: ReplicateStatusProps) => {
	return (
		<div className='text-white'>
			{replicateStatus === REPLICATE_STATUS.SUCCESS && (
				<div
					className={
						'flex items-center mb-5 border border-teal-500 text-sm rounded-lg p-2'
					}
				>
					<span className={'w-4 text-teal-500'}>
						<Info />
					</span>
					<span className={'ml-1 text-teal-500'}>
						Модель AR успішно завантажена.
					</span>
				</div>
			)}
			{replicateStatus === REPLICATE_STATUS.LOADING && (
				<div
					className={
						'flex items-center mb-5 border border-purple-400 text-sm rounded-lg p-2'
					}
				>
					<span className={'w-4 text-purple-400'}>
						<Info />
					</span>
					<span className={'ml-1 text-purple-400'}>
						Виконується генерація AR фотографії.
					</span>
				</div>
			)}
			{replicateStatus === REPLICATE_STATUS.ERROR && (
				<div
					className={
						'flex mb-5 items-center border border-red-400 text-sm rounded-lg p-2'
					}
				>
					<span className={'w-8 text-red-300'}>
						<Info />
					</span>
					<span className={'ml-1 text-red-300'}>
						Сталася помилка під час генерації AR фотографії, спробуйте
						завантажити фотографію ще раз.
					</span>
				</div>
			)}
		</div>
	);
};
