import { ButtonHTMLAttributes, ReactNode } from 'react';
import { MiniIndicator } from 'shared/ui/MiniIndicator';

type ButtonProps = {
	children: ReactNode;
	long?: boolean;
	isLoading?: boolean;
	bg?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
	children,
	long = false,
	isLoading = false,
	bg = 'bg-blue-600',
	...res
}: ButtonProps) => {
	return (
		<button
			{...res}
			type='button'
			className={`flex items-center justify-center text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm ${
				long ? 'px-4' : 'px-2'
			} py-2 text-center ${bg}`}
		>
			{isLoading && (
				<span className={'mr-1'}>
					<MiniIndicator isShow size={'w-3'} />{' '}
				</span>
			)}
			{children}
		</button>
	);
};
