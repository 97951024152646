import { useMutation } from '@tanstack/react-query';
import { PhotoApi } from '../../api/PhotoApi';

export const usePhotoDelete = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: PhotoApi.delete,
		});

	return {
		mutateDel: mutate,
		isPendingDel: isPending,
		isSuccessDel: isSuccess,
		isErrorDel: isError,
		errorDel: error,
		resetDel: reset,
		dataDel: data,
	};
};
