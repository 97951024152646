type IsAcceptOwnerProps = {
	role: string;
	userId: string;
};

export const isAcceptOwner = (
	array: IsAcceptOwnerProps[],
	author: string,
	role?: string
) => {
	if (author === undefined || !array || array.length < 1) {
		return null;
	}

	return array.find(
		user => user.userId === author && (role ? user.role === role : true)
	);
};
