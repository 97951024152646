import { ProviderApp } from 'app/providers/provider-app';
import { useProfile } from 'entities/profile';
import { VideoItemTypes, useVideo } from 'entities/video';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';
import Plus from 'shared/assets/svgs/Plus';
import { Button } from 'shared/ui/Button';
import { Provider } from 'shared/ui/Provider';
import { VideoSkeleton } from 'shared/ui/Skeleton/VideoSkeleton';
import { ErrorLoad } from 'widgets/ErrorLoad';
import { VideoModal } from './VideoModal';
import { useVideoModal } from './VideoModal/model/hooks/useVideoModal';

export const Video = () => {
	const { profileId } = useParams();
	const { onOpen } = useVideoModal();

	const {
		data: dataVideo,
		// isLoading: isLoadingVideo,
		isPending,
		fetchNextPage,
		hasNextPage,
		refetch,
		isError,
	} = useVideo(profileId as string, false);

	const { data, isLoading } = useProfile();

	const items: VideoItemTypes[] =
		dataVideo?.pages?.flatMap(page => page.data) ?? [];

	const onOpenModal = () => {
		onOpen(data._videoModerate, data._videoAuth, refetch);
	};

	return (
		<Provider>
			<ProviderApp>
				<VideoModal />
				<div className={'flex justify-between items-center'}>
					<h2 className='text-4xl font-extrabold mt-5 mb-5 text-white'>
						Відео пам'яті
					</h2>
					{!isLoading && data._videoSwitch && (
						<div>
							<Button onClick={onOpenModal} className=' text-white'>
								<Plus />
							</Button>
						</div>
					)}
				</div>
				<div className={'flex flex-col mt-3'}>
					{isError ? (
						<ErrorLoad onRefresh={refetch} />
					) : (
						<>
							{isPending ? (
								<div className={'flex flex-col gap-3'}>
									<VideoSkeleton />
									<VideoSkeleton />
								</div>
							) : (
								<>
									{!items.length ? (
										<h4 className={'text-center mt-3 text-slate-400'}>
											Немає жодного відео...
										</h4>
									) : (
										<InfiniteScroll
											dataLength={items.length}
											next={fetchNextPage}
											hasMore={!!hasNextPage}
											loader={
												<div className={'flex flex-col gap-3'}>
													<VideoSkeleton />
													<VideoSkeleton />
												</div>
											}
										>
											<div className={'flex flex-wrap gap-2'}>
												{items.map(item => (
													<ReactPlayer
														key={item._id}
														url={item.url}
														width={'100%'}
														height={'230px'}
													/>
												))}
											</div>
										</InfiniteScroll>
									)}
								</>
							)}
						</>
					)}
				</div>
			</ProviderApp>
		</Provider>
	);
};
