import { ReactNode } from 'react';

type CatgoryItemProps = {
	title: string;
	subtitle: string;
	titleChildren?: ReactNode;
	children: ReactNode;
};

export const CatgoryItem = ({
	title,
	subtitle,
	titleChildren,
	children,
}: CatgoryItemProps) => {
	return (
		<div className={'mb-10'}>
			<h2 className='flex text-1xl justify-between items-center font-extrabold border-slate-600 border-b pb-2 mt-5 mb-3 text-white'>
				{title}
				{titleChildren}
			</h2>
			<p className={'text-sm text-slate-300'}>{subtitle}</p>
			<div className={'mt-5'}>{children}</div>
		</div>
	);
};
