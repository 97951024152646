interface ButtonProps {
	label: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	disabled?: boolean;
	outline?: boolean;
	small?: boolean;
	icon?: string;
}

const ButtonModal: React.FC<ButtonProps> = props => {
	const { label, onClick, disabled, outline, small, icon } = props;

	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className={`
        relative
        disabled:opacity-70
        disabled:cursor-not-allowed
        rounded-lg
        hover:opacity-80
        transition
        w-full
        font-light
        border-[1px]
        ${outline ? 'bg-white' : 'bg-blue-500'}
        ${outline ? 'border-black' : 'border-blue-500'}
        ${outline ? 'text-black' : 'text-white'}
        ${small ? 'text-sm' : 'text-md'}
        ${small ? 'py-1' : 'py-3'}
        ${small ? 'font-light' : 'font-semibold'}
        ${small ? 'border-[1px]' : 'border-2'}
      `}
		>
			{icon && <div className={`${icon} text-[24px] absolute left-4 top-3`} />}
			{label}
		</button>
	);
};

export default ButtonModal;
