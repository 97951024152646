import { useAuth } from 'entities/auth';
import { memo } from 'react';
import Instagram from 'shared/assets/svgs/Instagram';
import LogoFull from 'shared/assets/svgs/LogoFull';
import Tiktok from 'shared/assets/svgs/Tiktok';
import { AppRouter } from 'shared/config/router';
import { Button } from 'shared/ui/Button';
import { LinkCustom } from 'shared/ui/Link';

type FooterProps = {
	paddgin?: boolean;
};

export const Footer = memo(({ paddgin = true }: FooterProps) => {
	const { isAuth } = useAuth();

	const onLinkInstagram = () => {
		const url = 'https://www.instagram.com/dzvinok_ua';
		window.open(url, '_blank');
	};

	const onLinkTikTok = () => {
		const url = 'https://www.tiktok.com/@dzvinok_qr';
		window.open(url, '_blank');
	};

	const onClickHome = () => {
		window.location.href = '/';
	};

	return (
		<footer
			className={`border-t bg-slate-900/30 border-slate-700 pt-5 ${
				paddgin ? 'pb-[80px] mb-safe-bottom' : 'pb-[30px]'
			} mt-[80px]`}
		>
			<div className='px-5'>
				<div className='flex justify-between items-center w-full'>
					<span className='text-slate-100 w-[130px]'>
						<LogoFull />
					</span>
					<div className='flex gap-3'>
						<div className='flex flex-col text-white w-[33px]'>
							<Button onClick={onLinkTikTok}>
								<Tiktok width='w-[17px]' />
							</Button>
						</div>
						<div className='flex flex-col text-white w-[33px]'>
							<Button onClick={onLinkInstagram}>
								<Instagram width='w-[17px]' />
							</Button>
						</div>
					</div>
				</div>
				<div className='flex mt-5 justify-around gap-3'>
					<a
						href='/'
						onClick={onClickHome}
						type='button'
						className={`text-indigo-200 rounded-lg text-sm text-center`}
					>
						Замовити
					</a>
					<LinkCustom to={AppRouter.TERMS}>Умови використання</LinkCustom>
					{!isAuth && <LinkCustom to={AppRouter.AUTH}>Авторизація</LinkCustom>}
					{isAuth && <LinkCustom to={AppRouter.DASHBOARD}>Кабінет</LinkCustom>}
				</div>
			</div>
			<div className='mt-5 pt-5 text-center border-t border-slate-700 '>
				<span className='text-xs text-slate-400'>
					Всі права захищені © Dzvinok, 2024
				</span>
			</div>
		</footer>
	);
});
