import React from 'react';

function Photo() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
			<path
				fill={'currentColor'}
				fillRule='evenodd'
				d='M12 18a5 5 0 100-10 5 5 0 000 10zm0-1.993a3.007 3.007 0 110-6.014 3.007 3.007 0 010 6.014z'
				clipRule='evenodd'
			></path>
			<path
				fill={'currentColor'}
				fillRule='evenodd'
				d='M9.562 2a3 3 0 00-2.91 2.272L6.218 6H4a3 3 0 00-3 3v10a3 3 0 003 3h16a3 3 0 003-3V9a3 3 0 00-3-3h-2.22l-.431-1.728A3 3 0 0014.439 2H9.561zm-.97 2.757A1 1 0 019.561 4h4.876a1 1 0 01.97.757l.433 1.728A2 2 0 0017.78 8H20a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V9a1 1 0 011-1h2.22a2 2 0 001.94-1.515l.431-1.728z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default React.memo(Photo);
