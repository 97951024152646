import React from 'react';

function Setting() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
			<g>
				<g fill='currentColor' fillRule='evenodd' clipRule='evenodd'>
					<path d='M12 9.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.25 12a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z'></path>
					<path d='M9.603 3.77a.919.919 0 00-1.25-.335l-.01.006-1.73.99a1.15 1.15 0 00-.424 1.564l-.649.375.649-.376c.535.923.679 1.935.2 2.764-.477.83-1.424 1.212-2.489 1.212-.633 0-1.15.522-1.15 1.15v1.76c0 .628.517 1.15 1.15 1.15 1.065 0 2.012.383 2.49 1.212.478.83.334 1.84-.2 2.764a1.149 1.149 0 00.422 1.563l1.742.996a.919.919 0 001.25-.335l.107-.186c.535-.923 1.341-1.552 2.298-1.552.957 0 1.761.63 2.291 1.554v.001l.106.183a.92.92 0 001.25.335l.011-.006 1.73-.99a1.147 1.147 0 00.423-1.565c-.534-.923-.677-1.934-.2-2.762.478-.83 1.425-1.212 2.49-1.212.633 0 1.15-.522 1.15-1.15v-1.76c0-.633-.522-1.15-1.15-1.15-1.065 0-2.012-.382-2.49-1.212-.478-.829-.334-1.84.2-2.764a1.15 1.15 0 00-.422-1.563l-1.742-.996a.919.919 0 00-1.25.335l-.107.186c-.535.923-1.341 1.552-2.298 1.552-.957 0-1.76-.63-2.29-1.553l-.002-.002-.106-.183zm-2.01-1.628c1.159-.685 2.63-.265 3.302.865l.004.007.11.19.002.003c.37.645.756.8.99.8.236 0 .625-.156 1-.803l.114-.197c.672-1.13 2.143-1.55 3.303-.865l1.724.987a2.65 2.65 0 01.977 3.616v.001c-.375.647-.316 1.06-.2 1.263.118.203.446.461 1.191.461a2.656 2.656 0 012.65 2.65v1.76a2.656 2.656 0 01-2.65 2.65c-.745 0-1.073.258-1.19.46-.117.204-.176.617.199 1.264l.002.004a2.647 2.647 0 01-.979 3.613l-1.725.987c-1.159.685-2.63.265-3.302-.864l-.004-.008-.11-.19-.002-.003c-.37-.645-.756-.8-.99-.8-.236 0-.625.156-1 .803l-.114.198c-.672 1.129-2.143 1.55-3.302.864l-1.725-.987a2.65 2.65 0 01-.977-3.616v-.001c.375-.647.316-1.06.2-1.263-.118-.204-.446-.461-1.191-.461a2.656 2.656 0 01-2.65-2.65v-1.76A2.656 2.656 0 013.9 8.47c.745 0 1.073-.258 1.19-.46.117-.204.176-.617-.199-1.264a2.649 2.649 0 01.976-3.617l1.725-.987z'></path>
				</g>
			</g>
		</svg>
	);
}

export default React.memo(Setting);
