import { useMutation } from '@tanstack/react-query';
import { PhotoApi } from '../../api/PhotoApi';

export const usePhotoUpload = () => {
	const { mutate, data, isPending, isSuccess, isError, error, reset } =
		useMutation({
			mutationFn: PhotoApi.uploadPhoto,
		});

	return {
		mutatePhoto: mutate,
		isPendingPhoto: isPending,
		isSuccessPhoto: isSuccess,
		isErrorPhoto: isError,
		errorPhoto: error,
		resetPhoto: reset,
		dataPhoto: data,
	};
};
