import React from 'react';

function Ar() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='M201.14 64L256 32 310.86 64'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M256 32L256 112'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='M310.86 448L256 480 201.14 448'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M256 480L256 400'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='M64 207.51L64 144 117.15 112.49'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M64 144L131.29 184'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='M448 304.49L448 368 394.85 399.51'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M448 368L380.71 328'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='M117.15 400L64 368 64 304.49'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M64 368L130.64 328'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='M394.85 112.49L448 144 448 207.51'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M448 144L380.71 184'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M256 320L256 256 310.86 224'
			></path>
			<path
				fill='none'
				stroke={'currentColor'}
				strokeLinecap='round'
				strokeMiterlimit='10'
				strokeWidth='32'
				d='M256 256L201.14 224'
			></path>
		</svg>
	);
}

export default React.memo(Ar);
