import React from 'react';

function Info() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
			<path
				fill={'currentColor'}
				d='M12 17.75a.75.75 0 00.75-.75v-6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75zM12 7a1 1 0 110 2 1 1 0 010-2z'
			></path>
			<path
				fill={'currentColor'}
				fillRule='evenodd'
				d='M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zM12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5z'
				clipRule='evenodd'
			></path>
		</svg>
	);
}

export default React.memo(Info);
