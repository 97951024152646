import { useMutation } from '@tanstack/react-query';
import { AuthApi } from '../../api/authApi';

export const useLogin = () => {
	const { mutate, data, isPending, isSuccess, error, reset } = useMutation({
		mutationFn: AuthApi.login,
	});

	return { mutate, isPending, isSuccess, error, reset, data };
};
