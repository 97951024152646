import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

type ButtonProps = {
	children: ReactNode;
} & LinkProps;

export const LinkCustom = ({ children, ...res }: ButtonProps) => {
	return (
		<Link
			{...res}
			type='button'
			className={`text-indigo-200 rounded-lg text-sm text-center`}
		>
			{children}
		</Link>
	);
};
