import {
	VideoItemTypes,
	useVideoDelete,
	useVideoModeration,
} from 'entities/video';
import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Button } from 'shared/ui/Button';
import { ButtonRed } from 'shared/ui/ButtonRed';

type VideoItemProps = {
	edited: boolean;
	profileId: string;
} & VideoItemTypes;

export const VideoItem = (props: VideoItemProps) => {
	const [isDelete, setIsDetele] = useState(false);
	const [isModerate, setIsModerate] = useState(props.moderation);

	const { mutateDel } = useVideoDelete();
	const { mutateMod } = useVideoModeration();

	const onDelete = () => {
		setIsDetele(true);
		mutateDel({ profileId: props.profileId, videoId: props._id });
	};

	const onModerate = () => {
		setIsModerate(true);
		mutateMod({ profileId: props.profileId, videoId: props._id });
	};

	return (
		<>
			{!isDelete && (
				<div
					className={`block p-6 w-full bg-slate-900  border border-slate-950 rounded-lg shadow`}
				>
					<ReactPlayer url={props.url} width={'100%'} height={'230px'} />
					{props.isOwner && props.edited && (
						<div
							className={
								'flex justify-end mt-5 pt-5 border-t border-slate-700 gap-3'
							}
						>
							{!isModerate && (
								<Button onClick={onModerate}>Опублікувати</Button>
							)}
							<ButtonRed onClick={onDelete} color={'indigo'}>
								<svg
									className={'w-4 h-4'}
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M10 12V17'
										stroke={'currentColor'}
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M14 12V17'
										stroke={'currentColor'}
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M4 7H20'
										stroke={'currentColor'}
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10'
										stroke={'currentColor'}
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z'
										stroke={'currentColor'}
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</ButtonRed>
						</div>
					)}
				</div>
			)}
		</>
	);
};
