import { useMutation } from '@tanstack/react-query';
import { ProfileApi } from '../../api/profileApi';

export const useFetchActivated = () => {
	const { mutate, isPending, isError, data, isSuccess, error } = useMutation({
		mutationFn: ProfileApi.activated,
	});

	return {
		mutateAct: mutate,
		isPendingAct: isPending,
		isErrorAct: isError,
		dataAct: data,
		errorAct: error,
		isSuccessAct: isSuccess,
	};
};
