import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth, useIsAuth } from 'entities/auth';
import { useUser } from 'entities/user';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import { AppRouter } from 'shared/config/router';
import { KEY_REDIRECT_AUTH_PROFILE } from 'shared/const/app';
import { Indicator } from 'shared/ui/Indicator';
import { InputText } from 'shared/ui/InputText';
import { Provider } from 'shared/ui/Provider';
import { authValidation } from 'shared/validation/auth.validation';
import { Footer } from 'widgets/Footer';
import { useAuthGoogle } from '../model/hooks/useAuthGoogle/useAuthGoogle';
import { useLogin } from '../model/hooks/useLogin/useLogin';
import { useLoginAccessToken } from '../model/hooks/useLogin/useLoginAccessToken';

export const Auth = () => {
	const { profileId } = useParams();

	const [email, setEmail] = useState('');
	const [isAccess, setIsAccess] = useState<boolean | undefined>();

	const setIsAuth = useAuth(state => state.setIsAuth);
	const setUser = useUser(state => state.initialUser);

	const { mutate, isPending, isSuccess, error } = useLogin();
	const {
		mutateAccess,
		isPendingAccess,
		isSuccessAccess,
		dataAccess,
		errorAccess,
	} = useLoginAccessToken();

	const { mutateGoogle, isPendingGoogle, isSuccessGoogle, dataGoogle } =
		useAuthGoogle();

	const { control, handleSubmit, resetField } = useForm({
		defaultValues: {
			email: '',
		},
		resolver: yupResolver(authValidation),
	});

	const onSubmit = ({ email }: { email: string }) => {
		mutate({ email });
		setEmail(email);
		resetField('email');
	};

	const onSubmitToken = (value: string) => {
		mutateAccess({ email: email, token: parseInt(value) });
	};

	useEffect(() => {
		if (isSuccess) {
			setIsAccess(true);
		}
	}, [isSuccess]);

	// by email
	useEffect(() => {
		if (isSuccessAccess) {
			setIsAuth(dataAccess.accessToken, dataAccess.refreshToken);
			setUser(dataAccess.user._id, dataAccess.user.name, dataAccess.user.email);
		}
	}, [isSuccessAccess, dataAccess]);

	// by google
	useEffect(() => {
		if (isSuccessGoogle) {
			setIsAuth(dataGoogle.accessToken, dataGoogle.refreshToken);
			setUser(dataGoogle.user._id, dataGoogle.user.name, dataGoogle.user.email);
		}
	}, [isSuccessGoogle, dataGoogle]);

	const authRedirect = localStorage.getItem(KEY_REDIRECT_AUTH_PROFILE);

	useIsAuth(
		true,
		profileId
			? `${AppRouter.DASHBOARD}/${profileId}`
			: authRedirect
			? `${AppRouter.HOME}/${authRedirect}`
			: AppRouter.DASHBOARD
	);

	const authByGoogle = useGoogleLogin({
		onSuccess: async tokenResponse => {
			mutateGoogle({ idToken: tokenResponse.access_token });
		},
	});

	return (
		<Provider>
			<Indicator isShow={isPending || isPendingAccess || isPendingGoogle} />
			<div className='flex-grow flex fle-col items-center justify-center px-5'>
				<div className='grid items-center w-full'>
					<form className='w-full'>
						<h3
							className={
								'text-3xl text-white text-center font-extrabold mb-8 max-md:text-center'
							}
						>
							Особистий кабінет
						</h3>
						<div>
							{isAccess ? (
								<>
									{isSuccess && (
										<p className={'text-slate-300 text-xs text-center mb-5'}>
											На вашу електронну пошту надіслано код підтвердження. Якщо
											ви не бачите повідомлення у папці “Вхідні”, будь ласка,
											перевірте також папку “Спам”.
										</p>
									)}
									<VerificationInput
										length={5}
										validChars={'0-9'}
										placeholder={''}
										autoFocus={false}
										inputProps={{
											autoComplete: 'off',
										}}
										classNames={{
											container: 'w-auto',
											character:
												'rounded bg-slate-700 border-slate-700 text-white',
											characterInactive: 'bg-slate-600 border-slate-600',
										}}
										onComplete={onSubmitToken}
									/>
									{errorAccess?.message && (
										<div className='text-red-400 mt-1'>Токен недійсний.</div>
									)}
								</>
							) : (
								<>
									<InputText
										control={control}
										name='email'
										type='email'
										autoComplete='email'
										placeholder='Адреса електронної пошти'
									/>
									{error?.message && (
										<div className='text-red-400 mt-1'>
											Виникла помилка при вході.
										</div>
									)}
								</>
							)}
						</div>
						{!isAccess && (
							<div className='mt-5'>
								<button
									onClick={handleSubmit(onSubmit)}
									type='button'
									className='w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none'
								>
									Авторизація
								</button>
							</div>
						)}
						<p className='my-5 text-sm text-gray-400 text-center'>
							або продовжити
						</p>
						<div className='space-x-6 flex justify-center'>
							<button
								type='button'
								onClick={() => authByGoogle()}
								className={
									'w-full py-2.5 px-4 text-sm font-semibold rounded text-slate-400 outline-none border-solid border border-slate-400 focus:outline-none'
								}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20px'
									className='inline'
									viewBox='0 0 512 512'
								>
									<path
										fill='#fbbd00'
										d='M120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308H52.823C18.568 144.703 0 198.922 0 256s18.568 111.297 52.823 155.785h86.308v-86.308C126.989 305.13 120 281.367 120 256z'
										data-original='#fbbd00'
									/>
									<path
										fill='#0f9d58'
										d='m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216C305.044 385.147 281.181 392 256 392z'
										data-original='#0f9d58'
									/>
									<path
										fill='#31aa52'
										d='m139.131 325.477-86.308 86.308a260.085 260.085 0 0 0 22.158 25.235C123.333 485.371 187.62 512 256 512V392c-49.624 0-93.117-26.72-116.869-66.523z'
										data-original='#31aa52'
									/>
									<path
										fill='#3c79e6'
										d='M512 256a258.24 258.24 0 0 0-4.192-46.377l-2.251-12.299H256v120h121.452a135.385 135.385 0 0 1-51.884 55.638l86.216 86.216a260.085 260.085 0 0 0 25.235-22.158C485.371 388.667 512 324.38 512 256z'
										data-original='#3c79e6'
									/>
									<path
										fill='#cf2d48'
										d='m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606C388.668 26.629 324.381 0 256 0l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z'
										data-original='#cf2d48'
									/>
									<path
										fill='#eb4132'
										d='M256 120V0C187.62 0 123.333 26.629 74.98 74.98a259.849 259.849 0 0 0-22.158 25.235l86.308 86.308C162.883 146.72 206.376 120 256 120z'
										data-original='#eb4132'
									/>
								</svg>
								<span className={' ml-3'}>за допомогою Google</span>
							</button>
						</div>
					</form>
				</div>
			</div>
			<Footer paddgin={false} />
		</Provider>
	);
};
