import {
	USER_LOCALSTORAGE_ACCESS_TOKEN,
	USER_LOCALSTORAGE_REFRESH_TOKEN,
} from 'shared/api/const/localstorage';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type AuthProps = {
	isAuth: boolean;
	isInit: boolean;
};

type Action = {
	initialAuth: () => void;
	logout: () => void;
	setIsAuth: (accessToken: string, refreshToken: string) => void;
};

export const useAuth = create<AuthProps & Action>()(
	immer(set => ({
		isAuth: false,
		isInit: false,
		initialAuth: async () =>
			set(state => {
				const accessToken = localStorage.getItem(
					USER_LOCALSTORAGE_ACCESS_TOKEN
				);
				state.isAuth = accessToken ? true : false;
				state.isInit = true;
			}),
		setIsAuth: (accessToken, refreshToken) =>
			set(state => {
				state.isAuth = true;
				state.isInit = true;

				localStorage.setItem(USER_LOCALSTORAGE_ACCESS_TOKEN, accessToken);
				localStorage.setItem(USER_LOCALSTORAGE_REFRESH_TOKEN, refreshToken);
			}),
		logout: () =>
			set(state => {
				state.isAuth = false;
				state.isInit = true;

				localStorage.removeItem(USER_LOCALSTORAGE_ACCESS_TOKEN);
				localStorage.removeItem(USER_LOCALSTORAGE_REFRESH_TOKEN);
			}),
	}))
);
