import React from 'react';

function Logo() {
	return (
		<svg viewBox='0 0 88 92' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M30.6091 80.8704C34.1831 84.0626 38.878 86 44.0202 86C49.162 86 53.8569 84.0626 57.4313 80.8704M44 6C51.2481 6 58.199 8.58996 63.3241 13.2002C68.4492 17.8103 71.3283 24.0631 71.3283 30.5829C71.3283 40.6675 73.5808 48.219 76.5019 53.7722C80.2337 60.8674 82.0996 64.415 81.9959 65.2252C81.8749 66.1711 81.7206 66.4313 80.9524 66.9834C80.294 67.4571 77.0191 67.4571 70.4689 67.4571H17.5309C10.9808 67.4571 7.70579 67.4571 7.04744 66.9834C6.27946 66.4313 6.12516 66.1711 6.0041 65.2252C5.90031 64.415 7.76627 60.8674 11.4982 53.7722C14.4192 48.219 16.6717 40.6675 16.6717 30.5829C16.6717 24.0631 19.5509 17.8103 24.6759 13.2002C29.801 8.58996 36.7519 6 44 6Z'
				stroke='currentColor'
				stroke-width='11'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
}

export default React.memo(Logo);
