import React, {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useRef,
} from 'react';
import { useLocation } from 'react-router-dom';

interface PreviousLocationContextProps {
	previousLocation: string | null;
}

const PreviousLocationContext = createContext<PreviousLocationContextProps>({
	previousLocation: null,
});

interface PreviousLocationProviderProps {
	children: ReactNode;
}

export const PreviousLocationProvider: React.FC<
	PreviousLocationProviderProps
> = ({ children }) => {
	const location = useLocation();
	const previousLocationRef = useRef<string | null>(null);

	useEffect(() => {
		const currentPathname = location.pathname;
		const currentRef = previousLocationRef.current;

		// Update previousLocationRef only if it's not already set to the current pathname
		if (currentRef !== currentPathname) {
			previousLocationRef.current = currentPathname;
		}
	}, [location]);

	return (
		<PreviousLocationContext.Provider
			value={{ previousLocation: previousLocationRef.current }}
		>
			{children}
		</PreviousLocationContext.Provider>
	);
};

export const usePreviousLocation = () => useContext(PreviousLocationContext);
